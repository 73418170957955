import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Card, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../components/api';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

export default function TMainAccounts() {

    const RootCard = styled(Card)(({ theme }) => ({
        boxShadow: 'none',
        textAlign: 'center',
        padding: theme.spacing(5, 0),
        // color: theme.palette.info.darker,
        // backgroundColor: theme.palette.info.lighter,
        backgroundColor: 'whitesmoke',
        cursor: 'pointer'
    }));
    const IconWrapper = styled('div')(({ theme }) => ({
        margin: 'auto',
        display: 'flex',
        // borderRadius: '50%',
        alignItems: 'center',
        width: theme.spacing(8),
        height: theme.spacing(8),
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        color: theme.palette.info.dark,
        // backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
        //     theme.palette.info.dark,
        //     0.24
        // )} 100%)`
    }));

    const navigate = useNavigate()


    const [contypes, setcontypes] = useState([])

    useEffect(() => {
        Api.get(`/v0/if/Contypes`, { headers: { CompanyGroup: "SIT" } })
            .then((res) => {
                setcontypes(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })


    }, [])

    return (
        <div style={{ padding: "20px" }}>
            <Grid container spacing={1}>
                {
                    contypes.map((contype, index) => {

                        return (
                            <Grid item md={4}>
                                <RootCard onClick={() => {
                                    navigate(`/settings/test/accounts/${contype.contype}`)
                                }}>
                                    <IconWrapper>
                                        <RecentActorsIcon fontSize="large" />
                                    </IconWrapper>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        {contype.contypename}
                                    </Typography>
                                </RootCard>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}
