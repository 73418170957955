import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ButtonDialogCustomer from './ButtonDialogCustomer';
import ButtonCreateCustomer from './ButtonCreateCustomer';

export default function Customer() {

    const [customers, setCustomers] = useState([
        {
            "id": "123456",
            "name": "John Doe",
            "email": "john.doe@example.com",
            "phone": "+1234567890",
            "address": {
                "street": "123 Main St",
                "city": "Anytown",
                "state": "CA",
                "zip": "12345",
                "country": "USA"
            }
        },
        {
            "id": "789012",
            "name": "Jane Smith",
            "email": "jane.smith@example.com",
            "phone": "+1987654321",
            "address": {
                "street": "456 Elm St",
                "city": "Smallville",
                "state": "NY",
                "zip": "54321",
                "country": "USA"
            }
        }
    ])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, data, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            // style: { bgcolor: "whitesmoke !important" },
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    console.log('data', data)
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };



    const onHandleDel = () => {
        handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
    }

    return (
        <div>
            <ButtonCreateCustomer />
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>ชื่อ</TableCell>
                            <TableCell>email</TableCell>
                            <TableCell>เบอร์โทร</TableCell>
                            <TableCell>ที่อยู่</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            customers.map((customer, i) => {
                                return (
                                    <TableRow key={`customer${i}`}>
                                        <TableCell>{customer.id}</TableCell>
                                        <TableCell>{customer.name}</TableCell>
                                        <TableCell>{customer.email}</TableCell>
                                        <TableCell>{customer.phone}</TableCell>
                                        <TableCell>{`${customer.address.street}, ${customer.address.city} ${customer.address.state} ${customer.address.zip} ${customer.address.country}`}</TableCell>
                                        <TableCell>
                                            <Stack spacing={1} direction="row">
                                                <Box>
                                                    {/* <IconButton size="small"><FindInPageIcon fontSize="small" /></IconButton> */}
                                                    <ButtonDialogCustomer />
                                                </Box>
                                                <Box>
                                                    <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลประเภท?", data: customer, onHandle: onHandleDel }) }}><DeleteOutlineIcon fontSize="small" /></IconButton>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div >
    )
}
