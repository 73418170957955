import * as Yup from 'yup';
import React, { useState } from 'react';
// material
import {
    Button,
    Divider,
    TextField,
    Container,
    Collapse,
    ListItemButton,
    List,
    ListSubheader,
    ListItemText,
    Grid
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

// components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Page from '../components/Page';
import { Api } from '../components/api';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



export default function GroupsNew({ account }) {

    const [open, setOpen] = useState(true);
    // const [initialValues, setInitialValues] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };
    const handleClick = () => {
        setOpen(!open);
    };


    const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

    const LoginSchema = Yup.object().shape({
        group_id: Yup.string().required('Id is required'),
        group_name: Yup.string().required('Name is required'),
        icon: Yup.string().matches(
            URL,
            'Enter correct url Icon!'
        )
        // .required('Icon is required'),
    });

    const formik = useFormik({
        initialValues: {
            group_id: "",
            group_name: "",
            icon: ""
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            const { c_coname } = account
            const data = {
                cname: c_coname,
                ...values,
            }
            Api.post('Tracking/Group', data)
                .then(res => {
                    handleOnClose()
                    handleSnackbar({ variant: 'success', message: 'success!' })
                }
                ).catch(error => {
                    handleSnackbar({ variant: 'error', message: error.response.data.Message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;
    const handleOnClose = () => {
        navigate('/settings/groups');
    }
    return (
        <Page title="groups new">
            <Container>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                        <Box width={400} margin="auto" padding={2} >
                            <List
                                sx={{ width: '100%', maxWidth: 320, bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton onClick={handleClick}>
                                    <ListItemText primary="Required" />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box padding={2} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Id"
                                                    {...getFieldProps('group_id')}
                                                    error={Boolean(touched.group_id && errors.group_id)}
                                                    helperText={touched.group_id && errors.group_id}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Name"
                                                    {...getFieldProps('group_name')}
                                                    error={Boolean(touched.group_name && errors.group_name)}
                                                    helperText={touched.group_name && errors.group_name}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Icon"
                                                    {...getFieldProps('icon')}
                                                    error={Boolean(touched.icon && errors.icon)}
                                                    helperText={touched.icon && errors.icon}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                                <Divider style={{ marginTop: 10 }} />

                                <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                    <Grid item xs={6} justifyContent="center">
                                        <Button
                                            fullWidth
                                            onClick={handleOnClose}
                                            size="medium"
                                            variant="outlined"
                                            color="info"
                                        // fullWidth
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} justifyContent="center">
                                        <LoadingButton
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            loading={isSubmitting}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </List>
                        </Box>
                    </Form>
                </FormikProvider>
            </Container>
        </Page >
    );
}
