import * as Yup from 'yup';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUserActions } from '../../_actions/userActions';
import { Api } from '../../api/';
import { useSnackbar } from 'notistack';
import { UseAesCbc } from '../../_crypto/AesCbc';
import { permissionAtom } from '../../_state/users';
import { useSetRecoilState } from 'recoil';


// ----------------------------------------------------------------------

export default function LoginForm() {

  const aesCbc = UseAesCbc();



  const setPermission = useSetRecoilState(permissionAtom);


  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const userActions = useUserActions();
  const LoginSchema = Yup.object().shape({
    userid: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
  };
  const formik = useFormik({
    initialValues: {
      userid: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        "userid": values.userid,
        "password": values.password
      });
      await Api.post('/v0/app/login', data)
        .then(res => {
          const resData = res.data
          localStorage.setItem('userId', values.userid)
          // localStorage.setItem('userId', userId);
          getDataToLocalStorage(resData.cocode)
          getPermissionToLocalStorage({ c_coname: resData.c_coname, userid: values.userid })
          // userActions.login({ dataAuth: resData, userid: values.userid })
          userActions.login({ dataAuth: resData })
        }
        ).catch(error => {
          handleSnackbar({ variant: 'error', message: error.response.data.Message });
        }
        )
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const getPermissionToLocalStorage = ({ c_coname, userid }) => {
    Api.get(`/Tracking/Permission?cname=${c_coname}&userid=${userid}&permission=SITGPS`)
      .then(res => {

        const permission = res.data?.value !== "" ? res.data?.value : "{}"
        const encode = aesCbc.encrypt(permission);
        setPermission(JSON.parse(permission))
        localStorage.setItem("permission", encode)
      }
      )
  }
  const getDataToLocalStorage = (cocode) => {
    Api.get(`/v0/gps/province?cocode=${cocode}`)
      .then(res => {
        const encode = aesCbc.encrypt(JSON.stringify(res.data));
        localStorage.setItem("listProvince", encode)
      }
      )
    Api.get(`/v0/gps/dlt_vender?cocode=${cocode}`)
      .then(res => {
        const encode = aesCbc.encrypt(JSON.stringify(res.data));
        localStorage.setItem("dltVender", encode)
      }
      )
    Api.get(`/v0/gps/vehicle_types?cocode=${cocode}`)
      .then(res => {
        const encode = aesCbc.encrypt(JSON.stringify(res.data));
        localStorage.setItem("listVehicleType", encode)
      }
      )
    Api.get(`/v0/gps/vehicle_register?cocode=${cocode}`)
      .then(res => {
        const encode = aesCbc.encrypt(JSON.stringify(res.data));
        localStorage.setItem("listVehicleRegisterType", encode)
      }
      )
    Api.get(`/v0/gps/model?cocode=${cocode}`)
      .then(res => {
        const encode = aesCbc.encrypt(JSON.stringify(res.data));
        localStorage.setItem("listModel", encode)
      }
      )
  }


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="off"
            type="text"
            label="Username"
            {...getFieldProps('userid')}
            error={Boolean(touched.userid && errors.userid)}
            helperText={touched.userid && errors.userid}
          // onChange={(e) => console.log(e.target.value)}
          />

          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <Link component={RouterLink} variant="button" to="/register" color="blue" underline="none">
            ลงทะเบียนผู้ใช้
          </Link>
          <Link component={RouterLink} variant="subtitle2" to="/forgotpassword">
            ลืมรหัสผ่าน?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="info"
          loading={isSubmitting}
        >
          เข้าสู่ระบบ
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
