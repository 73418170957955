import { alpha, Box, Card, Container, Divider, Grid, Typography } from '@mui/material'
import { styled } from '@mui/styles';
import React from 'react'
import Page from '../../components/Page'
import SpeedIcon from '@mui/icons-material/Speed';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import { useNavigate } from 'react-router-dom';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
export default function MainChart() {
    const navigate = useNavigate()
    return (
        <Page title="Chart">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Chart</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <Box>
                        <Box padding="10px">
                            <Typography variant="h5" >Speed</Typography>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <RootCardStyle onClick={() => {
                                    navigate('/report/chart/speedmonth')
                                }}>
                                    <IconWrapperStyle>
                                        <SpeedIcon fontSize="large" color="error" />
                                    </IconWrapperStyle>
                                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                                        Summary Overspeed
                                    </Typography>
                                </RootCardStyle>
                            </Grid>
                        </Grid>
                    </Box>
                </MainStyle>
            </Container>
        </Page>
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));

const RootCardStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    // color: theme.palette.info.darker,
    // backgroundColor: theme.palette.info.lighter,
    backgroundColor: 'whitesmoke',
    cursor: 'pointer'
}));
const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    // borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.info.dark,
    // backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    //     theme.palette.info.dark,
    //     0.24
    // )} 100%)`
}));