import { Badge, Box, Button, Card, Container, Divider, Fab, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import Page from '../components/Page'

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { Api } from '../components/api';



import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';


export default function Groups({ userId, account }) {
    const [masterGroup, setMasterGroup] = useState([])
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    useEffect(() => {
        Api.get(`Tracking/Group?cname=${account.c_coname}`)
            .then(res => {
                var list = res.data;
                setMasterGroup(list)
            }
            ).catch(e => {
                console.log('error', e)
            })

        // return () => {
        //     second
        // }
    }, [])





    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleSnackWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            // handleDel(item)
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };


    const handleDel = (group_id) => {
        const { c_coname } = account
        const data = {
            cname: c_coname,
            group_id: group_id
        }
        Api.delete('Tracking/Group', { data })
            .then(res => {
                handleGetData()
                handleSnackbar({ variant: 'success', message: 'success!' })
            }
            ).catch(error => {
                handleSnackbar({ variant: 'error', message: error.response.data.Message });
            }
            )
    };

    const handleGetData = () => {
        Api.get(`Tracking/Group?cname=${account.c_coname}`)
            .then(res => {
                var list = res.data;
                setMasterGroup(list)
            }
            ).catch(e => {
                console.log('error', e)
            })
    }

    return (
        <Page title="Groups">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Groups</Typography>
                </Box>
                <Divider />
                <MainStyle>

                    {
                        masterGroup.map((group, index) => (
                            <Card
                                sx={{
                                    borderRadius: '0px',
                                    marginBottom: '5px'
                                }}
                                elevation={3}
                                key={index}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        padding: '5px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            paddingY: "3px",
                                        }}
                                    >
                                        <Box sx={{
                                            justifyContent: "center",
                                            textAlign: "center",
                                            marginInline: '5px',
                                            paddingTop: '10px',
                                            borderRadius: 50,
                                            minWidth: 50,
                                            minHeight: 50,
                                            maxWidth: 50,
                                            maxHeight: 50,
                                            display: "flex",
                                            marginTop: "8px",
                                            alignItems: 'flex-start',
                                            background: 'rgb(182,33,254)',
                                            background: 'linear-gradient(90deg, rgba(30, 136, 229, 1) 0%, rgba(33, 150, 243, 1) 49%, rgba(187, 222, 251, 1) 100%)',
                                        }}>
                                            {
                                                group.icon !== "" && (
                                                    <img src={group.icon} alt='MainImgIcon' width={30} height={30} />

                                                )
                                            }
                                            {
                                                group.icon === "" && (
                                                    <ImageNotSupportedRoundedIcon sx={{
                                                        fontSize: "30px"
                                                    }} />
                                                )
                                            }
                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            overflowX: 'auto',
                                            borderRadius: 1,
                                            justifyContent: 'space-between',
                                            width: "calc(100% - 80px)",
                                            alignItems: 'flex-start'
                                        }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    paddingY: "3px",
                                                    width: 1
                                                }}
                                            >
                                                <Box >
                                                    <Typography>
                                                        {
                                                            group.group_name
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Box paddingX={1}>
                                                    <IconButton edge="start" aria-label="Edit" style={{ margin: 1 }}
                                                        onClick={() => {
                                                            navigate(`/settings/groups/edit`, { state: group })
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="start" aria-label="delete" style={{ margin: 1 }}
                                                        onClick={() => {
                                                            closeSnackbar()
                                                            handleSnackWithAction({
                                                                message: "Remove item?",
                                                                handle: () => {
                                                                    handleDel(group.group_id)
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <Typography fontSize="12px" color={"#A9A9A9"}>
                                                {
                                                    group.group_id
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        ))}


                    <FabStyle color="primary" aria-label='Add' onClick={() => {
                        navigate('/settings/groups/new')
                    }}>
                        <AddIcon />
                    </FabStyle>
                </MainStyle>
            </Container>
        </Page>
    )
}



const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 5,
    right: 5,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 65,
    overflow: 'auto'
}));