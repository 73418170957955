import { Container, ButtonGroup, Paper, TextField, Button, Typography, Divider, Box, Menu, MenuItem, List, ListItem, IconButton, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, ZoomControl, GeoJSON, Popup, Polygon, useMapEvent, CircleMarker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { MHidden } from '../../components/@material-extend';
import Page from '../../components/Page';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import { Icon } from '@iconify/react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Api } from '../../components/api';
import LayersIcon from '@mui/icons-material/Layers';
import ListTileLayer from '../../layouts/ListTileLayer';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from '@mui/lab';
import { fNumber } from '../../utils/formatNumber';


const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
        minWidth: '100%',
    }
}));
const SidebarStyle = styled('div')(({ theme }) => ({
    width: '100%',
    height: `calc(50vh - ${56 + 1}px)`,
    overflow: 'auto',
    padding: 10,
    [theme.breakpoints.up('md')]: {
        width: 320,
        height: `calc(100vh - ${56 + 1}px)`,
    },
}));
const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    overflow: 'hidden',
    height: '100vh',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
        height: '50vh'
    },
}));
const BottomNavContainerStyle = styled('div')(({ theme }) => ({
    position: "fixed",
    bottom: 0,
    left: 0,
    width: 320,
}));


const center = [13.839660198254604, 100.63469639884744];
const zoom = 13;

const useStyles = makeStyles(theme => ({
    polygonCursor: {
        // cursor: 'move !important'
        cursor: 'not-allowed !important'
    },
    polygonCursorCreate: {
        // cursor: 'move !important'
        cursor: 'crosshair !important'
    },
    LayerControl: {
        position: 'absolute',
        // backdropFilter: 'blur(10px)',
        // border: "2px solid rgba(0,0,0,0.2)",
        // borderRadius: 5,
        top: 10,
        [theme.breakpoints.up('md')]: {
            left: 320 + 11,

        },
        left: 11,
        zIndex: 2,
    },
    LayerForm: {
        position: "absolute",
        top: "10px",
        left: "45%",
        zIndex: 2,
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "5px",
        [theme.breakpoints.down('md')]: {
            left: "50%",
            transform: "translateX(-50%)"
        },
    },
    LayerControlMap: {
        position: 'fixed',
        display: 'block',
        backdropFilter: 'blur(10px)',
        border: "2px solid rgba(0,0,0,0.2)",
        borderRadius: 5,
        top: 90,
        right: 10,
        width: 32,
        height: 32,
        zIndex: 2,
    }
}))

export default function Geofences({ account }) {



    const refMapStyle = useRef(null);
    const refDropDownMapStyle = useRef(null);
    const [openDropDown, setDropDown] = useState({ mapStyle: false });
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")

    const handleOpenDropDown = (name) => {
        setDropDown({
            ...openDropDown,
            [name]: !openDropDown[name]
        });
    };

    const navigate = useNavigate()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        // const handleClick = useCallback((button) => () => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
        // }, [enqueueSnackbar]);
    }

    const handleClickWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };

    // }, [enqueueSnackbar, closeSnackbar]);

    const [map, setMap] = useState(null);
    const [addPolygon, setAddPolygon] = useState(false);
    const [polygonCreate, setPolygonCreate] = useState(null)
    const [polygonMove, setPolygonMove] = useState(null)
    // {
    //     type: "Polygon",
    //     properties: {
    //         name: "Polygon1",
    //         detail: "Polygon1",
    //     },
    //     coordinates: [
    //         [
    //             [-109.05, 41.00],
    //             [-102.06, 40.99],
    //             [-102.03, 36.99],
    //             [-109.04, 36.99],
    //             [-109.05, 41.00]
    //         ],
    //     ]
    // },
    const [polygonInit, setPolygonInit] = useState([])
    const [polygonList, setPolygonList] = useState([])
    // const map = useMap()
    const [geometrySelect, setGeometrySelect] = useState(null);
    const getPolygonList = useCallback(() => {
        const { cocode, c_coname } = account;
        Api.get(`Tracking/PoiStations?cocode=${cocode}&c_coname=${c_coname}`)
            .then(res => {
                var listPolygon = res.data;
                setPolygonList(listPolygon);
            }
            ).catch(e => {
                console.log('error', e)
            })
    }, [account]);
    useEffect(() => {
        getPolygonList()
    }, [getPolygonList])


    const getPolygon = ({ poi_id }) => {
        const { cocode, c_coname } = account;
        Api.get(`Tracking/PoiStation?cocode=${cocode}&c_coname=${c_coname}&poi_id=${poi_id}`)
            .then(res => {
                var polygon = res.data;
                const newPolygon = JSON.parse(polygon.poi_area)
                const rePolygon =
                {
                    ...newPolygon,
                    properties: {
                        poi_id: polygon.poi_id,
                        poi_name: polygon.poi_name,
                        cocode: polygon.cocode,
                        c_coname: polygon.c_coname,
                    },

                }
                map.flyToBounds(rePolygon.coordinates[0].map(e => [e[1], e[0]]), { duration: 1 })
                // console.log('polygon', rePolygon)
                setPolygonInit(
                    [rePolygon]
                )
                // setPolygonList(listPolygon);
            }
            ).catch(e => {
                console.log('error', e)
            })
    }


    const onEachFeature = (geofences, layer) => {
        layer.on({
            // mouseover: (e) => {
            //     e.target.setStyle({
            //         color: "red",
            //     });

            // },
            // mouseout: (e) => {
            //     e.target.setStyle({
            //         color: "black",
            //     });
            // },
            click: (event) => {
                if (!addPolygon) {
                    setGeometrySelect(geofences)
                }
            }
        });
    };
    const classes = useStyles();
    const getPolygonStyle = (feature) => {
        const geometry = feature.geometry
        return {
            color: geometry === geometrySelect ? 'red' : 'blue',
            className: geometry === geometrySelect ? classes.polygonCursor : addPolygon ? classes.polygonCursorCreate : '',
            weight: 2,
            lineJoin: 'round',
            dashArray: geometry === geometrySelect ? '2, 5' : '1, 1',
        }
    }

    const PageHidden = ({ hidden = false, children }) => {
        if (hidden) {
            return null;

        }
        return children;

    }

    const handleDel = ({ poi_id }) => {
        const { cocode, c_coname } = account;
        var data = {
            poi_id: poi_id,
            cocode: cocode,
            c_coname: c_coname,
        }
        Api.delete('/Tracking/PoiStation', { data })
            .then(res => {
                handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                setGeometrySelect(null)
                setPolygonInit([])
                getPolygonList();
            })
            .catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: message });
            })
    };
    const SidebarComponent = () => {
        return (
            <SidebarStyle>
                <Box sx={{ px: 1, py: 1 }}>
                    <Typography>Geofences</Typography>
                </Box>
                <List style={{ paddind: 10 }}>
                    {
                        polygonList.map((e, i) => (
                            <Fragment
                                key={'geofences' + i}
                            >
                                <ListItem
                                    disablePadding
                                    secondaryAction={
                                        <Box paddingX={1}>
                                            <IconButton edge="start" size="small" aria-label="Edit" style={{ margin: 1 }}
                                                onClick={() => {
                                                    navigate(`/settings/geofences/geofence/${e.poi_id}`)
                                                }}
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton edge="start" size="small" aria-label="delete" style={{ margin: 1 }}
                                                onClick={() => {
                                                    closeSnackbar()
                                                    handleClickWithAction({
                                                        message: 'Remove item?',
                                                        handle: () => {
                                                            handleDel({ poi_id: e.poi_id })
                                                        }
                                                    })
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    }>
                                    <ListItemButton
                                        onClick={() => {
                                            setGeometrySelect(null)
                                            // map.flyToBounds(e.coordinates[0].map(e => [e[1], e[0]]), { duration: 1 })
                                            getPolygon({ poi_id: e.poi_id })
                                        }}
                                    >
                                        <ListItemText
                                            primary={e.poi_name}
                                        // secondary={'e.deviceid'}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {i + 1 !== polygonList.length ? <Divider /> : null}
                            </Fragment>
                        ))
                    }
                </List>
            </SidebarStyle >
        )
    }

    const BackdropSelect = ({ onClick }) => {
        const map = useMapEvents({
            click: (e) => {
                onClick()
                return null;
            },
        });
        return null;
    }

    const LocationOnClick = ({ onClick, onMouseMove, onKeyDown }) => {
        const map = useMapEvents({
            click: (e) => {
                onClick(e.latlng)
            },
            mousemove: (e) => {
                onMouseMove(e.latlng)
            },
            keydown: (e) => {
                // console.log('e', e.originalEvent.key)
                onKeyDown(e.originalEvent.key)
            }

        });
        return null;
    };

    const handleClearCreate = () => {
        setGeometrySelect(null);
        setPolygonCreate(null)
        setPolygonMove(null)
        map.getContainer().style.cursor = '';
        setAddPolygon(false)
    }
    const handleData = () => {
        return polygonInit
    }


    const listTileLayer = ListTileLayer

    const ReplaySchema = Yup.object().shape({
        latlng: Yup.string().required('LatLng is required').nullable(),
    });
    const formik = useFormik({
        initialValues: {
            latlng: '',
        },
        validationSchema: ReplaySchema,
        onSubmit: (values) => {
            const lat = values.latlng?.split(",")[0]
            const lng = values.latlng?.split(",")[1].replace(' ', '')

            const latlng = [parseFloat(lat), parseFloat(lng)]

            // map.flyTo([14.149223003958738, 100.87883523921539], { duration: 1 })
            map.flyTo(latlng, map.getZoom(), {
                "animate": true,
                "duration": 1,
            })
            // console.log('latlng', latlng)
        }
    });
    const { errors, touched, isSubmitting, values, setFieldValue, handleSubmit, getFieldProps } = formik;


    return (
        <Page title="Map">
            <RootStyle>
                <MHidden width="mdDown">
                    <SidebarComponent map={map} />
                    <BottomNavContainerStyle>
                        <BottomNavigationBar ative="settings" />
                    </BottomNavContainerStyle>
                </MHidden>
                <MainStyle >
                    <Box className={classes.LayerForm} sx={{ justifyContent: 'center' }}
                    >
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Paper
                                    // component="form"
                                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
                                >
                                    <TextField
                                        label="location"
                                        fullWidth
                                        size="small"
                                        autoComplete="off"
                                        name="latlng"
                                        {...getFieldProps('latlng')}
                                        error={Boolean(touched.latlng && errors.latlng)}
                                    />
                                    <IconButton type="submit" sx={{ p: "10px" }} size="small" aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Form>
                        </FormikProvider>
                    </Box>
                    <Box className={classes.LayerControlMap} sx={{ justifyContent: 'center' }}
                        ref={refDropDownMapStyle}
                    >
                        <IconButton edge="end" size="small"
                            style={{ backgroundColor: 'white', borderRadius: 5, }}
                            onClick={() => {
                                handleOpenDropDown('mapStyle')
                            }}>
                            <LayersIcon fontSize="inherit" />
                        </IconButton>
                        <Menu
                            open={openDropDown.mapStyle}
                            anchorEl={refDropDownMapStyle.current}
                            onClose={() => handleOpenDropDown('mapStyle')}
                            PaperProps={{
                                sx: { width: 200, maxWidth: '100%' }
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <Menu
                                open={openDropDown.mapStyle}
                                anchorEl={refDropDownMapStyle.current}
                                onClose={() => handleOpenDropDown('mapStyle')}
                                PaperProps={{
                                    sx: { width: 200, maxWidth: '100%' }
                                }}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                {
                                    listTileLayer.map((value, index) =>
                                        <MenuItem
                                            sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                                            onClick={
                                                () => {
                                                    handleOpenDropDown('mapStyle')
                                                    if (refMapStyle.current) {
                                                        refMapStyle.current.setUrl(value.url);
                                                        setSelectedMapStyle(value.id)
                                                        localStorage.setItem('selectedMapStyle', value.id)
                                                    }
                                                }
                                            }
                                            key={index}
                                        >
                                            {
                                                value.name
                                            }
                                        </MenuItem>
                                    )
                                }
                            </Menu>
                        </Menu>
                    </Box>
                    <Box className={classes.LayerControl} sx={{ justifyContent: 'center' }}
                    >
                        <ButtonGroup size='small' color="inherit" sx={{
                            backgroundColor: 'white',
                            boxShadow: "0 0 0 2px rgba(0,0,0,.1)",
                            borderRadius: '2px',
                        }}
                            variant="outlined" orientation='vertical'>
                            <Button size="small" sx={{ borderRadius: "3px", border: 0, minWidth: "30px !important", textTransform: "none", padding: "5px 0px" }}
                                onClick={() => {
                                    if (geometrySelect) {
                                        setGeometrySelect(null)
                                    }
                                    if (polygonCreate !== null) {
                                        const value = polygonCreate
                                        handleClearCreate()
                                        navigate(`/settings/geofences/geofence`, { state: value })
                                    }
                                    map.getContainer().style.cursor = !addPolygon ? 'crosshair' : '';
                                    setAddPolygon(!addPolygon)
                                }}
                            >
                                <Icon icon="ph:polygon-thin" fontSize="22px" />
                            </Button>

                            {/* <Button size="small" sx={{ borderRadius: "3px", minWidth: "30px !important", borderWidth: '1px 0 0 0', borderTopColor: "#ccc", textTransform: "none", padding: "5px 0px" }}
                                onClick={
                                    () => {
                                        
                                        handleClearCreate()
                                    }
                                }
                            >
                                <PlaceIcon style={{ fontSize: "22px" }} />
                            </Button> */}

                            <Button size="small" sx={{ borderRadius: "3px", minWidth: "30px !important", borderWidth: '1px 0 0 0', borderTopColor: "#ccc", textTransform: "none", padding: "5px 0px" }}
                                onClick={
                                    () => {
                                        if (geometrySelect) {
                                            closeSnackbar()
                                            handleClickWithAction({
                                                message: 'Remove item?',
                                                handle: () => {
                                                    handleDel({ poi_id: geometrySelect.properties.poi_id })
                                                    setGeometrySelect(null)
                                                }
                                            })
                                            setGeometrySelect(null)
                                            return
                                        }
                                        handleClearCreate()
                                    }
                                }
                            >
                                <DeleteIcon style={{ fontSize: "22px" }} />
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <MapContainerStyle
                        whenCreated={setMap}
                        center={center}
                        zoom={zoom}
                        minZoom={3}
                        zoomControl={false}
                        scrollWheelZoom={true}
                        doubleClickZoom={false}
                        attributionControl={false}
                    >
                        <ZoomControl position='topright' />
                        <TileLayer ref={refMapStyle}
                            url={listTileLayer.find(value => value.id === selectedMapStyle).url}
                            maxNativeZoom={19}
                            maxZoom={22}
                        />
                        <PageHidden hidden={false}>
                            <GeoJSON data={polygonInit}
                                onEachFeature={onEachFeature}
                                style={getPolygonStyle}
                            />
                        </PageHidden>
                        <PageHidden hidden={geometrySelect === null}>
                            <BackdropSelect onClick={() => {
                                if (geometrySelect !== null) {
                                    setGeometrySelect(null)
                                }
                                return
                            }} />
                        </PageHidden>
                        <PageHidden hidden={!addPolygon}>
                            <LocationOnClick
                                onClick={
                                    ({ lat, lng }) => {
                                        const lnglat = [lng, lat]

                                        if (polygonCreate === null) {
                                            const newValue = {
                                                type: "Polygon",
                                                coordinates: [
                                                    [
                                                        lnglat,
                                                    ],
                                                ]
                                            }
                                            setPolygonCreate(newValue)
                                        } else {
                                            const coordinates = [polygonCreate.coordinates[0].concat([lnglat])]
                                            setPolygonCreate({ ...polygonCreate, coordinates })
                                        }
                                    }
                                }
                                onMouseMove={
                                    ({ lat, lng }) => {
                                        const lnglat = [lng, lat]
                                        // if (polygonCreate !== null) {
                                        //     console.log('coordinates', polygonCreate.coordinates[0][0])
                                        // }
                                        if (polygonCreate !== null) {
                                            const newValue = {
                                                type: "Polygon",
                                                coordinates: [
                                                    [
                                                        ...polygonCreate.coordinates[0],
                                                        lnglat
                                                    ],
                                                ]
                                            }
                                            setPolygonMove(newValue)
                                        }
                                    }
                                }
                                onKeyDown={(key) => {
                                    // Escape
                                    // Enter
                                    // Backspace
                                    if (key === "Escape") {
                                        handleClearCreate()
                                    }
                                    if (key === "Backspace") {
                                        if (polygonCreate.coordinates[0].length > 0) {
                                            const coordinates = [polygonCreate.coordinates[0].slice(0, polygonCreate.coordinates[0].length - 1)]
                                            setPolygonCreate({ ...polygonCreate, coordinates })
                                        }
                                    }
                                    if (key === "Enter") {
                                        const { coordinates } = polygonCreate
                                        const newCoordinates = [
                                            [
                                                ...coordinates[0],
                                                coordinates[0][0]
                                            ],
                                        ]
                                        const value = { ...polygonCreate, coordinates: newCoordinates }
                                        handleClearCreate()
                                        navigate(`/settings/geofences/geofence`, { state: value })
                                    }

                                }}
                            />
                            {/* <GeoJSON data={polygonCreate}
                                style={{
                                    color: 'orange',
                                    className: classes.polygonCursorCreate,
                                    weight: 2,
                                    lineJoin: 'round',
                                    dashArray: '5, 5',
                                }}
                            /> */}
                            {
                                polygonMove?.type === "Polygon" && (
                                    <>
                                        <GeoJSON data={polygonMove}
                                            style={{
                                                color: 'red',
                                                className: classes.polygonCursorCreate,
                                                weight: 2,
                                                lineJoin: 'round',
                                                dashArray: '5, 5',
                                            }}
                                        />
                                        <GeoJSON data={
                                            {
                                                type: "MultiPoint",
                                                properties: {
                                                },
                                                coordinates: polygonCreate !== null ? polygonCreate.coordinates[0] : []
                                            }
                                        }
                                            style={{
                                                // className: name === geometrySelect ? classes.polygonCursor : '',
                                                weight: 2,
                                            }}
                                            pointToLayer={(feature, latlng) => {
                                                // return <CircleMarker center={latlng} />;
                                                return L.circleMarker(latlng, {
                                                    weight: 20,
                                                    radius: 5,
                                                    fillColor: "white",
                                                    fillOpacity: 10
                                                });
                                            }}
                                        />
                                    </>
                                )
                            }
                        </PageHidden>
                    </MapContainerStyle>
                    <MHidden width="mdUp">
                        <SidebarComponent />
                        <BottomNavigationBar ative="settings" />
                    </MHidden>
                </MainStyle>
            </RootStyle >
        </Page >
    )
}

