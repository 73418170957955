import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Stack, Paper, AppBar, Toolbar, IconButton, Typography, Drawer, useMediaQuery, Backdrop, Snackbar, Button } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ContainerMap from './ContainerMap';
import ControlSide from './ControlSide';
import { MHidden } from '../../components/@material-extend';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import CreateGroup from './CreateGroup';
import { Api } from '../../components/api';
import EditGroup from './EditGroup';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';


export default function MainPoi({ account }) {
    const [open, setOpen] = useState(false);
    const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { c_coname } = account;
    useEffect(() => {
        if (isFullScreen) {
            setOpen(false)
        }
    }, [isFullScreen]);


    const [listGroupPoi, setListGroupPoi] = useState([]);


    useEffect(() => {
        handleOpenBackdrop()
        Api.get(`/Tracking/poigeocodegroups?cname=${c_coname}`)
            // Api.get(`/Tracking/poigeocodegroups?cname=FIRST=${cocode}`)
            .then(res => {
                setListGroupPoi(res.data)
                handleCloseBackdrop()
            }
            )
    }, [Api, c_coname]);


    const [map, setMap] = useState(null);
    const refMapStyle = useRef(null);
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap");
    const [poiSelect, setPoiSelect] = useState(null);
    const [editRef, setEditRef] = useState(null);
    const [editMode, setEditMode] = useState('');
    const refControlPOI = React.useRef(null);
    const [modeFun, setModeFun] = useState('');
    const ControlSideMemo = React.memo(ControlSide)

    const [listPoiShow, setListPoiShow] = useState([]);
    const [openCreateGroup, setOpenCreateGroup] = useState(false);
    const [openEditGroup, setOpenEditeGroup] = useState(false);
    const [handleDataGroup, setHandleDataGroup] = useState({});
    const [handleEditData, setHandleEditData] = useState(null);
    const [openDelGroup, setOpenDelGroup] = useState(null);

    const onCloseCreateGroup = () => {
        setOpenCreateGroup(false)
    }

    const onEditGroup = (group) => {
        setHandleDataGroup(group)
        setOpenEditeGroup(true)
    }

    const onCloseEditGroup = () => {
        setOpenEditeGroup(false)
    }
    const onSubmitEditGroup = (data) => {
        setOpenEditeGroup(false)
        handleOpenBackdrop()
        const dataSubmit = {
            ...data,
            cname: c_coname
        }
        Api.put(`/Tracking/poigeocodegroup`, dataSubmit)
            .then(
                (res) => {
                    // setListGroupPoi(g => g.concat(res.data))
                    Api.get(`/Tracking/poigeocodegroups?cname=${c_coname}`)
                        .then(res => {
                            setListGroupPoi(res.data)
                            handleCloseBackdrop()
                        }
                        )
                }
            )
    }

    const onSubmitCreateGroup = (data) => {
        setOpenCreateGroup(false)
        handleOpenBackdrop()
        const dataSubmit = {
            ...data,
            cname: c_coname
        }
        Api.post(`/Tracking/poigeocodegroup`, dataSubmit)
            .then(
                (res) => {
                    // setListGroupPoi(g => g.concat(res.data))
                    Api.get(`/Tracking/poigeocodegroups?cname=${c_coname}`)
                        .then(res => {
                            setListGroupPoi(res.data)
                            handleCloseBackdrop()
                        }
                        )
                }
            )
    }

    const handleClickDelGroup = (del) => {
        setOpenDelGroup(del);
    };

    const handleCloseDelGroup = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDelGroup(null);
    };

    const onSubmitDelGroup = () => {
        handleOpenBackdrop()
        const req = { ...openDelGroup, cname: c_coname }
        setOpenDelGroup(null);
        Api.delete(`/Tracking/poigeocodegroup`, { data: req })
            .then(res => {
                // setListPoiShow(l => l.concat(res.data))
                setListPoiShow(e => e.filter(p => p.group_id !== req?.group_id))
                setListGroupPoi(e => e.filter(p => p.group_id !== req?.group_id))
                handleCloseBackdrop()
            }
            )

    }

    const handleCreated = (poi) => {
        if (refControlPOI.current?.getLayers().length !== 0) {
            refControlPOI.current.clearLayers()
        }
        const created = {
            "cname": c_coname,
            "poi_id": '',
            "poi_name": '',
            "group_id": '',
            "type": "Feature",
            "properties": {
                "radius": Math.floor(poi.properties?.radius || 0),
                "shape": poi.properties.shape || ''
            },
            "geometry": {
                "type": poi.geometry.type,
                "coordinates": poi.geometry.coordinates
            }
        }
        // console.log('created', created)
        setPoiSelect(created)
        setHandleEditData(created)
        setModeFun('create')
    }

    const editResize = (radius) => {
        setHandleEditData(prevState => {
            return {
                ...prevState,
                properties: {
                    ...prevState?.properties,
                    radius: radius,

                }
            }
        })

    }

    const onGetPoiGroup = (group_id) => {
        handleOpenBackdrop()
        Api.get(`/Tracking/poigeocodegroup?cname=${c_coname}&group_id=${group_id}`)
            .then(
                (res) => {
                    const poi_id = res.data.map(e => e.id)
                    const req = {
                        "cname": c_coname,
                        "poi_id": poi_id
                    }
                    Api.post(`/Tracking/poigeocodelist`, req)
                        .then(res => {
                            // setListPoiShow(res.data)
                            setListPoiShow(e => e.filter(p => p.group_id !== group_id).concat(res.data))
                            handleCloseBackdrop()
                        }
                        )
                }
            )
    }

    const editMove = (latLng) => {
        setHandleEditData(prevState => {
            return {
                ...prevState,
                geometry: {
                    ...prevState?.geometry,
                    coordinates: latLng,

                }
            }
        })

    }








    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleOpenBackdrop = () => {
        setOpenBackdrop(true);
    };



    const [openDel, setOpenDel] = useState(null);

    const handleClickDel = (del) => {
        setOpenDel(del);
    };

    const handleCloseDel = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDel(null);
    };

    const onSubmitDel = () => {
        handleOpenBackdrop()
        const req = { ...openDel }
        setOpenDel(null);
        console.log('req', req)
        Api.delete(`/Tracking/poigeocode`, { data: req })
            .then(res => {
                // setListPoiShow(l => l.concat(res.data))
                setListPoiShow(e => e.filter(p => p.poi_id !== req?.poi_id))
                handleCloseBackdrop()
            }
            )

    }
    const [search, setSearch] = useState("");



    const onHandleChange = (value) => {
        // const { value } = e.target
        setSearch(value)

    }
    const getlistPoi = () => {
        return _.filter(listPoiShow, (s => s.poi_name.toLocaleUpperCase().includes(search.toLocaleUpperCase())))
    }
    return (
        <RootStyle>
            <AppBarStyle elevation={3} >
                <ToolbarStyle>
                    <IconButton onClick={() => setOpen(!open)} sx={{ mr: 1, color: 'text.primary' }}>
                        <MenuRoundedIcon />
                    </IconButton>
                </ToolbarStyle>
            </AppBarStyle>
            <DrawerStyle >
                <MHidden width="mdUp">
                    <Drawer
                        open={open}
                        onClose={() => setOpen(!open)}
                        // variant="permanent"
                        PaperProps={{
                            sx: { width: 320 }
                        }}
                    >
                        <Box sx={{ px: 1, py: 1 }}>
                            <Toolbar style={{ minHeight: '64px', paddingLeft: 35, paddingRight: 35 }}>
                                <Typography variant='h4' >POI</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Toolbar>
                        </Box>
                        <ControlSide
                            onOpenCreateGroup={() => {
                                setOpenCreateGroup(true)
                            }}
                            listGroupPoi={listGroupPoi}
                            onEditGroup={onEditGroup}
                            listPoiShow={getlistPoi()}
                            setListPoiShow={setListPoiShow}
                            onGetPoiGroup={onGetPoiGroup}
                            setModeFun={setModeFun}
                            setHandleEditData={setHandleEditData}
                            setPoiSelect={setPoiSelect}
                            map={map}
                            handleClickDel={handleClickDel}
                            handleClickDelGroup={handleClickDelGroup}
                            onHandleChange={onHandleChange}
                        />
                    </Drawer>
                </MHidden>
                <MHidden width="mdDown">
                    <Drawer
                        open={true}
                        variant="permanent"
                        PaperProps={{
                            sx: { width: 320, height: 'calc(100% - 56px)', bottom: 56, padding: '10px' }
                        }}
                    >
                        <Box sx={{ px: 1, py: 1 }}>
                            <Toolbar style={{ minHeight: '64px', paddingLeft: 10, paddingRight: 10 }}>
                                <Typography variant='h4' >POI</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Toolbar>
                        </Box>

                        <ControlSide
                            onOpenCreateGroup={() => {
                                setOpenCreateGroup(true)
                            }}
                            listGroupPoi={listGroupPoi}
                            onEditGroup={onEditGroup}
                            listPoiShow={getlistPoi()}
                            setListPoiShow={setListPoiShow}
                            onGetPoiGroup={onGetPoiGroup}
                            setModeFun={setModeFun}
                            setHandleEditData={setHandleEditData}
                            setPoiSelect={setPoiSelect}
                            map={map}
                            handleClickDel={handleClickDel}
                            handleClickDelGroup={handleClickDelGroup}
                            onHandleChange={onHandleChange}
                        />

                        <Paper sx={{ position: 'fixed', width: 320, bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                            <BottomNavigationBar ative="settings" />
                        </Paper>
                    </Drawer>
                </MHidden>
            </DrawerStyle>
            <MainStyle>
                <ContainerMap
                    map={map}
                    setMap={setMap}
                    refMapStyle={refMapStyle}
                    selectedMapStyle={selectedMapStyle}
                    setSelectedMapStyle={setSelectedMapStyle}
                    poiSelect={poiSelect}
                    setPoiSelect={setPoiSelect}
                    handleEditData={handleEditData}
                    setHandleEditData={setHandleEditData}
                    editRef={editRef}
                    setEditRef={setEditRef}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    handleCreated={handleCreated}
                    refControlPOI={refControlPOI}
                    listPoiShow={getlistPoi()}
                    listGroupPoi={listGroupPoi}
                    editResize={editResize}
                    editMove={editMove}
                    modeFun={modeFun}
                    setModeFun={setModeFun}
                    setListPoiShow={setListPoiShow}

                    handleOpenBackdrop={handleOpenBackdrop}
                    handleCloseBackdrop={handleCloseBackdrop}

                    onGetPoiGroup={onGetPoiGroup}
                />
                <CreateGroup
                    open={openCreateGroup}
                    onClose={onCloseCreateGroup}
                    handleSubmit={onSubmitCreateGroup}
                />
                <EditGroup
                    open={openEditGroup}
                    onClose={onCloseEditGroup}
                    handleSubmit={onSubmitEditGroup}
                    handleDataGroup={handleDataGroup}
                    handleOpenBackdrop={handleOpenBackdrop}
                    handleCloseBackdrop={handleCloseBackdrop}
                />
                <MHidden width="mdUp">
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigationBar ative="settings" />
                    </Paper>
                </MHidden>
            </MainStyle>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openDel !== null}
                autoHideDuration={6000}
                onClose={handleCloseDel}
                action={
                    <React.Fragment>
                        <Button
                            color="inherit"
                            variant="text"
                            size="small"
                            onClick={onSubmitDel}
                        >
                            YES
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseDel}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                message={`You want to delete "${openDel?.poi_name !== undefined ? openDel?.poi_name : ''}" ?`}
                ContentProps={{
                    sx: {
                        background: "#ed6c02"
                    }
                }}

            />

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={openDelGroup !== null}
                autoHideDuration={6000}
                onClose={handleCloseDelGroup}
                action={
                    <React.Fragment>
                        <Button
                            color="inherit"
                            variant="text"
                            size="small"
                            onClick={onSubmitDelGroup}
                        >
                            YES
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseDelGroup}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                message={`You want to delete "${openDelGroup?.name !== undefined ? openDelGroup?.name : ''}" ?`}
                ContentProps={{
                    sx: {
                        background: "#ed6c02"
                    }
                }}

            />


        </RootStyle >
    )
}



























const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
        paddingTop: 66,
        paddingBottom: 56,
    }
}));


const AppBarStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    width: 0,
    maxHeight: 64,
    [theme.breakpoints.down('md')]: {
        // width: `calc(100% - ${350 + 1}px)`
        width: "100%"
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    minHeight: '64px',
}));
const DrawerStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        width: 320
    },
    width: 0,
}));