import styled from '@emotion/styled';
import { Autocomplete, Box, Collapse, Container, Divider, Grid, List, ListItemButton, Button, ListItemText, ListItem, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TransferList from './TransferList';


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingBottom: '60px'
}));

export default function NewRouteTrips({ account }) {
    const navigate = useNavigate();
    const params = useParams()

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const [open, setOpen] = useState({ required: true, dialog: false })
    const [stations, setStations] = useState([
        { id: 'YT-01', name: 'แฮปปี้แลนด์' },
        { id: 'YT-01', name: 'ซอย นวมินทร์10การเคหะแห่งชาติ' },
        { id: 'YT-01', name: 'ตรงข้ามโรงเรียนบ้านบางกะปิ' },
        { id: 'YT-01', name: 'เคหะคลองจั่น (แฟลต23)' },
        { id: 'YT-01', name: 'เดอะมอลล์บางกะปิ ' },
        { id: 'YT-01', name: 'เดอะมอลล์บางกะปิ ' },
        { id: 'YT-01', name: 'โรงพยาบาลเวชธานี (ลาดพร้าว136)' },
        { id: 'YT-01', name: 'วัดกลาง' },
        { id: 'YT-01', name: 'ลาดพร้าว 130 (ซอย มหาดไทย2)' },
        { id: 'YT-01', name: 'ลาดพร้าว 101 ' },
        { id: 'YT-01', name: 'ลาดพร้าว 126 ' },
        { id: 'YT-01', name: 'ลาดพร้าว 122 (ซอย มหาดไทย 1)' },
        { id: 'YT-01', name: 'ตรงข้ามโรงพยาบาลลาดพร้าว ' },
        { id: 'YT-01', name: 'ลาดพร้าว 87,112,โรงเรียนบางกอกศึกษา' },
        { id: 'YT-01', name: 'สำนักงานเขตวังทองหลาง ' },
        { id: 'YT-01', name: 'บิ๊กซีลาดพร้าว ' },
        { id: 'YT-01', name: 'ลาดพร้าว 71' },
        { id: 'YT-01', name: 'ลาดพร้าว 67/1 (ลาดพร้าว80) ' },
        { id: 'YT-01', name: 'ลาดพร้าว 78' },
        { id: 'YT-01', name: 'ลาดพร้าว 57 (กรมแผนที่ทหาร)' },
        { id: 'YT-01', name: 'ตลาดโชคชัย 4 ' },
        { id: 'YT-01', name: 'ตลาดสะพาน 2 ' },
    ])
    const [listStation, setListStation] = useState([])
    const DeviceSchema = Yup.object().shape({

        route_no: Yup.string().required('Route No is required').nullable(),
        route_name: Yup.string().required('Route name is required').nullable(),

    })
    const formik = useFormik({
        initialValues: {
            route_no: params.route_no,
            route_name: ''
        },
        validationSchema: DeviceSchema,
        onSubmit: (values) => {
            const data = JSON.stringify({
                "route_no": values.route_no,
                "route_name": values.route_name
            });
            Api.post('/v0/Busticket/Route', data)
                .then(res => {
                    handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                    navigate('/settings/routes')
                }
                ).catch(error => {
                    const { message } = error
                    handleSnackbar({ variant: 'error', message: message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const handleClick = (name) => {
        setOpen({ ...open, [name]: !open[name] });
    };
    const handleOnClose = () => {
        navigate(`/settings/routes/${params.route_no}`)
    }



    return (
        <Page title="routes" >
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Route New</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                                <Box width={400} margin="auto" padding={2}
                                >
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={() => {
                                            handleClick("required")
                                        }} >
                                            <ListItemText primary="required" />
                                            {
                                                open.required ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.required} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled
                                                            fullWidth
                                                            size="small"
                                                            label="เลขที่สายเดินรถ"
                                                            {...getFieldProps('route_no')}
                                                            error={Boolean(touched.route_no && errors.route_no)}
                                                            helperText={touched.route_no && errors.route_no}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ชื่อสายเดินรถ"
                                                            {...getFieldProps('route_name')}
                                                            error={Boolean(touched.route_name && errors.route_name)}
                                                            helperText={touched.route_name && errors.route_name}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        <Box padding={2}>
                                            {/* <DraggableList /> */}
                                            {
                                                listStation.map((values, index) =>
                                                    <ListItem key={index}>
                                                        <ListItemText primary={values.name} />
                                                    </ListItem>
                                                )
                                            }
                                        </Box>
                                        <Box padding={2}>
                                            <Button
                                                fullWidth
                                                onClick={
                                                    () => {
                                                        handleClick('dialog')
                                                    }
                                                }
                                                size="medium"
                                                variant="outlined"
                                                color="info"
                                            >
                                                เลือกสถานี
                                            </Button>
                                            <TransferList
                                                handleClose={(_, reason) => {
                                                    if (reason !== "backdropClick") {
                                                        handleClick('dialog')
                                                    }
                                                }}
                                                open={open.dialog}
                                                stations={stations}
                                                listStation={listStation} ปป
                                                onSubmit={(val) => {
                                                    setListStation(val)
                                                }} />
                                        </Box>
                                        <Divider style={{ marginTop: 10 }} />
                                        <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                            <Grid item xs={6} justifyContent="center">
                                                <Button
                                                    fullWidth
                                                    onClick={handleOnClose}
                                                    size="medium"
                                                    variant="outlined"
                                                    color="info"
                                                // fullWidth
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} justifyContent="center">
                                                <LoadingButton
                                                    fullWidth
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    color="info"
                                                    loading={isSubmitting}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </MainStyle>
            </Container>
        </Page>
    )
}
