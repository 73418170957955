import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import FindInPageIcon from '@mui/icons-material/FindInPage';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogDriver from './DialogDriver';
import DialogBushostess from './DialogBushostess';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';

export default function ButtonDialogWorkUpdate({ data, disabled, onHandle }) {
    const [work, setWork] = useState(data)

    // const drivers = [
    //     {
    //         "employee_id": "EMP001",
    //         "name": "ประสิทธิ์ จงจิตกลาง",
    //         "phone": "+1987654321"
    //     },
    //     {
    //         "employee_id": "EMP002",
    //         "name": "ปริญญา ศรีท้วม",
    //         "phone": "+1122334455"
    //     }
    // ];

    const [open, setOpen] = useState(false)


    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        setOpen(false)
        onHandle(work)
    }

    const handleDelDriver = (id) => {
        const { drivers } = work
        setWork(w => { return ({ ...w, drivers: drivers.filter(d => d.employee_id !== id) }) })

    }

    const handleChangeDriver = (drivers) => {
        // const { drivers } = work
        setWork(w => { return ({ ...w, drivers: drivers }) })
    }

    const handleDelBushostess = (id) => {
        const { bushostess } = work
        setWork(w => { return ({ ...w, bushostess: bushostess.filter(d => d.employee_id !== id) }) })

    }

    const handleChangeBushostess = (bushostess) => {
        // const { drivers } = work
        setWork(w => { return ({ ...w, bushostess: bushostess }) })
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setWork(e => {
            return ({ ...e, [name]: value })
        })
    }

    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><FindInPageIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียด ใบงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่' placeholder='เลขที่' onChange={handleChange} value={work.work_id} name='work_id' fullWidth />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='ประเภท' placeholder='ประเภท' onChange={handleChange} value={work?.work_type} name='work_type' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='สถานที่รับ' onChange={handleChange} value={work?.pickupLocation} name='pickupLocation' fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => {
                                                    }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='วันที่' onChange={handleChange} value={work?.pickupDate} name='pickupDate' fullWidth
                                        placeholder='DD-MM-YYYY'
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='รับเวลา' onChange={handleChange} placeholder='HH:mm' value={work?.pickupTime} name='pickupTime' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='สถานที่ส่ง' onChange={handleChange} value={work?.returnLocation} name='returnLocation' fullWidth
                                        // InputLabelProps={{ shrink: formValue.localEnd ? true : false }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => {
                                                        // handleOpenForm("dialogLocation")
                                                        // setDialogFieldName("localEnd")
                                                    }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='ส่งเวลา' onChange={handleChange} value={work?.returnDate} placeholder='DD-MM-YYYY' name='returnDate' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='ส่งเวลา' onChange={handleChange} value={work?.returnTime} placeholder='HH:mm' name='returnTime' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='ประเภทรถ' placeholder='ประเภทรถ' onChange={handleChange} value={work?.car_type} name='car_type' fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => {
                                                        // handleOpenForm("dialogLocation")
                                                        // setDialogFieldName("localStart")
                                                    }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='ทะเบียนรถ' placeholder='ทะเบียนรถ' onChange={handleChange} value={work?.licensePlate} name='licensePlate' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='เลขที่รถ' placeholder='เลขที่รถ' onChange={handleChange} value={work?.car_id} name='car_id' fullWidth />
                                </Grid>


                                <Grid item xs={12} md={12}>
                                    <DialogDriver data={work.drivers} onChange={(drivers) => {
                                        handleChangeDriver(drivers)
                                    }} />
                                </Grid>
                                {/* ************************พนักงานขับรถ************************ */}
                                {
                                    work?.drivers.map((driver, index) => {
                                        return (
                                            <Grid item xs={12} md={12} key={index}>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={5} md={5}>
                                                        <TextField size="small" disabled label='ชื่อนามสกุล' placeholder='ชื่อนามสกุล' value={driver.name} name='name' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <TextField size="small" disabled label='เบอร์โทร' placeholder='เบอร์โทร' value={driver.phone} name='dateStart' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={1} md={1}>
                                                        <Button variant="contained" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลทริป?", onHandle: () => { handleDelDriver(driver?.employee_id) } }) }} ><DeleteOutlineIcon /></Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* ************************พนักงานขับรถ************************ */}

                                <Grid item xs={12} md={12}>
                                    <DialogBushostess data={work.bushostess} onChange={(bushostess) => {
                                        handleChangeBushostess(bushostess)
                                    }} />
                                </Grid>

                                {/* ************************พนักงานต้อนรับ************************ */}
                                {
                                    work?.bushostess.map((bushost, index) => {
                                        return (
                                            <Grid item xs={12} md={12} key={index}>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={5} md={5}>
                                                        <TextField size="small" disabled label='ชื่อนามสกุล' placeholder='ชื่อนามสกุล' value={bushost.name} name='name' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <TextField size="small" disabled label='เบอร์โทร' placeholder='เบอร์โทร' value={bushost.phone} name='dateStart' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={1} md={1}>
                                                        <Button variant="contained" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลทริป?", onHandle: () => { handleDelDriver(bushost?.employee_id) } }) }}  ><DeleteOutlineIcon /></Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* ************************พนักงานต้อนรับ************************ */}


                                <Grid item xs={12} md={12}>
                                    <TextField size="small" label='หมายเหตุ' value={``} multiline maxRows={3} minRows={3} name='remake' fullWidth />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
