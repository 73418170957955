import { Container, Fab, Card, Menu, MenuItem, alpha, TablePagination, List, ListItem, InputAdornment, ListItemButton, Button, TextField, ListItemIcon, IconButton, ListItemText, Box, Divider, Typography } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Page from '../../components/Page'
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import { Api } from '../../components/api';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';

const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 0,
    overflow: 'auto'
}));
export default function MainDevice({ account }) {
    const [listDevices, setDevice] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);
    const [group, setGroup] = useState(null);
    const [groups, setGroups] = useState([]);
    const [search, setSearch] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const getDevices = useCallback(() => {

        const { c_coname } = account;
        Api.get(`/v0/gps/devices?cname=${c_coname}&PageSize=9999999&Page=1`)
            .then(res => {
                var listDevices = res.data.devices;
                setDevice(listDevices);
                setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
            })
        Api.get(`Tracking/Group?cname=${c_coname}`)
            .then(res => {
                var list = res.data;
                setGroups(list)
            }
            ).catch(e => {
                console.log('error', e)
            })
    }, [account]);


    useEffect(() => {
        getDevices()
    }, [getDevices])


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleClickWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            // handleDel(item)
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };
    const handleDel = (item) => {
        // await Api.delete('/device?_id=' + item._id)
        var data = {
            cocode: item.cocode,
            cobcode: item.cobcode,
            deviceid: item.deviceid
        }
        Api.delete('/v0/gps/device', { data })
            .then(res => {
                handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                getDevices();
            })
            .catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: message });
            })
    };


    const handleUpDLT = (item) => {

        const data = JSON.stringify({
            ...item,
            dlt_status: !item.dlt_status
        });
        Api.put('/v0/gps/device', data)
            .then(res => {
                if (!item.dlt_status) {
                    const dataUp = JSON.stringify({
                        cocode: item.cocode,
                        cobcode: item.cobcode,
                        deviceid: item.deviceid,
                    });
                    Api.post('v0/gps/masterfile_dlt', dataUp).then(res => {
                        handleSnackbar({ variant: 'success', message: 'success' });
                        getDevices();
                    });
                }
            })
            .catch(error => {
                console.log('error', error)
            })
    };
    const handleUpToDLT = (item) => {

        const dataUp = JSON.stringify({
            cocode: item.cocode,
            cobcode: item.cobcode,
            deviceid: item.deviceid,
        });
        Api.post('v0/gps/masterfile_dlt', dataUp).then(res => {
            handleSnackbar({ variant: 'success', message: 'success' });
            getDevices();
        });
    }
    // const handleDelDLT = (item) => {
    //     const data = JSON.stringify({
    //         cocode: item.cocode,
    //         cobcode: item.cobcode,
    //         deviceid: item.deviceid,
    //     });
    //     Api.post('v0/gps/masterfile_dlt', data)
    //         .then(res => {
    //             handleSnackbar({ variant: 'success', message: 'success' });
    //             getDevices();
    //         })
    //         .catch(error => {
    //             handleSnackbar({ variant: 'error', message: "error please try again" });
    //         });
    // };



    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleChangeGroup = (value) => {
        setGroup(value)
    }
    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event);
        setPage(0)
    };

    const filterList = group !== null ?
        listDevices.filter((val) => {
            return (val.vehicle_id?.toUpperCase().includes(search.toUpperCase()) || val.vehicle_chassis_no?.toUpperCase().includes(search.toUpperCase())) && val.group_id?.toUpperCase().includes(group.toUpperCase())
        })
        :
        listDevices.filter((val) => {
            return (val.vehicle_id?.toUpperCase().includes(search.toUpperCase()) || val.vehicle_chassis_no?.toUpperCase().includes(search.toUpperCase()))
        })

    // const filterList = group !== null ?
    //     listDevices.filter((val) => {
    //         return (val.devicename.toUpperCase().includes(search.toUpperCase()) || val.car_id.toUpperCase().includes(search.toUpperCase())) && val.group_id?.toUpperCase().includes(group.toUpperCase())
    //     })
    //     :
    //     listDevices.filter((val) => {
    //         return val.devicename.toUpperCase().includes(search.toUpperCase()) || val.car_id.toUpperCase().includes(search.toUpperCase())
    //     })



    const options = [
        {
            icon: <EditIcon />,
            title: "Edit",
            // onClick: (row) => {
            //     navigate(`/settings/devices/device/${row.device}`)
            // },
            to: (row) => {
                return `/settings/devices/device/${row.device}`
            },
            target: "",
            component: Link
        },
        {
            icon: <LocalPrintshopIcon />,
            title: "DLT Cer",
            to: (row) => {
                return `/settings/devices/print/${row.vehicle_chassis_no}`
            },
            target: "_blank",
            component: Link
        },
        // {
        //     icon: <LocalPrintshopIcon />,
        //     title: "driver",
        //     to: (row) => {
        //         return `/settings/devices/print/${row.vehicle_chassis_no}`
        //     },
        //     target: "_blank",
        //     component: Link
        // }
        // {
        //     icon: <HomeRepairServiceOutlinedIcon />,
        //     title: "Service",
        //     to: (row) => {
        //         return `/settings/service/${row.vehicle_chassis_no}`
        //     },
        //     target: "_blank",
        //     component: Link
        // }
    ];


    const columns = [
        { field: 'devicename', headerName: 'ชื่ออุปกรณ์', flex: 1, },
        { field: 'car_id', headerName: 'ชื่อรถ', flex: 1, },
        { field: 'vehicle_id', headerName: 'ป้ายทะเบียน', flex: 1, },
        { field: 'vehicle_chassis_no', headerName: 'เลขตัวถัง', flex: 1, },
        { field: 'imei', headerName: 'imei', flex: 1, },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            getActions: ({ row }) => [
                // <GridActionsCellItem
                //     icon={<LocalPrintshopIcon />}
                //     component={Link}
                //     // onClick={() => { }}
                //     target="_blank"
                //     to={
                //         `/settings/devices/print/${e.row.vehicle_chassis_no}`
                //     }
                //     label="DLT Cer"
                //     // showInMenu
                // />,
                // <Button
                //     size="small"
                //     component={Link}
                //     target="_blank"
                //     to={
                //         `/settings/devices/print/${row.vehicle_chassis_no}`
                //     }
                //     variant="outlined"
                //     startIcon={<LocalPrintshopIcon />}>
                //     DLT Cer
                // </Button>,
                // <Button
                //     size="small"
                //     component={Link}
                //     target="_blank"
                //     to={
                //         `/settings/devices/print/${row.vehicle_chassis_no}`
                //     }
                //     variant="outlined"
                //     startIcon={<LocalPrintshopIcon />}>
                //     Service
                // </Button>,
                // <>
                //     <IconButton
                //         aria-label="more"
                //         id="long-button"
                //         aria-controls={openMore ? 'long-menu' : undefined}
                //         aria-expanded={openMore ? 'true' : undefined}
                //         aria-haspopup="true"
                //         onClick={handleClickMore}
                //     >
                //         <MoreVertIcon />
                //     </IconButton>
                //     <StyledMenu
                //         id="long-menu"
                //         MenuListProps={{
                //             'aria-labelledby': 'long-button',
                //         }}
                //         anchorEl={anchorElMore}
                //         open={openMore}
                //         onClose={handleCloseMore}
                //         PaperProps={{
                //             style: {
                //                 maxHeight: 48 * 4.5,
                //                 width: '20ch',
                //             },
                //         }}
                //     >
                //         {options.map((option) => (
                //             <MenuItem
                //                 key={option}
                //                 onClick={() => {
                //                     // option.onClick(row)
                //                     handleCloseMore()
                //                 }}
                //                 component={option.component}
                //                 to={option.to(row)}
                //                 target={option.target}
                //             >
                //                 {option.icon} {option.title}
                //             </MenuItem>
                //         ))}
                //     </StyledMenu>
                // </>,
                <MenuMoreTable row={row} options={options} />,
                // <GridActionsCellItem
                //     icon={<EditIcon />}
                //     onClick={() => {
                //         navigate(`/settings/devices/device/${row.device}`)
                //     }}
                //     label="Edit"
                // // showInMenu
                // />,
            ],
            // alim
        }
    ];

    const handleGetData = (event) => {
        getDevices()
        setPage(0)
    }
    return (
        <Page title="device">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Device</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <Box sx={{
                        margin: "10px"
                    }}>
                        <Box width={400} sx={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                            <TextField
                                fullWidth
                                size="small"
                                label={`ค้นหา ป้ายทะเบียน/เลขตัวถัง ${group == null ? "" : "ใน " + groups.find(value => value.group_id === group).group_name}`}
                                value={search}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end' >
                                            <IconButton
                                                // ref={refDropDownTune}
                                                edge="end"
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                            >
                                                <TuneRoundedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <IconButton
                                // ref={refDropDownTune}
                                edge="end"
                                onClick={handleGetData}
                            >
                                <RefreshRoundedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <div style={{ height: "calc(100vh - 120px)", width: '100%' }}>
                        <DataGrid
                            rows={filterList}
                            columns={columns}
                            pageSize={rowsPerPage}
                            onPageSizeChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 20, 30, 40]}
                            pagination
                            getRowId={(row) => row.device}
                            page={page}
                            onPageChange={(newPage) => setPage(newPage)}
                        // checkboxSelection
                        />
                    </div>
                </MainStyle>
                <StyledMenu
                    id="group-menu"
                    MenuListProps={{
                        'aria-labelledby': 'group-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <List>
                        <ListItem disablePadding key={"groupsall"}>
                            <ListItemButton onClick={() => {
                                setPage(0)
                                setGroup(null)
                                handleClose()
                            }}>
                                <ListItemText primary={"ทั้งหมด"} />
                            </ListItemButton>
                        </ListItem>
                        {
                            groups.map((value, index) => {

                                return (
                                    <ListItem disablePadding key={"groups" + index}>
                                        <ListItemButton onClick={() => {
                                            setGroup(value.group_id)
                                            handleClose()
                                            setPage(0)
                                        }}>
                                            <ListItemText primary={value.group_name} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </StyledMenu>
                {/* <FabStyle color="primary" aria-label='Add' onClick={() => {
                    navigate('/settings/devices/device')
                }}>
                    <AddIcon />
                </FabStyle> */}
            </Container >
        </Page >
    )

}


const MenuMoreTable = ({ row, options }) => {

    const [anchorElMore, setAnchorElMore] = React.useState(null);
    const openMore = Boolean(anchorElMore);
    const handleClickMore = (event) => {
        setAnchorElMore(event.currentTarget);
    };
    const handleCloseMore = () => {
        setAnchorElMore(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMore ? 'long-menu' : undefined}
                aria-expanded={openMore ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickMore}
            >
                <MoreVertIcon />
            </IconButton>
            <StyledMenu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorElMore}
                open={openMore}
                onClose={handleCloseMore}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => {
                            // option.onClick(row)
                            handleCloseMore()
                        }}
                        component={option.component}
                        to={option.to(row)}
                        target={option.target}
                    >
                        {option.icon} {option.title}
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    )
}



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '8px',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));