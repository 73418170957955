import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Stack, Link, Container, Typography, Grid, Paper } from '@mui/material';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import { Box } from '@mui/system';
import ImgLogo from '../img/logo.png';
import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import { deviceGroupState, filterGroupState, firstLoadState, listDevicesCustomState, listDevicesState, listGroupsState, loadingState, queryState, searchState, selectDeviceState, stationDashboardState } from './dashboard_group/DashboardStore';
import { devicesPageState, devicesPerPageState, devicesSearchState } from './devices/DeviceStore';
import { groupSelectStorage, groupsStorage, selectDeviceStorage, subscribeStorage } from './NewHome/DashStorage';
import { permissionAtom } from '../components/_state/users';
// import { useRecoilValue } from 'recoil';
// import { userAtom } from '../components/_state';
// import { useEffect } from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  height: '100vh',
  display: 'flex'
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex'
  // }
}));
const LeftStyle = styled('div')(({ theme }) => ({
  height: '100%',
  width: '28%',
  [theme.breakpoints.down('md')]: {
    width: '52px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '0px',
  },
  display: 'flex',
  backgroundColor: '#6290c2',
  alignItems: 'center',
  paddingBottom: '40px',
  justifyContent: 'center',
}));

const ContentStyle = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  boxShadow: '-2px 0px 16px rgb(0 0 0 / 25%)',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}));
const FormStyle = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '40px',
  maxWidth: '416px',
}))
const ImgLogoStyle = styled('img')(({ theme }) => ({
  maxWidth: 150,
  display: 'flex',
}));
const ImgBoxStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '20px',
  justifyContent: 'center',
}));
// ----------------------------------------------------------------------

export default function Login() {

  const filterGroup = useResetRecoilState(filterGroupState)
  const listDevices = useResetRecoilState(listDevicesState)
  const deviceGroup = useResetRecoilState(listGroupsState)
  const firstLoad = useResetRecoilState(firstLoadState)
  const loading = useResetRecoilState(loadingState)
  const selectDevice = useResetRecoilState(selectDeviceState);
  const query = useResetRecoilState(queryState);
  const search = useResetRecoilState(searchState);
  const listDevicesCustom = useResetRecoilState(listDevicesCustomState);
  const stationDashboard = useResetRecoilState(stationDashboardState);
  const groupSelectStorageFn = useResetRecoilState(groupSelectStorage);
  const subscribeStorageFn = useResetRecoilState(subscribeStorage);
  const selectDeviceStorageFn = useResetRecoilState(selectDeviceStorage);
  const groupsStorageFn = useResetRecoilState(groupsStorage);
  const permissionFn = useResetRecoilState(permissionAtom);


  // const devicesSearch = useResetRecoilState(devicesSearchState);
  // const devicesPage = useResetRecoilState(devicesPageState);
  // const devicesPerPage = useResetRecoilState(devicesPerPageState);

  useEffect(() => {
    filterGroup()
    listDevices()
    deviceGroup()
    firstLoad()
    loading()
    selectDevice()
    query()
    search()
    listDevicesCustom()
    stationDashboard()
    // devicesSearch()
    // devicesPage()
    // devicesPerPage()
    groupSelectStorageFn()
    subscribeStorageFn()
    selectDeviceStorageFn()
    groupsStorageFn()
    permissionFn()
    localStorage.clear()

  }, [filterGroup, listDevices, deviceGroup, firstLoad, loading])
  return (
    <RootStyle title="Login">
      {/* <Container maxWidth="sm"> */}
      <LeftStyle>
        <MHidden width="mdDown">
          <ImgLogoStyle src={ImgLogo} alt="login" width={150} />
        </MHidden>
      </LeftStyle>
      <ContentStyle>
        <FormStyle>
          <MHidden width="mdUp">
            <ImgBoxStyle>
              <ImgLogoStyle src={ImgLogo} alt="login" width={80} />
            </ImgBoxStyle>
          </MHidden>
          <LoginForm />
        </FormStyle>
      </ContentStyle>
      {/* </Container> */}
    </RootStyle>
  );
}
