import React, { useEffect, useState } from 'react'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import DialogDriver from './DialogDriver';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import DialogBushostess from './DialogBushostess';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ButtonDialogWork from './ButtonDialogWork';
import ButtonDialogWorkUpdate from './ButtonDialogWorkUpdate';
import DialogTrip from './DialogTrip';
import DialogTripUpdate from './DialogTripUpdate';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function UpdateBooking() {
  const [expanded, setExpanded] = React.useState(false);



  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const drivers = [
    {
      "employee_id": "EDV001",
      "name": "ประสิทธิ์ จงจิตกลาง",
      "phone": "+1987654321"
    },
    {
      "employee_id": "EDV002",
      "name": "ปริญญา ศรีท้วม",
      "phone": "+1122334455"
    }
  ]

  const bushostess = [
    {
      "employee_id": "EBH001",
      "name": "ประจินดา จงจิตกลาง",
      "phone": "+1987654321"
    },
    {
      "employee_id": "EBH002",
      "name": "ปริญสา ศรีท้วม",
      "phone": "+1122334455"
    }
  ]
  const { state } = useLocation();
  const navigation = useNavigate()

  // useEffect(() => {
  //   if (!state) {
  //     navigation("/settings/booking/test")
  //     return
  //   }

  // }, [state])



  const [booking, setBooking] = useState(state)


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const handleClickVariant = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };



  const handleSnackWithAction = ({ message, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  // handleDel(item)
                  // console.log('data', data)
                  closeSnackbar(key)
                  onHandle()
                  // onHandle(data)
                  // handleVariant("ลบข้อมูลเสร็จสิ้น !", "success")
                  // handleVariant("ลบข้อมูลไม่สำเร็จ !", "error")
                  // handleClickVariant("ลบข้อมูลไม่สำเร็จ !", "error")
                  // enqueueSnackbar('ลบข้อมูลเสร็จสิ้น!', { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
                  // enqueueSnackbar('ลบข้อมูลไม่สำเร็จ!', { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };


  const onHandleCreateWork = (trip_id, cars_control_id, work) => {

    const gt = booking.trips.find(t => t.trip_id === trip_id)
    const nt = booking.trips.filter(t => t.trip_id !== trip_id)

    const ct = gt.cars_control.find(ct => ct.cars_control_id === cars_control_id)
    const nct = gt.cars_control.filter(ct => ct.cars_control_id !== cars_control_id)

    const { works } = ct

    const n_t = { ...ct, works: [...works, work] }
    const n_b = [...nt, { ...gt, cars_control: [...nct, n_t] }]


    setBooking(E => {
      return ({ ...E, trips: n_b, })
    })
    handleClickVariant("เพิ่มข้อมูลเสร็จสิ้น !", "success")


  }
  const onHandleChangeWork = (trip_id, cars_control_id, work) => {

    const gt = booking.trips.find(t => t.trip_id === trip_id)
    const nt = booking.trips.filter(t => t.trip_id !== trip_id)

    const ct = gt.cars_control.find(ct => ct.cars_control_id === cars_control_id)
    const nct = gt.cars_control.filter(ct => ct.cars_control_id !== cars_control_id)

    const { works } = ct
    const n_w = works.filter(ct => ct.car_id !== work.car_id)

    const n_t = { ...ct, works: [...n_w, work] }
    const n_b = [...nt, { ...gt, cars_control: [...nct, n_t] }]


    setBooking(E => {
      return ({ ...E, trips: n_b, })
    })
    handleClickVariant("เพิ่มข้อมูลเสร็จสิ้น !", "success")


  }

  const onHandleDelCar = (trip_id, car_id, cars_control_id) => {
    const gt = booking.trips.find(t => t.trip_id === trip_id)
    const nt = booking.trips.filter(t => t.trip_id !== trip_id)

    const ct = gt.cars_control.find(ct => ct.cars_control_id === cars_control_id)
    const nct = gt.cars_control.filter(ct => ct.cars_control_id !== cars_control_id)

    const { works } = ct

    const nw = works.filter(t => t.car_id !== car_id)

    const n_t = { ...ct, works: [...nw] }
    const n_b = [...nt, { ...gt, cars_control: [...nct, n_t] }]


    setBooking(E => {
      return ({ ...E, trips: n_b, })
    })
    handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")

  }

  const calculateSum = (data) => {
    let sum = 0;
    data.forEach(item => {
      sum += _.isString(item.car_capacity) ? Number(item.car_capacity) : item.car_capacity;
    });
    return sum;
  };

  const onHandleSubmit = (trip) => {
    setBooking(b => {
      return ({ ...b, trips: [...b.trips, trip] })
    })
  }

  const onHandleSubmitEdit = (trip) => {

    const nt = booking.trips.filter(t => t.trip_id !== trip.trip_id)

    setBooking(b => {
      return ({ ...b, trips: [...nt, trip] })
    })
  }

  const handleCustomer = (e) => {

    const { name, value } = e.target
    setBooking(e => {
      return ({
        ...e, customer: { ...e.customer, [name]: value }
      })
    })
  }

  const onChangeBookingDate = (name, value) => {
    setBooking(b => {
      return ({
        ...b,
        [name]: value
      })
    })
  }

  const haveCars = (value) => {
    var check = false
    value?.cars_control.map((cars) => {
      cars?.works.map(c => {
        check = true
        return
      })
    })
    return check
  }

  const onDelTrip = (trip_id) => {
    const filter = booking.trips.filter(t => t.trip_id !== trip_id)
    setBooking(e => {
      return {
        ...e,
        trips: filter
      }
    })
    handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
  }


  return (
    <>
      {
        !state && (
          <Navigate to="/settings/booking/test" />
        )
      }
      {
        state && (
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ padding: "10px" }}>
                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={1} alternativeLabel>
                    <Step>
                      <StepLabel
                      // StepIconComponent={(props) => {
                      //   return (<StepIcon {...props}>
                      //     <VideoLabelIcon />
                      //   </StepIcon>)
                      // }}
                      >รับเรื่อง</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>จัดรถ</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>ยืนยัน</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>วิ่งงาน</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>ชำระเงิน</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
                <Stack spacing={1}>
                  <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
                  <Box>
                    <Stack >
                      <Grid container spacing={1} >
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={1} >
                            <Grid item xs={5} md={5}>
                              <TextField size="small" label='กลุ่มประเภท' fullWidth
                                value={booking.booking_type}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" >
                                      <IconButton size="small" ><SearchIcon fontSize="inherit" /></IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                name='grouptype' />
                            </Grid>
                            <Grid item xs={7} md={7}>
                              <TextField size="small" label='ประเภทงาน' name='grouptypename' fullWidth
                                value={booking.booking_work_type}
                              />
                            </Grid>

                            <Grid item xs={6} md={6}>
                              <DesktopDatePicker
                                disablePast
                                label="วันที่เริ่มจอง"
                                inputFormat="dd/MM/yyyy"
                                value={booking.booking_start}
                                name='booking_start'
                                onChange={(value) => { onChangeBookingDate("booking_start", value) }}
                                renderInput={(params) => <TextField size="small" label='วันที่เริ่มจอง' name='booking_start' fullWidth {...params} />}
                              />
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <DesktopDatePicker
                                disablePast
                                label="วันที่สิ้นสุดจอง"
                                inputFormat="dd/MM/yyyy"
                                value={booking.booking_end}
                                name='booking_end'
                                onChange={(value) => { onChangeBookingDate("booking_end", value) }}
                                renderInput={(params) => <TextField size="small" label='วันที่สิ้นสุดจอง' name='booking_end' fullWidth {...params} />}
                              />
                            </Grid>


                            <Grid item xs={4} md={4}>
                              <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                                value={booking.customer.prefix}

                              // InputLabelProps={{ shrink: formCus.prefix ? true : false }} 
                              />
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <TextField size="small" label='ลูกค้า' fullWidth
                                // InputLabelProps={{ shrink: formCus.name ? true : false }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" >
                                      <IconButton size="small" onClick={() => {
                                        // handleOpenForm("dialogCustomer")
                                      }}><SearchIcon fontSize="inherit" /></IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                value={booking.customer.name}
                                onChange={handleCustomer}
                                name='name'
                              />
                            </Grid>
                          </Grid>
                          <Collapse in={true} timeout="auto" unmountOnExit style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <Grid container spacing={1} >
                              <Grid item xs={8} md={8}>
                                <TextField size="small" label='เลขที่ผู้เสียภาษี' name='tax_id' fullWidth
                                  value={booking.customer.tax_id}
                                  onChange={handleCustomer}
                                // InputLabelProps={{ shrink: formCus.taxId ? true : false }}
                                />
                              </Grid>
                              {/* <Grid item xs={5} md={5}>
                          <TextField size="small" label='คำนำหน้า' name='prefix' fullWidth
                            value={booking.customer.prefix}

                          // InputLabelProps={{ shrink: formCus.prefix ? true : false }} 
                          />
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <TextField size="small" label='ชื่อผู้ประกอบการ' name='operatorname' fullWidth
                            value={booking.customer.name}
                          // InputLabelProps={{ shrink: formCus.operatorname ? true : false }} 
                          />
                        </Grid> */}
                              <Grid item xs={4} md={4}>
                                <TextField size="small" label='สาขา' name='branch' fullWidth
                                  value={booking.customer.branch}
                                  onChange={handleCustomer}

                                // InputLabelProps={{ shrink: formCus.branch ? true : false }}
                                />
                              </Grid>
                              <Grid item xs={9} md={9}>
                                <TextField size="small" label='ที่อยู่' name='address' fullWidth
                                  value={booking.customer.address}
                                  onChange={handleCustomer}

                                // InputLabelProps={{ shrink: formCus.address ? true : false }}
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <TextField size="small" label='ประเภทที่อยู่' name='address_type' fullWidth
                                  value={booking.customer.address_type}
                                  onChange={handleCustomer}
                                // InputLabelProps={{ shrink: formCus.addresstype ? true : false }} 
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='แขวง/ตำบล' name='subdistrict' fullWidth
                                  value={booking.customer.subdistrict}
                                  onChange={handleCustomer}
                                // InputLabelProps={{ shrink: formCus.subdistrict ? true : false }} 
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='เขต/อำเภอ' name='district' fullWidth
                                  value={booking.customer.district}
                                  onChange={handleCustomer}
                                // InputLabelProps={{ shrink: formCus.district ? true : false }} 
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='จังหวัด' name='province' fullWidth
                                  value={booking.customer.province}
                                  onChange={handleCustomer}

                                // InputLabelProps={{ shrink: formCus.province ? true : false }}
                                />
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <TextField size="small" label='รหัสไปรษณีย์' name='postcode' fullWidth
                                  value={booking.customer.postcode}
                                  onChange={handleCustomer}

                                // InputLabelProps={{ shrink: formCus.zipCode ? true : false }}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField size="small" label='เบอร์ติดต่อ' name='phone' fullWidth
                                  value={booking.customer.phone}
                                  onChange={handleCustomer}

                                // InputLabelProps={{ shrink: formCus.tel ? true : false }}
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={1} >
                            <Grid item xs={12} md={12}>
                              <TextField size="small" label='เลขที่อ้างอิง' name='ref' fullWidth
                                value={booking.booking_ref}
                              />
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <TextField size="small" label='ผู้ติดต่อ' name='Contact' fullWidth
                                value={booking.contact}
                              />
                            </Grid>
                            <Grid item xs={7} md={7}>
                              <TextField size="small" label='เบอร์โทร' name='tel' fullWidth
                                value={booking.contact_phone}

                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Autocomplete
                                multiple
                                limitTags={2}
                                id="multiple-limit-tags"
                                options={[{ title: "wdwd" }]}
                                getOptionLabel={(option) => option?.title}
                                defaultValue={[]}
                                renderInput={(params) => (
                                  <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
                                )}
                              // sx={{ width: '500px' }}
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <TextField size="small" label='หมายเหตุ' multiline maxRows={3} minRows={3} name='remake' fullWidth />
                              {/* <Stack spacing={1} direction="row">
                          <Checkbox name='autoTrip' />
                          <Typography sx={{ paddingTop: "8px" }} >Auto trip</Typography>
                        </Stack> */}
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Button variant="outlined">บันทึก</Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box sx={{ marginBottom: "10px" }}>
                        <Divider />
                      </Box>
                      <Box sx={{ padding: "10px" }}>
                        <DialogTrip dropoff_date={booking.booking_end} pickup_date={booking.booking_start} onHandleSubmit={onHandleSubmit} />
                      </Box>
                      <Box sx={{ minHeight: "250px", height: "400px", borderStyle: "solid", borderColor: "#e5e7eb", borderRadius: "10px", overflow: "auto" }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow >
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>

                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                เลขที่ใบเดินทาง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                วันที่เริ่มจอง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                วันที่สิ้นสุดจอง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                ต้นทาง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                ปลายทาง
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                ประเภทรถ
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                พนักงานขับรถ
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                พนักงานต้อนรับ
                              </TableCell>
                              <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              _.orderBy(booking.trips, ["trip_id", ["asc"]]).map((value, index) => {
                                // _.orderBy(booking.trips, ["tripNo"], ["asc"]).map((value, index) => {
                                return (
                                  <React.Fragment key={`TableRow-${index + 1}`}>
                                    <TableRow  >
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                        {index + 1}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.trip_id}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {/* {fDateTo(value.dateStart, "dd-MM-yyyy")} */}
                                        {value.pickup_date}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {/* {fDateTo(value.dateEnd, "dd-MM-yyyy")} */}
                                        {value.dropoff_date}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.pickup_location}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.dropoff_location}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        {value.trips_cars.map(v => v.car_type + ":" + v.car_capacity)}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "center" }}>
                                        {value.employees.driver_capacity}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "center" }}>
                                        {value.employees.bushostess_capacity}
                                        {/* {value.employees.bushostess_capacity + "," + value.employees.bushostess_capacity} */}
                                      </TableCell>
                                      <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                        <Stack spacing={1} direction="row" justifyContent="end">
                                          {/* <IconButton onClick={() => { setOpenTripD(true) }} disabled={value.cars.length >= calculateSum(value.trips_cars)} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton> */}
                                          <DialogTripUpdate data={value} onHandleSubmit={onHandleSubmitEdit} />
                                          <IconButton
                                            disabled={haveCars(value)}
                                            onClick={() => {
                                              handleSnackWithAction({
                                                message: "ต้องการลบข้อมูลทริป?", onHandle: () => {
                                                  onDelTrip(value.trip_id)
                                                }
                                              })
                                            }}
                                            size="small">
                                            <DeleteOutlineIcon fontSize="inherit" />
                                          </IconButton>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                    {
                                      _.orderBy(value.cars_control, ["cars_control_id", ["asc"]]).map((ct, ict) => {
                                        return (
                                          <>
                                            <TableRow >
                                              <TableCell colSpan={1} sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                <ReportProblemRoundedIcon color="warning" />
                                              </TableCell>
                                              <TableCell colSpan={8} sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                {`${ct.car_type} : ${ct.car_name}`}
                                              </TableCell>
                                              <TableCell colSpan={2} sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "right" }}>
                                                {/* <IconButton onClick={() => { setOpenTripD(true) }} disabled={value.cars.length >= calculateSum(value.trips_cars)} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton> */}
                                                <ButtonDialogWork
                                                  disabled={false}
                                                  onHandle={(work) => { onHandleCreateWork(value.trip_id, ct.cars_control_id, work) }}
                                                />
                                              </TableCell>
                                            </TableRow>
                                            {
                                              calculateSum(value.trips_cars) > 0 && (
                                                <TableRow >
                                                  <TableCell colSpan={11} sx={{ padding: "0px" }}>
                                                    <Box>
                                                      <Table>
                                                        <TableBody>
                                                          {
                                                            _.orderBy(ct.works, ["car_id", ["asc"]]).map((work, Index) => {
                                                              return (
                                                                <TableRow >
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>

                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='วันที่เริ่ม' fullWidth
                                                                      value={work.pickupDate}
                                                                      name="pickupDate"
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='วันที่สิ้นสุด' fullWidth
                                                                      name="returnDate"
                                                                      value={work.returnDate}
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='ทะเบียนรถ' fullWidth
                                                                      value={work.licensePlate}
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <TextField size="small" label='พนักงาน' fullWidth
                                                                      value={work?.drivers?.map((v, i) => v.name).toString()}
                                                                      disabled
                                                                      inputProps={{
                                                                        style: {
                                                                          height: "15px",
                                                                          fontSize: "12px"
                                                                        }
                                                                      }}
                                                                      InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                    />
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                    <Stack spacing={1} direction="row" justifyContent="end">
                                                                      <ButtonDialogWorkUpdate disabled={false} data={work} onHandle={(work) => { onHandleChangeWork(value.trip_id, ct.cars_control_id, work) }} />
                                                                      <IconButton onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลรถ?", onHandle: () => { onHandleDelCar(value.trip_id, work.car_id, ct.cars_control_id) } }) }} size="small"><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                                                    </Stack>
                                                                  </TableCell>
                                                                </TableRow>
                                                              )
                                                            })
                                                          }
                                                        </TableBody>
                                                      </Table>
                                                    </Box>
                                                  </TableCell>
                                                </TableRow >
                                              )
                                            }</>
                                        )
                                      })
                                    }
                                  </React.Fragment>
                                )
                              })
                            }
                          </TableBody >
                        </Table >
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </LocalizationProvider>
            {/* <Box sx={{ padding: "20px" }}>
              <Button variant="contained" onClick={() => {
                handleSnackWithAction({
                  message: "ต้องการบันทึกการจอง?", onHandle: () => {
                    handleClickVariant("บันทึกข้อมูลเสร็จสิ้น !", "success")
                  }
                })
              }} >บันทึกข้อมูล</Button>
            </Box> */}



            {/* /////////////////////////////////////////////////////////////////////////////////////////// */}

            {/* /////////////////////////////////////////////////////////////////////////////////////////// */}


          </div >
        )
      }
    </>
  )
}
