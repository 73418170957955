import styled from '@emotion/styled';
import { Autocomplete, Box, Collapse, Container, Divider, Grid, List, ListItemButton, Button, ListItemText, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function NewDevice({ account, listModel = [], dltVender, listVehicleType = [], listProvince = [], listVehicleRegisterType = [] }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const [open, setOpen] = useState({ required: true, attributes: true, group: true })

    const DeviceSchema = Yup.object().shape({
        gpsmodel: Yup.object().shape({
            gpsmodel: Yup.string().required('Model Gps is required'),
        }).nullable().required('Model Gps is required'),
        devicename: Yup.string().required('Device Name is required'),
        imei: Yup.string().required('Imei is required'),
        deviceid: Yup.string().required('Deviceid is required'),
        telgps: Yup.string().required('Telgps is required'),

    })
    const formik = useFormik({
        initialValues: {
            gpsmodel: null,
            devicename: '',
            imei: '',
            deviceid: '',
            telgps: '',
            vehicle_type: null,
            vehicle_id: '',
            vehicle_chassis_no: '',
            province_code: null,
            vehicle_register_type: null,
            vender_id: null
        },
        validationSchema: DeviceSchema,
        onSubmit: (values) => {
            const data = JSON.stringify({
                ...values,
                vender_id: dltVender.vender_id,
                cocode: dltVender.cocode,
                cobcode: dltVender.cobcode,
                gpsmodel: values.gpsmodel.gpsmodel,
                province_code: values.province_code !== null ? values.province_code.province_code : '',
                vehicle_register_type: values.vehicle_register_type !== null ? values.vehicle_register_type.vehicle_register_type : '',
                vehicle_type: values.vehicle_type !== null ? values.vehicle_type.vehicle_type : '',
                unit_id: dltVender.vender_id + values.gpsmodel.gpsmodel + values.imei.padStart(20, "0")
            });
            // console.log('data', data)
            Api.post('/v0/gps/device', data)
                .then(res => {
                    handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                    navigate('/settings/devices')
                }
                ).catch(error => {
                    const { message } = error
                    handleSnackbar({ variant: 'error', message: message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const handleClick = (name) => {
        setOpen({ ...open, [name]: !open[name] });
    };
    const handleOnClose = () => {
        navigate('/settings/devices')
    }
    const dltVenderField = dltVender.vender_id !== undefined ? dltVender.vender_id + " : " + dltVender.vender_name_th : ""

    return (
        <Page title="device" >
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Device</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                                <Box width={400} margin="auto" padding={2}
                                // border={1} borderRadius={1}
                                >
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={() => {
                                            handleClick("required")
                                        }} >
                                            <ListItemText primary="Required" />
                                            {
                                                open.required ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.required} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="ผู้ประกอบการ GPS"
                                                            // {...getFieldProps('name')}
                                                            value={dltVenderField}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ชื่ออุปกรณ์"
                                                            {...getFieldProps('devicename')}
                                                            error={Boolean(touched.devicename && errors.devicename)}
                                                            helperText={touched.devicename && errors.devicename}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Autocomplete
                                                            id="gpsmodel"
                                                            size="small"
                                                            name="gpsmodel"
                                                            options={listModel}
                                                            getOptionLabel={(option) => option.name}
                                                            fullWidth
                                                            // value={values.gpsmodel}
                                                            // {...getFieldProps('gpsmodel')}
                                                            isOptionEqualToValue={(option, value) => option.gpsmodel === value.gpsmodel}
                                                            onChange={(e, value) => {
                                                                // const gpsmodel = value !== null ? value.gpsmodel : null;
                                                                setFieldValue("gpsmodel", value)
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    label="เลือกรุ่น GPS"
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    name="gpsmodel"
                                                                    {...params}
                                                                    {...getFieldProps('gpsmodel')}
                                                                    error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                                                    helperText={touched.gpsmodel && errors.gpsmodel}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="เลข IMEI"
                                                            {...getFieldProps('imei')}
                                                            inputProps={{ maxLength: 20 }}
                                                            error={Boolean(touched.imei && errors.imei)}
                                                            helperText={touched.imei && errors.imei}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="รหัสอุปกรณ์"
                                                            {...getFieldProps('deviceid')}
                                                            error={Boolean(touched.deviceid && errors.deviceid)}
                                                            helperText={touched.deviceid && errors.deviceid}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="เบอร์โทร GPS"
                                                            {...getFieldProps('telgps')}
                                                            error={Boolean(touched.telgps && errors.telgps)}
                                                            helperText={touched.telgps && errors.telgps}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        <ListItemButton onClick={() => {
                                            handleClick("attributes")
                                        }} >
                                            <ListItemText primary="Attributes" />
                                            {
                                                open.attributes ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.attributes} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            autoComplete="off"
                                                            type="text"
                                                            label="เลขตัวถัง"
                                                            {...getFieldProps('vehicle_chassis_no')}
                                                            error={Boolean(touched.vehicle_chassis_no && errors.vehicle_chassis_no)}
                                                            helperText={touched.vehicle_chassis_no && errors.vehicle_chassis_no}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Autocomplete
                                                            size="small"
                                                            id="vehicle_type"
                                                            name="vehicle_type"
                                                            options={listVehicleType}
                                                            getOptionLabel={(option) => option.vehicle_type}
                                                            isOptionEqualToValue={(option, value) => option.vehicle_type === value.vehicle_type}
                                                            fullWidth
                                                            onChange={(e, value) => {
                                                                setFieldValue("vehicle_type", value);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    name="vehicle_type"
                                                                    {...params}
                                                                    type="text"
                                                                    label="ยี่ห้อรถ"
                                                                    {...getFieldProps('vehicle_type')}
                                                                    error={Boolean(touched.vehicle_type && errors.vehicle_type)}
                                                                    helperText={touched.vehicle_type && errors.vehicle_type}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            autoComplete="off"
                                                            type="text"
                                                            label="ป้ายทะเบียน"
                                                            {...getFieldProps('vehicle_id')}
                                                            error={Boolean(touched.vehicle_id && errors.vehicle_id)}
                                                            helperText={touched.vehicle_id && errors.vehicle_id}
                                                        // onChange={(e) => console.log(e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>

                                                        <Autocomplete
                                                            size="small"
                                                            id="province_code"
                                                            name="province_code"
                                                            options={listProvince}
                                                            getOptionLabel={(option) => option.province_name}
                                                            isOptionEqualToValue={(option, value) => option.province_code === value.province_code}
                                                            fullWidth
                                                            onChange={(e, value) => {
                                                                setFieldValue("province_code", value);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    name="province_code"
                                                                    {...params}
                                                                    type="text"
                                                                    label="จังหวัด"
                                                                    {...getFieldProps('province_code')}
                                                                    error={Boolean(touched.province_code && errors.province_code)}
                                                                    helperText={touched.province_code && errors.province_code}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Autocomplete
                                                            size="small"
                                                            id="vehicle_register_type"
                                                            name="vehicle_register_type"
                                                            options={listVehicleRegisterType}
                                                            getOptionLabel={(option) => option.vehicle_register_name}
                                                            isOptionEqualToValue={(option, value) => option.vehicle_register_type === value.vehicle_register_type}
                                                            fullWidth
                                                            onChange={(e, value) => {
                                                                setFieldValue("vehicle_register_type", value);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    name="vehicle_register_type"
                                                                    {...params}
                                                                    type="text"
                                                                    label="ประเภทรถ"
                                                                    {...getFieldProps('vehicle_register_type')}
                                                                    error={Boolean(touched.vehicle_register_type && errors.vehicle_register_type)}
                                                                    helperText={touched.vehicle_register_type && errors.vehicle_register_type}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MobileDatePicker
                                                            disableFuture={true}
                                                            // allowSameDateSelection
                                                            label="วันติดตั้ง"
                                                            allowKeyboardControl={false}
                                                            value={values.dateinstall}
                                                            inputFormat="dd/MM/yyyy"
                                                            format="dd/MM/yyyy"
                                                            onChange={(val) => {
                                                                setFieldValue('dateinstall', val);
                                                            }}
                                                            renderInput={(params) => <TextField
                                                                size="small"
                                                                {...params}
                                                                {...getFieldProps('dateinstall')}
                                                                fullWidth
                                                                placeholder="DD/MM/YYYY"
                                                                autoComplete="off"
                                                                error={Boolean(touched.dateinstall && errors.dateinstall)}
                                                                helperText={touched.dateinstall && errors.dateinstall} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MobileDatePicker
                                                            // disableFuture={false}  
                                                            // disablePast={true}
                                                            // allowSameDateSelection
                                                            minDate={values.dateinstall}
                                                            label="วันหมดอายุ"
                                                            allowKeyboardControl={false}
                                                            value={values.dateexp}
                                                            inputFormat="dd/MM/yyyy"
                                                            format="dd/MM/yyyy"
                                                            onChange={(val) => {
                                                                setFieldValue('dateexp', val);  
                                                            }}
                                                            renderInput={(params) => <TextField
                                                                size="small"
                                                                {...params}
                                                                {...getFieldProps('dateexp')}
                                                                fullWidth
                                                                placeholder="DD/MM/YYYY"
                                                                autoComplete="off"
                                                                error={Boolean(touched.dateexp && errors.dateexp)}
                                                                helperText={touched.dateexp && errors.dateexp} />}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        <ListItemButton onClick={() => {
                                            handleClick("group")
                                        }} >
                                            <ListItemText primary="Group" />
                                            {
                                                open.group ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.group} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="group"
                                                            {...getFieldProps('group')}
                                                            error={Boolean(touched.group && errors.group)}
                                                            helperText={touched.group && errors.group}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                            <Grid item xs={6} justifyContent="center">
                                                <Button
                                                    fullWidth
                                                    onClick={handleOnClose}
                                                    size="medium"
                                                    variant="outlined"
                                                    color="info"
                                                // fullWidth
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} justifyContent="center">
                                                <LoadingButton
                                                    fullWidth
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    color="info"
                                                    loading={isSubmitting}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </MainStyle>
            </Container>
        </Page>
    )
}
