import { Box, Container, Divider, Fab, Stack, Tooltip, LinearProgress, Grid, Stepper, Step, StepLabel, StepConnector, stepConnectorClasses, Typography, ListItem, List, ListItemButton, ListItemText, IconButton, Button, Dialog, DialogTitle, TextField } from '@mui/material';
import { styled } from '@mui/system';
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Page from '../../components/Page'
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../components/api';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';

import Check from '@mui/icons-material/Check';
import StepperRoute from './StepperRoute';
import MapRoute from './MapRoute';
import AirportShuttleRoundedIcon from '@mui/icons-material/AirportShuttleRounded';
import { Icon } from '@iconify/react';

const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    zIndex : 9999,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const FabBack = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 80,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 60,
    overflow: 'auto'
}));
export default function MainRouteTrips() {
    const navigate = useNavigate()

    const params = useParams()
    const location = useLocation()




    // [
    //     {
    //         route_no: 'R001',
    //         sub_route_no: 'R00001',
    //         route_name: 'สาย - 8',
    //         direction: "ขาออก",
    //         style: { width: 150 },
    //         stations: [
    //             {


    //                 name_bus_stop: "แฮปปี้แลนด์",
    //                 latitude: 13.778132,
    //                 longitude: 100.644608,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 100,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ซอย นวมินทร์10การเคหะแห่งชาติ",
    //                 latitude: 13.775695,
    //                 longitude: 100.645279,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "เคหะคลองจั่น",
    //                 latitude: 13.774333,
    //                 longitude: 100.645933,
    //                 position_cars: [
    //                     {
    //                         position: 80,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 70,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตรงข้ามโรงเรียนบ้านบางกะปิ",
    //                 latitude: 13.771679,
    //                 longitude: 100.647383,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "เคหะคลองจั่น (แฟลต23)",
    //                 latitude: 13.770226,
    //                 longitude: 100.647485,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "เดอะมอลล์บางกะปิ ",
    //                 latitude: 13.767189,
    //                 longitude: 100.642827,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "แม็คโครบางกะปิ",
    //                 latitude: 13.768639,
    //                 longitude: 100.640178,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 142,140",
    //                 latitude: 13.769573,
    //                 longitude: 100.638681,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "โรงพยาบาลเวชธานี (ลาดพร้าว136)",
    //                 latitude: 13.770572,
    //                 longitude: 100.636878,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "วัดกลาง",
    //                 latitude: 13.77178,
    //                 longitude: 100.634666,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 130 (ซอย มหาดไทย2)",
    //                 latitude: 13.773151,
    //                 longitude: 100.632303,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 101 ",
    //                 latitude: 13.774588,
    //                 longitude: 100.629716,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 126 ",
    //                 latitude: 13.775803,
    //                 longitude: 100.62752,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 122 (ซอย มหาดไทย 1)",
    //                 latitude: 13.777237,
    //                 longitude: 100.624954,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตรงข้ามโรงพยาบาลลาดพร้าว ",
    //                 latitude: 13.778625,
    //                 longitude: 100.622467,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 87,112,โรงเรียนบางกอกศึกษา",
    //                 latitude: 13.780484,
    //                 longitude: 100.619146,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "สำนักงานเขตวังทองหลาง ",
    //                 latitude: 13.782669,
    //                 longitude: 100.615259,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "บิ๊กซีลาดพร้าว ",
    //                 latitude: 13.784395,
    //                 longitude: 100.61215,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 71",
    //                 latitude: 13.785307,
    //                 longitude: 100.610521,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 67/1 (ลาดพร้าว80) ",
    //                 latitude: 13.787415,
    //                 longitude: 100.606742,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 78",
    //                 latitude: 13.788683,
    //                 longitude: 100.604461,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 57 (กรมแผนที่ทหาร)",
    //                 latitude: 13.789883,
    //                 longitude: 100.602317,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตลาดโชคชัย 4 ",
    //                 latitude: 13.790915,
    //                 longitude: 100.600483,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตลาดสะพาน 2 ",
    //                 latitude: 13.792825,
    //                 longitude: 100.59707,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตลาดโชคชัย 4",
    //                 latitude: 13.793955,
    //                 longitude: 100.595045,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตลาดสะพาน 2",
    //                 latitude: 13.796377,
    //                 longitude: 100.590688,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "โรงเรียนพิบูลย์อุปถัมภ์",
    //                 latitude: 13.797881,
    //                 longitude: 100.588017,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "แยกภาวนา (ลาดพร้าว41)",
    //                 latitude: 13.800297,
    //                 longitude: 100.583752,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 42",
    //                 latitude: 13.801944,
    //                 longitude: 100.580756,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 34 (หมู่บ้านกลางเมือง)",
    //                 latitude: 13.803917,
    //                 longitude: 100.577229,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "แยกรัชดา-ลาดพร้าว (ใต้ดินทางออก1)",
    //                 latitude: 13.806129,
    //                 longitude: 100.573254,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ลาดพร้าว 22 (ใต้ดินทางออก2,3)",
    //                 latitude: 13.807435,
    //                 longitude: 100.570916,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 30,
    //                         name: "car2"
    //                     }
    //                 ]

    //             },
    //             {


    //                 name_bus_stop: "บิ๊กซีเอ็กซ์ตร้าลาดพร้าว (โรงเรียนปานะพันธ์)",
    //                 latitude: 13.809256,
    //                 longitude: 100.567675,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "บ้านพร้อมพันธ์",
    //                 latitude: 13.810076,
    //                 longitude: 100.566195,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ยูเนี่ยนมอลล์",
    //                 latitude: 13.811044,
    //                 longitude: 100.564461,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตรงข้ามยูเนี่ยนมอลล์",
    //                 latitude: 13.811928,
    //                 longitude: 100.562863,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ห้าแยกลาดพร้าว ปากทางลาดพร้าว",
    //                 latitude: 13.812964,
    //                 longitude: 100.561054,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ธนาคารทหารไทย",
    //                 latitude: 13.808641,
    //                 longitude: 100.55753,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "BTS หมอชิต",
    //                 latitude: 13.802318,
    //                 longitude: 100.553783,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ขนส่งหมอชิต สถาบันการบินพลเรือน",
    //                 latitude: 13.800203,
    //                 longitude: 100.552559,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "แยกกำแพงเพชร",
    //                 latitude: 13.797099,
    //                 longitude: 100.551049,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "วัดไผ่ตัน",
    //                 latitude: 13.794587,
    //                 longitude: 100.550102,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "บิ้กซีสะพานควาย ทางออก2",
    //                 latitude: 13.793023,
    //                 longitude: 100.549542,
    //             },
    //             {


    //                 name_bus_stop: "สถานีตำรวจนครบาลบางซื่อ",
    //                 latitude: 13.78629,
    //                 longitude: 100.547134,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 10,
    //                         name: "car1"
    //                     },
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ซอยสายลม กองบัญชาการตำรวจตระเวนชายแดน",
    //                 latitude: 13.783952,
    //                 longitude: 100.546282,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ซอยอารีย์",
    //                 latitude: 13.779789,
    //                 longitude: 100.544813,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ซอยราชครู",
    //                 latitude: 13.77664,
    //                 longitude: 100.543626,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ซอยพหลโยธิน 2",
    //                 latitude: 13.774597,
    //                 longitude: 100.542861,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "สนามเป้า",
    //                 latitude: 13.772921,
    //                 longitude: 100.542315,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ททบ.5,โรงพยาบาลพญาไท 2",
    //                 latitude: 13.769785,
    //                 longitude: 100.541158,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ซอยลือชา",
    //                 latitude: 13.767323,
    //                 longitude: 100.539782,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "อนุสาวรีย์ชัยสมรภูมิ",
    //                 latitude: 13.764596,
    //                 longitude: 100.537482,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "โรงพยาบาลเด็ก",
    //                 latitude: 13.766234,
    //                 longitude: 100.535112,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "โรงพยาบาลพระมงกุฎเกล้า",
    //                 latitude: 13.76772,
    //                 longitude: 100.531802,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "โรงพยาบาลรามาธิบดี",
    //                 latitude: 13.766799,
    //                 longitude: 100.527941,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "กระทรวงการต่างประเทศ",
    //                 latitude: 13.762277,
    //                 longitude: 100.524349,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "แยกเสาวนีย์",
    //                 latitude: 13.761813,
    //                 longitude: 100.522516,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "เลียบทางรถไฟยมราช",
    //                 latitude: 13.758467,
    //                 longitude: 100.521166,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "สะพานขาว,บ้านมนังคศิลา,โรงพยาบาลมิชชั่น,มหานาค",
    //                 latitude: 13.756857,
    //                 longitude: 100.519043,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "สำนักงานสภาพัฒนาการเศรษฐกิจและสังคมแห่งชาติ (สภาพัฒน์)",
    //                 latitude: 13.756502,
    //                 longitude: 100.514238,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "หลานหลวง 8",
    //                 latitude: 13.756405,
    //                 longitude: 100.511475,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตรงข้ามวัดสระเกศราชวรมหาวิหาร (ภูเขาทอง)",
    //                 latitude: 13.753177,
    //                 longitude: 100.509319,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "แม้นศรี",
    //                 latitude: 13.751107,
    //                 longitude: 100.50847,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตรงข้ามชุมชนบ้านดอกไม้",
    //                 latitude: 13.748893,
    //                 longitude: 100.507427,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "วัดตึก",
    //                 latitude: 13.744707,
    //                 longitude: 100.505014,
    //                 position_cars: [

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตลาดเยาวราช",
    //                 latitude: 13.742986,
    //                 longitude: 100.503652,
    //                 position_cars: [

    //                 ]
    //             },
    //             {

    //                 station_no: "",

    //                 name_bus_stop: "สะพานพุทธ",
    //                 latitude: 13.740096,
    //                 longitude: 100.49806,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                 ]
    //             },
    //         ],
    //     },
    //     {
    //         route_no: 'R001',
    //         sub_route_no: 'R00001',
    //         route_name: 'สาย - 8',
    //         direction: 'ขาเข้า',
    //         style: { width: 1050 },
    //         stations: [
    //             {
    //                 name_bus_stop: "ตลาดเยาวราช",
    //                 latitude: 13.742986,
    //                 longitude: 100.503652,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 15,
    //                         name: "car1"
    //                     },

    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "สะพานพุทธ",
    //                 latitude: 13.740096,
    //                 longitude: 100.49806,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         route_no: 'R001',
    //         sub_route_no: 'R00001',
    //         route_name: 'สาย - 8',
    //         direction: 'ขาเข้า ทางพิเศษ',
    //         style: { width: 525 },

    //         stations: [
    //             {


    //                 name_bus_stop: "วัดตึก",
    //                 latitude: 13.744707,
    //                 longitude: 100.505014,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "ตลาดเยาวราช",
    //                 latitude: 13.742986,
    //                 longitude: 100.503652,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                 ]
    //             },
    //             {


    //                 name_bus_stop: "สะพานพุทธ",
    //                 latitude: 13.740096,
    //                 longitude: 100.49806,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car1"
    //                     },
    //                 ]
    //             },
    //         ]
    //     },
    //     {
    //         route_no: 'R001',
    //         route_name: 'สาย - 8',
    //         sub_route_no: 'R00001',
    //         direction: 'ขาออก ทางพิเศษ',
    //         style: { width: 1050 },
    //         stations: [
    //             {
    //                 bus_stop_id: 'S001',
    //                 name_bus_stop: "แฮปปี้แลนด์",
    //                 latitude: 13.778132,
    //                 longitude: 100.644608,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 5,
    //                         name: "car1"
    //                     },
    //                     {
    //                         position: 10,
    //                         name: "car2"
    //                     }
    //                 ]
    //             },
    //             {
    //                 bus_stop_id: 'S050',
    //                 name_bus_stop: "วัดตึก",
    //                 latitude: 13.744707,
    //                 longitude: 100.505014,
    //                 isMain: true,
    //                 position_cars: [
    //                     {
    //                         position: 0,
    //                         name: "car3"
    //                     },
    //                     {
    //                         position: 0,
    //                         name: "car4"
    //                     },
    //                     {
    //                         position: 0,
    //                         name: "car5"
    //                     },

    //                 ]
    //             }
    //         ]
    //     }
    // ]

















    const [listStation, setListStation] = useState([])


    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const getStation = useCallback(
        ({ route_no }) => {
            Api.get('/v0/Busticket/RouteStation', {
                params: {
                    route_no: route_no
                },
            })
                .then((res) => {
                    const list = res.data
                    setListStation(list)
                })
                .catch((error) => {
                    console.log('error', error)
                    setListStation([])
                })
        },
        [],
    )

    useEffect(() => {
        // getStation({ route_no: params.route_no })
    }, [getStation])

    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleClickWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            // handleDel(item)
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };
    const handleDel = (item) => {
        // var data = {
        //     ...item
        // }
        // Api.delete('/v0/Busticket/RouteStation', { data })
        //     .then(res => {
        //         handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
        //         getStation({ route_no: params.route_no });
        //     })
        //     .catch(error => {
        //         const { message } = error
        //         handleSnackbar({ variant: 'error', message: message });
        //     })
    };

    return (
        <Page title="routes">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Route {params.route_no}</Typography>
                </Box>
                <Divider />
                <MainStyle>

                    <List style={{ paddind: 10 }} sx={{
                        overflow: "hidden"
                    }}>
                        {listStation.map((element, i) => (
                            <Fragment
                                key={'stations' + i}
                            >
                                <ListItem
                                    disablePadding
                                    secondaryAction={
                                        <Box paddingX={1}>
                                            {/* <IconButton edge="start" aria-label="delete" style={{ margin: 1 }}
                                                onClick={() => {
                                                    closeSnackbar()
                                                    handleClickWithAction({
                                                        message: "Copy item?",
                                                        handle: () => {
                                                            handleOpen()
                                                            // handleDel(e)
                                                        }
                                                    })
                                                }}
                                            >
                                                <FileCopyRoundedIcon color="info" />
                                            </IconButton> */}
                                            <IconButton edge="start" aria-label="Edit" style={{ margin: 1 }}
                                                onClick={() => {
                                                    navigate(`/settings/routes/${element.route_no}/${element.sub_route_no}`, {
                                                        state: element
                                                    })
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="start" aria-label="delete" style={{ margin: 1 }}
                                                onClick={() => {
                                                    closeSnackbar()
                                                    handleClickWithAction({
                                                        message: "Remove item?",
                                                        handle: () => {
                                                            handleDel(element)
                                                        }
                                                    })
                                                }}
                                            >
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Box>
                                    }>
                                    <ListItemButton
                                        onClick={() => {

                                        }}
                                    >
                                        <ListItemText
                                            primary={`${element.route_name} ( ${element.direction} )`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        height: "160px",
                                        paddingTop: "40px",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        overflowX: "auto",
                                        overflowY: "hidden",
                                    }}
                                // className="hide-scrollbar"
                                >
                                    {
                                        element.stations
                                            .filter(val => val.isMain === true)
                                            .map((value, index) => (
                                                <Fragment key={`point${index}`} >
                                                    {
                                                        element.stations
                                                            .filter(val => val.isMain === true)
                                                            .length !== index + 1 && (
                                                            <>
                                                                <Tooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    componentsProps={{
                                                                        tooltip: {
                                                                            sx: {
                                                                                color: "black",
                                                                                bgcolor: "#F2F2F2",
                                                                                '& .MuiTooltip-arrow': {
                                                                                    color: "#F2F2F2",
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                    onClose={() => { }}
                                                                    open={true}
                                                                    arrow
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={<Typography maxWidth={"180px"} noWrap overflow="hidden" textOverflow="ellipsis" fontSize="0.6875rem" fontWeight="600" >{value.name_bus_stop}</Typography>}
                                                                    placement={index % 2 === 0 ? "top" : "bottom"}
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            minWidth: "20px",
                                                                            height: "20px",
                                                                            borderRadius: "50%",
                                                                            backgroundColor: "#8758FF",
                                                                            marginTop: "3px",
                                                                            borderColor: "red",
                                                                            borderWidth: "1px",
                                                                            borderStyle: "solid",
                                                                            paddingLeft: "5px",
                                                                            paddingBottom: "4px",
                                                                            fontSize: "12px",
                                                                            fontWeight: "bold",
                                                                            color: "white"
                                                                        }} >
                                                                        {value.position_cars.length}
                                                                    </Box>
                                                                </Tooltip>
                                                                <Box style={{
                                                                    minWidth: `${element.style?.width - 20}px`,
                                                                    height: "20px",
                                                                    marginTop: "3px",
                                                                    backgroundColor: "#8758FF",
                                                                    borderRadius: "3px",
                                                                    position: "relative"
                                                                }} >
                                                                    {
                                                                        value.position_cars?.map((car, i) => (
                                                                            <Box
                                                                                key={`car${car.name}${i}`}
                                                                                style={{
                                                                                    width: "30px",
                                                                                    height: "30px",
                                                                                    position: "absolute",
                                                                                    top: "60px",
                                                                                    left: `${(((car.position) / 100) * element.style?.width) - 20}px`,
                                                                                }}>
                                                                                {/* <AirportShuttleRoundedIcon /> */}
                                                                                <Icon icon="mingcute:bus-2-line" fontSize="30px" />
                                                                            </Box>

                                                                        ))
                                                                    }
                                                                </Box>
                                                            </>
                                                        )
                                                    }

                                                    {
                                                        element.stations
                                                            .filter(val => val.isMain === true)
                                                            .length === index + 1 && (
                                                            <>
                                                                <Tooltip
                                                                    PopperProps={{
                                                                        disablePortal: true,
                                                                    }}
                                                                    componentsProps={{
                                                                        tooltip: {
                                                                            sx: {
                                                                                color: "black",
                                                                                bgcolor: "#F2F2F2",
                                                                                '& .MuiTooltip-arrow': {
                                                                                    color: "#F2F2F2",
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                    onClose={() => { }}
                                                                    open={true}
                                                                    arrow
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title={<Typography maxWidth={"180px"} noWrap overflow="hidden" textOverflow="ellipsis" fontSize="0.6875rem" fontWeight="600" >{value.name_bus_stop}</Typography>}
                                                                    placement={index % 2 === 0 ? "top" : "bottom"}
                                                                >
                                                                    <Box
                                                                        style={{
                                                                            minWidth: "20px",
                                                                            height: "20px",
                                                                            borderRadius: "50%",
                                                                            backgroundColor: "#8758FF",
                                                                            marginTop: "3px",
                                                                            borderColor: "red",
                                                                            borderWidth: "1px",
                                                                            borderStyle: "solid",
                                                                            paddingLeft: "5px",
                                                                            paddingBottom: "4px",
                                                                            fontSize: "12px",
                                                                            fontWeight: "bold",
                                                                            color: "white"
                                                                        }} >
                                                                        {value.position_cars.length}
                                                                    </Box>
                                                                </Tooltip>
                                                                <Box style={{
                                                                    width: element.style?.width,
                                                                    height: "20px",
                                                                    marginTop: "3px",
                                                                    borderRadius: "3px",
                                                                    position: "relative"
                                                                }} >
                                                                    {
                                                                        value.position_cars?.map((car, i) => (
                                                                            <Box
                                                                                key={`car${car.name}${i}`}
                                                                                style={{
                                                                                    width: "30px",
                                                                                    height: "30px",
                                                                                    position: "absolute",
                                                                                    top: "60px",
                                                                                    left: `${(((car.position) / 100) * element.style?.width) - 20}px`,
                                                                                }}>
                                                                                {/* <AirportShuttleRoundedIcon /> */}
                                                                                <Icon icon="mingcute:bus-2-line" fontSize="30px" />
                                                                            </Box>

                                                                        ))
                                                                    }
                                                                </Box>
                                                            </>
                                                        )
                                                    }
                                                </Fragment>
                                            ))
                                    }

                                </Box>

                                {i + 1 !== listStation.length ? <Divider /> : null}
                            </Fragment>
                        ))}
                    </List>
                    <FabStyle color="primary" aria-label='Add' onClick={() => {
                        navigate(`/settings/routes/${params.route_no}/new`, {
                            state: location.state
                        })
                    }}>
                        <AddIcon />
                    </FabStyle>
                </MainStyle>
            </Container >
        </Page >
    )
}

