import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import { Container, Box, Card, Grid, Typography, Fab, LinearProgress, Backdrop, CardContent, Tooltip, CircularProgress, ButtonGroup, Button, Menu, Slide, MenuItem, useMediaQuery, IconButton, Paper } from '@mui/material';
import { MapContainer, Marker, TileLayer, ZoomControl } from 'react-leaflet'
import { makeStyles, styled, withStyles } from '@mui/styles';
import ListTileLayer from '../../layouts/ListTileLayer';
import BottomNavigationBar from '../../layouts/BottomNavigationBar'
import { Api } from '../../components/api';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import YoutubeSearchedForRoundedIcon from '@mui/icons-material/YoutubeSearchedForRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import { fNumber, fShortenNumber } from '../../utils/formatNumber';


const NevWidth = 400;
const SlideWidth = 220;
const BottomNavHeight = 56;

const useStyles = makeStyles(theme => ({
    SlideStyles: {
        minWidth: `${SlideWidth}px`,
        marginRight: "10px"
    },
}));

export default function DashboardGroup({
    loading, deviceGroup, listDevices
}) {

    // const deviceByGroup = groupSelectd === '' ?
    //     listDevices
    //     :
    //     listDevices.filter(value => {
    //         const isFind = value.group.filter(a => a.group_id === groupSelectd.group_id)
    //         if (isFind.length > 0) {
    //             return value
    //         }
    //         return
    //     })
    return (
        <Page title="Dashboard" >

            <Container maxWidth={false} style={{ padding: 0, overflow: 'hidden' }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    {
                        !loading && (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        height: `calc(100vh)`,
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        flexDirection: "column",
                                        width: 1
                                    }}>
                                    {
                                        deviceGroup.length === 0 && (
                                            <MainCard deviceByGroup={listDevices} groupSelectd={{ name: "All" }} />
                                        )
                                    }
                                    {
                                        deviceGroup.map((group, index) => {
                                            const listDeviceGroup = listDevices.filter(value => {
                                                const isFind = value.group.filter(a => a.group_id === group.group_id)
                                                if (isFind.length > 0) {
                                                    return value
                                                }
                                                return
                                            })
                                            return (
                                                <MainCard deviceByGroup={listDeviceGroup} groupSelectd={group} key={index} />
                                            )
                                        })}

                                </Box>

                            </>
                        )
                    }
                </Box>
            </Container>
        </Page>
    )
}


const MainCard = ({ deviceByGroup, groupSelectd }) => {
    const [zoom, setZoom] = useState(75);
    // const [zoom, setZoom] = useState(75);
    const [viewTypes, setViewType] = useState("EV")
    const [selectd, setSelectd] = useState('')
    const classes = useStyles()

    const offLine = deviceByGroup.filter(value => {
        const dateNow = new Date()
        const minOffLine = dateNow.setMinutes(dateNow.getMinutes() - 5)
        return value.datetime === null || new Date(value.datetime) < minOffLine
    })


    const deviceFilter = selectd === "ONLINE" ?
        deviceByGroup.filter(value => {
            const dateNow = new Date()
            const minOffLine = dateNow.setMinutes(dateNow.getMinutes() - 5)
            return new Date(value.datetime) > minOffLine
        }) :
        selectd === "OFFLINE" ? deviceByGroup.filter(value => {
            const dateNow = new Date()
            const minOffLine = dateNow.setMinutes(dateNow.getMinutes() - 5)
            return value.datetime === null || new Date(value.datetime) < minOffLine
        })
            :
            deviceByGroup


    const onScroll = (e) => {

    };

    return (
        <Box sx={{
            margin: "2px",
        }}>
            <Card variant="outlined"
                sx={{
                    minWidth: "100%",
                    borderRadius: 0,
                    // marginBottom: "1px",
                }}
            >
                <CardContent>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: 1
                    }}>
                        <Box minWidth={SlideWidth}>
                            <Typography fontSize={16} marginTop="10px" textAlign="center" color="#000000" marginBottom="10px">{groupSelectd.name}</Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            overflowY: "auto",
                            justifyContent: "end",
                            width: "100%"
                        }}
                        // onWheel={onScroll}
                        >
                            <Box>
                                <IconButton size="small"
                                    onClick={() => {
                                        setViewType(viewTypes => viewTypes === "EV" ? "SPEED" : "EV")
                                    }}
                                    key="view">
                                    {
                                        viewTypes === "EV" ?
                                            <SpeedRoundedIcon />
                                            :
                                            <BatteryStdIcon />
                                    }
                                </IconButton>
                                <IconButton size="small"
                                    onClick={() => {
                                        setZoom(50)
                                    }}
                                    key="ZoomMain">
                                    <YoutubeSearchedForRoundedIcon />
                                </IconButton>
                                <IconButton size="small"
                                    onClick={() => {
                                        setZoom(zoom => zoom * 2)
                                    }}
                                    key="ZoomIn">
                                    <ZoomInRoundedIcon />
                                </IconButton>
                                <IconButton size="small"
                                    onClick={() => {
                                        setZoom(zoom => zoom / 2)
                                    }}
                                    disabled={zoom <= 25}
                                    key="ZoomOut">
                                    <ZoomOutRoundedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around"

                        }}
                            className={classes.SlideStyles}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{ m: 1, position: "relative" }}>
                                    <Fab
                                        aria-label="filter"
                                        color="primary"
                                        sx={(theme) => ({
                                            zIndex: 9,
                                            backgroundColor: selectd === '' ? "#e2eef6" : "white",
                                            color: "black",
                                            borderColor: theme.palette.primary.light,
                                            "&:hover": {
                                                // backgroundColor: "white",
                                                opacity: 0.4,
                                                color: "black"
                                            }
                                        })}
                                        onClick={() => {
                                            setSelectd('')
                                        }}
                                    >
                                        {deviceByGroup.length}
                                    </Fab>
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={68}
                                        sx={{
                                            // color: "#eeeeee",
                                            color: "#064273",
                                            position: "absolute",
                                            top: -6,
                                            left: -6,
                                            zIndex: 1
                                        }}
                                    />
                                    <Typography fontSize={13} marginTop="10px" textAlign="center" color="#A9A9A9">DEVICE</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{ m: 1, position: "relative" }}>
                                    <Fab

                                        aria-label="filter"
                                        color="primary"
                                        sx={(theme) => ({
                                            zIndex: 9,
                                            backgroundColor: selectd === 'ONLINE' ? "#e2eef6" : "white",
                                            color: "black",
                                            borderColor: theme.palette.primary.light,
                                            "&:hover": {
                                                // backgroundColor: "white",
                                                opacity: 0.4,
                                                color: "black"
                                            }
                                        })}
                                        onClick={() => {
                                            setSelectd('ONLINE')
                                        }}
                                    >
                                        {deviceByGroup.length - offLine.length}
                                    </Fab>
                                    <CircularProgress
                                        variant="determinate"
                                        // value={((deviceByGroup.length - offLine.length) / deviceByGroup.length) * 100}
                                        value={100}
                                        size={68}
                                        sx={{
                                            // color: "#eeeeee",
                                            color: "#0f7ad1",
                                            position: "absolute",
                                            top: -6,
                                            left: -6,
                                            zIndex: 1,
                                        }}
                                    />
                                    <CircularProgress
                                        variant="determinate"
                                        // value={100}
                                        value={(offLine.length / deviceByGroup.length) * 100}
                                        size={68}
                                        sx={{
                                            // color: "#0f7ad1",
                                            color: "#eeeeee",
                                            position: "absolute",
                                            top: -6,
                                            left: -6,
                                            zIndex: 1
                                        }}
                                    />
                                    <Typography fontSize={13} marginTop="10px" textAlign="center" color="#A9A9A9">ONLINE</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box sx={{ m: 1, position: "relative" }}>
                                    <Fab
                                        aria-label="filter"
                                        color="primary"
                                        sx={(theme) => ({
                                            zIndex: 9,
                                            backgroundColor: selectd === 'OFFLINE' ? "#e2eef6" : "white",
                                            color: "black",
                                            borderColor: theme.palette.primary.light,
                                            "&:hover": {
                                                // backgroundColor: "white",
                                                opacity: 0.4,
                                                color: "black"
                                            }
                                        })}
                                        onClick={() => {
                                            setSelectd('OFFLINE')
                                        }}
                                    >
                                        {offLine.length}
                                    </Fab>
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={68}
                                        sx={{
                                            color: "#eeeeee",
                                            position: "absolute",
                                            top: -6,
                                            left: -6,
                                            zIndex: 1
                                        }}
                                    />
                                    <CircularProgress
                                        variant="determinate"
                                        value={(offLine.length / deviceByGroup.length) * 100}
                                        size={68}
                                        sx={{
                                            color: "#4f4f4f",
                                            position: "absolute",
                                            top: -6,
                                            left: -6,
                                            zIndex: 1,
                                        }}
                                    />
                                    <Typography fontSize={13} marginTop="10px" textAlign="center" color="#A9A9A9">OFFLINE</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            overflowY: "auto",
                            width: "100%"
                        }}
                        // onWheel={onScroll}
                        >
                            {
                                deviceFilter.map((value, index) => {
                                    const evLevel = value.widget.find(value => value.id === "SOC")?.value.split(" ")[0];
                                    return (
                                        <Box style={{
                                            margin: "5px",
                                            minWidth: `${zoom}px`,
                                            maxWidth: `${zoom}px`,
                                        }}
                                            key={index}>

                                            {
                                                viewTypes === "EV" && (
                                                    <Box justifyContent="center" display="flex">
                                                        <BorderLinearProgress
                                                            name={value.name}
                                                            charging_status={value.charging_status}
                                                            variant="determinate"
                                                            value={fShortenNumber(evLevel)} unit="%"
                                                            icon={value.widget.find(value => value.id === "ACC")?.icon}
                                                        />
                                                    </Box>
                                                )
                                            }
                                            {
                                                viewTypes === "SPEED" && (
                                                    <Box justifyContent="center" display="flex">
                                                        <SpeedLinearProgress
                                                            name={value.name}
                                                            charging_status={value.charging_status}
                                                            variant="determinate"
                                                            value={value.speed} unit="KM/h"
                                                            icon={value.widget.find(value => value.id === "ACC")?.icon}
                                                        />
                                                    </Box>
                                                )
                                            }
                                            {
                                                zoom > 25 && (
                                                    <Typography sx={{ fontSize: 12, width: `${zoom}px` }} noWrap color="text.secondary" textAlign={"center"}>
                                                        {
                                                            value.name
                                                        }
                                                    </Typography>
                                                )
                                            }
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                </CardContent >
            </Card >
        </Box >
    )
}


const BorderLinearProgress = ({ name = '', value = 0, height = 80, charging_status, unit, icon }) => {
    return (
        <Box textAlign="center">
            <Tooltip title={name} arrow placement="top">
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "20px",
                    height: height,
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: theme =>
                        theme.palette.grey[300]
                }}>
                    <Box sx={
                        {
                            position: "absolute",
                            background: "#1a90ff",
                            height: `${height / 100 * value}px`,
                            width: "20px",
                            borderRadius: "5px",
                            bottom: 0
                        }
                    } />
                    <Typography sx={{ fontSize: 10, position: "absolute", bottom: "5px", color: "white", textShadow: "2px 2px 10px #000000", writingMode: "vertical-rl", transform: "rotate(180deg)" }} noWrap={true} margin="0px" paddingTop="0px" textOverflow="unset" overflow="unset" >
                        {`${value} ${unit}`}
                    </Typography>
                    {
                        charging_status === 2 && (
                            <BoltRoundedIcon style={{ position: "absolute", margin: "auto" }} />
                        )
                    }
                </Box>
            </Tooltip>
            <Box sx={{ margin: 'auto', }}>
                <img src={icon} alt='icon'
                    width={25}
                    height={25}
                />
            </Box>
        </Box>
    )
};


const SpeedLinearProgress = ({ name = '', value = 0, height = 80, charging_status, unit, icon }) => {
    return (
        <Box textAlign="center">
            <Tooltip title={name} arrow placement="top">
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "20px",
                    height: height,
                    borderRadius: "5px",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: theme =>
                        theme.palette.grey[300]
                }}>
                    <Box sx={
                        {
                            position: "absolute",
                            background: "#1a90ff",
                            height: `${(height / 100) * (value / 200 * 100)}px`,
                            width: "20px",
                            borderRadius: "5px",
                            bottom: 0
                        }
                    } />
                    <Typography sx={{ fontSize: 10, position: "absolute", bottom: "5px", color: "white", textShadow: "2px 2px 10px #000000", writingMode: "vertical-rl", transform: "rotate(180deg)" }} noWrap={true} margin="0px" paddingTop="0px" textOverflow="unset" overflow="unset" >
                        {`${value} ${unit}`}
                    </Typography>
                    {
                        charging_status === 2 && (
                            <BoltRoundedIcon style={{ position: "absolute", margin: "auto" }} />
                        )
                    }
                </Box>
            </Tooltip>
            <Box sx={{ margin: 'auto', }}>
                <img src={icon} alt='icon'
                    width={25}
                    height={25}
                />
            </Box>
        </Box>
    )
};