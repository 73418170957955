import styled from '@emotion/styled';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Divider, Grid, TextField, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Dialog, DialogContent, useMediaQuery } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Hvac } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DialogMap from './DialogMap';
import MarkerWithLabel from './MarkerWithLabel';

const listTableHead = [
    {
        title: ""
    },
    {
        title: 'วันที่',
    },
    {
        title: 'รหัสเหตุการณ์',
    },
    {
        title: 'ชื่อเหตุการณ์',
    },
    {
        title: 'ข้อมูลเหตุการณ์',
    },
    {
        title: 'ละติจูด',
    },
    {
        title: 'ลองจิจูด',
    },
    {
        title: 'มาตรวัดระยะทาง',
    },


]

export default function LogEvents({ account, userId }) {
    const navigate = useNavigate()
    const [devices, setDevices] = useState([])
    const [overspeeds, setOverspeeds] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const [open, setOpen] = useState(false);
    const [dialogMapData, setDialogMapData] = useState(null);
    const handleClickOpen = (data) => {
        setOpen(true);
        setDialogMapData(data)
        // setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
            var list = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
            setDevices(list)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }, [userId])

    const schema = Yup.object().shape({
        deviceid: Yup.object().shape({
            device: Yup.string().required('Device is required'),
        }).nullable().required('Device is required'),
        // speed: Yup.number('speed min 50').required().min(50, 'speed min 50')
    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            deviceid: null,
            // speed: 60,
            date_s: selectedTime.setHours(0, 0, 0),
            date_e: selectedTime.setHours(23, 59, 59),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { deviceid, date_s, date_e,
                // speed
            } = values
            const { cocode } = account;

            Api.get('/tracking/events', {
                params: {
                    // cocode: cocode,
                    device: deviceid.device,
                    dateStart: fDateTo(date_s, 'yyyy-MM-dd HH:mm:ss'),
                    dateEnd: fDateTo(date_e, 'yyyy-MM-dd HH:mm:ss')
                }
            }).then((res) => {
                const { data } = res
                setOverspeeds(data)
                setPage(0)
                setSubmitting()
            }).catch((e) => {
                setOverspeeds([])
                setPage(0)
                setSubmitting()
            })
        }
    })


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
    return (
        <Page title="log events">
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event : log events</Typography>
                </Box>
            </Container>
            <Divider />
            <MainStyle>
                <Container sx={{ padding: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Autocomplete
                                            id="deviceid"
                                            name="deviceid"
                                            options={
                                                devices
                                            }
                                            size="small"
                                            getOptionLabel={(option) => option.vehicle_id}
                                            isOptionEqualToValue={(option, value) => option.device === value.device}
                                            fullWidth
                                            onChange={(e, value) => {
                                                setFieldValue("deviceid", value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="เลือกอุปกรณ์ GPS"
                                                    fullWidth
                                                    autoComplete="off"
                                                    name="deviceid"
                                                    {...params}
                                                    {...getFieldProps('deviceid')}
                                                    error={Boolean(touched.deviceid && errors.deviceid)}
                                                // helperText={touched.deviceid && errors.deviceid}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={6} sm={3}>
                                        <TextField
                                            size="small"
                                            {...getFieldProps('speed')}
                                            fullWidth
                                            type="number"
                                            min={50}
                                            label="ความเร็ว"
                                            autoComplete="off"
                                            error={Boolean(touched.speed && errors.speed)}
                                            helperText={touched.speed && errors.speed}
                                        />
                                    </Grid> */}
                                    <Grid item xs={6} sm={4}>
                                        <MobileDateTimePicker
                                            ampm={false}
                                            disableFuture={true}
                                            label="วันเวลาเริ่ม"
                                            allowKeyboardControl={false}
                                            value={values.date_s}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(val) => {
                                                setFieldValue('date_s', val);
                                                setFieldValue('date_e', new Date(val).setHours(23, 59, 59));
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_r')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_s && errors.date_s)}
                                                helperText={touched.date_s && errors.date_s} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <MobileDateTimePicker
                                            minDate={new Date(values.date_s)}
                                            maxDate={new Date(values.date_s).setDate(new Date(values.date_s).getDate() + 7)}
                                            ampm={false}
                                            disableFuture={true}
                                            label="ถึงวันเวลา"
                                            allowKeyboardControl={false}
                                            value={values.date_e}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(val) => {
                                                setFieldValue('date_e', val);
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_e')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_e && errors.date_e)}
                                                helperText={touched.date_e && errors.date_e} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} justifyContent="flex-end" justifyItems="center">
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <LoadingButton
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}

                                            >
                                                ค้นหา
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Button
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="info"
                                                onClick={() => {
                                                    navigate('/report/event')
                                                }}

                                            >
                                                กลับ
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Container>
                {
                    overspeeds.length > 0 && (
                        <Container sx={{ padding: 3 }} maxWidth={false}>
                            <Box sx={{ padding: 1 }}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Button as={CSVLink} filename={`${fDateTo(values.date_s, 'dd_MM_yyyy_HH:mm')}-${fDateTo(values.date_e, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                                    overspeeds.map((value, index) => {
                                        const {
                                            date_gps, event_id, event_name, event_value, latitude, longitude, odometer
                                        } = value;
                                        return {
                                            "วันที่": `=""${fDateTo(new Date(date_gps), 'dd/MM/yyyy')}""`,
                                            "เวลา": `=""${fDateTo(new Date(date_gps), 'HH:mm:ss')}""`,
                                            "รหัสเหตุการณ์": `=""${event_id}""`,
                                            "ชื่อเหตุการณ์": `=""${event_name}""`,
                                            "ข้อมูลเหตุการณ์": `=""${event_value}""`,
                                            "ละติจูด": `=""${latitude}""`,
                                            "ลองจิจูด": `=""${longitude}""`,
                                            "มาตรวัดระยะทาง": `=""${odometer}""`,
                                        }
                                    }
                                    )
                                } variant="contained" style={{
                                    textDecorationLine: 'none',
                                }} >
                                    Download
                                </Button>
                            </Box>
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            {
                                                listTableHead.map((item, index) => {
                                                    return <TableCell style={{ textTransform: 'capitalize' }} key={index} >{item.title}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            overspeeds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => {
                                                const {
                                                    date_gps, event_id, event_name, event_value, latitude, longitude, odometer
                                                } = value;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                        onClick={() => {
                                                            handleClickOpen(value)
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <TableCell>
                                                            <IconButton size="small" onClick={() => {
                                                                handleClickOpen(value)
                                                            }}>
                                                                <LocationOnIcon fontSize='inherit' />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{fDateTo(date_gps, 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                        <TableCell>{event_id}</TableCell>
                                                        <TableCell>{event_name}</TableCell>
                                                        <TableCell>{event_value}</TableCell>
                                                        <TableCell>{latitude}</TableCell>
                                                        <TableCell>{longitude}</TableCell>
                                                        <TableCell>{odometer}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[
                                    { value: 10, label: '10' },
                                    { value: 20, label: '20' },
                                    { value: 30, label: '30' },
                                    { value: 50, label: '50' },
                                    // { value: 100, label: 100 },
                                    // { value: listLog.length, label: 'All' }
                                ]}
                                component="div"
                                count={overspeeds.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Container>
                    )}
            </MainStyle>
            <DialogMap handleClose={handleClose} open={open} dialogMapData={dialogMapData}>
                {
                    dialogMapData !== null && (
                        <MarkerWithLabel
                            position={[dialogMapData.latitude, dialogMapData.longitude]}
                            label={
                                <Box>
                                    <Box>
                                        วันที่: {`${fDateTo(new Date(dialogMapData.date_gps), 'dd/MM/yyyy HH:mm:ss')}`}
                                    </Box>
                                    <Box>
                                        รหัสเหตุการณ์:{`${dialogMapData.event_id}`}
                                    </Box>
                                    <Box>
                                        ชื่อเหตุการณ์: {`${dialogMapData.event_name}`}
                                    </Box>
                                    <Box>
                                        ข้อมูลเหตุการณ์: {`${dialogMapData.event_value}`}
                                    </Box>
                                    <Box>
                                        มาตรวัดระยะทาง: {`${dialogMapData.odometer}`}
                                    </Box>
                                </Box>
                            }
                        />
                    )
                }
            </DialogMap>
        </Page >
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));