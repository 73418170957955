import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { Api } from '../../../components/api';

const init = {
    "cogcode": "SIT",
    "cocode": "SIT",
    "id": "00000000000439",
    "contype": "23",
    "concode": "0",
    "prefixid": null,
    "firstname": "เอสไอที คอนซัลแตนท์",
    "lastname": "จำกัด",
    "sex": "ชาย",
    "PassportID": null,
    "ThaiID": null,
    "membercard": null,
    "phone": "0800000000",
    "status": "U",
    "birthday": null,
    "email": "sit@mail.com",
    "c_coname": null,
    "c_cobcode": null,
    "c_cobname": null,
    "taxid": null,
    "positionid": null
}

export default function ButtonCreateCustomer({ disabled, onHandle }) {
    const [customer, setCustomer] = useState({
        "id": "123456",
        "name": "John Doe",
        "email": "john.doe@example.com",
        "phone": "+1234567890",
        "address": {
            "street": "123 Main St",
            "city": "Anytown",
            "state": "CA",
            "zip": "12345",
            "country": "USA"
        }
    })

    const [open, setOpen] = useState(false)
    const [concode, setConcode] = useState([])

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        setOpen(false)
        // onHandle({ ...customer })
        setCustomer()
    }



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setCustomer(e => {
            return ({ ...e, [name]: value })
        })
    }

    useEffect(() => {
        Api.get("/v0/if/Concode", { headers: { CompanyGroup: "SIT" } })
            .then((res) => {
                setConcode(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }, [])


    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>ข้อมูลลูกค้า</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {/* <Box>
                        {JSON.stringify(concode)}
                    </Box> */}
                    <Box>
                        <Stack >
                            <Grid container spacing={1}>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField size="small" fullWidth />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}