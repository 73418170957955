import { useState } from "react";
import { Api } from "../../components/api"
import useSWR, { useSWRConfig as UseSWRConfig, } from 'swr'
import axios from "axios";
import _ from 'lodash';

export const useSubscribe = ({ urls, args, userId, setBackdrop, options, setInt, select }) => {

    const ourRequest = axios.CancelToken.source()
    const fetcher = (url, req) => Api.post(url, req, { cancelToken: ourRequest.token }).then(res => {
        setBackdrop(false)
        setInt(5000)
        return res.data
    }).catch(error => {
        // setBackdrop(false)
        return error.data
    })


    const { data, error, isLoading, mutate } = useSWR(urls, () => fetcher(urls, { userId: userId, list: args }), options)
    return {
        subscribes: _.sortBy(data, ['name']) || [],
        isLoading,
        isError: error,
        isCancel: () => {
            ourRequest.cancel()
            setInt(10)
            setBackdrop(true)
            // controller.abort()
        },
        mutate: mutate,
        selectSub: _.find(data, (d) => d.device === select) || null
    }
}


// export const useStationDashboard = ({ urls, options, setInt }) => {

//     const ourRequest = axios.CancelToken.source()
//     const fetcher = (url) => Api.get(url, { cancelToken: ourRequest.token }).then(res => {
//         setInt(5000)
//         // setBackdrop(false)
//         return res.data
//     }).catch(error => {
//         // setBackdrop(true)
//         return error.data
//     })


//     const { data } = useSWR(urls, () => fetcher(urls), options)
//     return {
//         subStations: data || [],
//         isSubRouteCancel: () => {
//             ourRequest.cancel()
//         },
//     }
// }
// export const useDashboardByUser = ({ urls, setBackdrop, options }) => {

//     const ourRequest = axios.CancelToken.source()
//     const fetcher = (url) => Api.get(url, { cancelToken: ourRequest.token }).then(res => {
//         setBackdrop(false)
//         return res.data
//     }).catch(error => {
//         // setBackdrop(true)
//         return error.data
//     })


//     const { data } = useSWR(urls, () => fetcher(urls), options)
//     return {
//         dashBoards: data || [],
//         // isLoading,
//         // isError: error,
//         // isSubRouteCancel: () => {
//         //     setInt(10)
//         //     // controller.abort()
//         //     ourRequest.cancel()
//         // },
//         // subRoute: _.find(data, (d) => d.device === select) || null
//     }
// }

