import React from 'react'
import CustomerForm from './CustomerForm'

export default function AccountsByConType() {
    return (
        <div>
            <CustomerForm />
        </div>
    )
}
