import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Card, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

export default function CustomerForm() {


    // const customer = {
    //     "cogcode": "SIT",
    //     "cocode": "SIT",
    //     "id": "00000000000003",
    //     "contype": "21",
    //     "concode": null,
    //     "prefixid": null,
    //     "firstname": "",
    //     "lastname": "",
    //     "sex": null,
    //     "PassportID": null,
    //     "ThaiID": null,
    //     "membercard": null,
    //     "phone": "",
    //     "status": null,
    //     "birthday": null,
    //     "email": "supoj@hotmal.com",
    //     "c_coname": null,
    //     "c_cobcode": null,
    //     "c_cobname": null,
    //     "taxid": null,
    //     "positionid": null
    // }

    const [customer, setCustomer] = useState({
        cogcode: "SIT",
        cocode: "SIT",
        // id: "00000000000003",
        contype: "21",
        concode: null,
        prefixid: null,
        firstname: "",
        lastname: "",
        sex: null,
        PassportID: null,
        ThaiID: null,
        membercard: null,
        phone: "",
        status: null,
        birthday: null,
        email: "supoj@hotmal.com",
        c_coname: null,
        c_cobcode: null,
        c_cobname: null,
        taxid: null,
        positionid: null
    })

    const handleOnChange = (e) => {
        const { value, name } = e.target

        setCustomer(c => {
            return ({
                ...c,
                [name]: value
            })
        })

    }

    return (
        <div style={{ padding: "10px" }}>
            <Grid container spacing={1}>
                <Grid item md={6} >
                    <TextField size="small" fullWidth label="ประเภทผู้ใช้งาน" name='concode' value={customer.concode} onChange={handleOnChange} />
                </Grid>
                <Grid item md={6} >
                    <TextField size="small" fullWidth label="คำนำหน้า" name='prefixid' value={customer.prefixid} onChange={handleOnChange} />
                </Grid>
                <Grid item md={6} >
                    <TextField size="small" fullWidth label="ชื่อ" name='firstname' value={customer.firstname} onChange={handleOnChange} />
                </Grid>
                <Grid item md={6} >
                    <TextField size="small" fullWidth label="" name='prefixid' value={customer.lastname} onChange={handleOnChange} />
                </Grid>
            </Grid>
        </div>
    )
}
