import styled from '@emotion/styled';
import { LoadingButton, LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Divider, Grid, TextField, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import _ from 'lodash';
import SortIcon from '@mui/icons-material/Sort';

const listTableHead = [
    {
        title: 'ทะเบียนรถ',
        dataKey: "vehicle_id"
    },
    {
        title: 'มาตรวัดระยะทาง (m.)',
        dataKey: "odometer"
    },
    {
        title: 'เริ่มชาร์จเวลา',
        dataKey: "startTime"
    },
    {
        title: 'เริ่มชาร์จ (%)',
        dataKey: "startCharging"
    },
    {
        title: 'หยุดชาร์จเวลา',
        dataKey: "endTime"
    },
    {
        title: 'หยุดชาร์จ (%)',
        dataKey: "endCharging"
    },
    {
        title: 'มาตรวัดระยะทางครั้งก่อน (m.)',
        dataKey: "NextOrder"
    },
    {
        title: 'ค่าพลังงานครั้งก่อน (%)',
        dataKey: "NextCh"
    },
    {
        title: 'ใช้พลังงาน (%)',
        dataKey: "usesoc"
    },
    {
        title: 'รวมระยะทาง (Km.)',
        dataKey: "total"
    },

]

export default function EvSummary({ account, userId }) {
    const navigate = useNavigate()
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [oderByKey, setOderByKey] = useState('vehicle_id');
    const [oderBy, setOderBy] = useState("asc");


    const schema = Yup.object().shape({
    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            speed: 60,
            date_s: selectedTime,
            date_e: selectedTime,
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { date_s, date_e, speed } = values
            const { c_coname } = account;

            Api.get('/Tracking/GPSCharging', {
                params: {
                    // cocode: cocode,
                    cname: c_coname,
                    dateStart: fDateTo(date_s, 'yyyy-MM-dd'),
                    dateEnd: fDateTo(date_e, 'yyyy-MM-dd')
                }
            }).then((res) => {
                const { data } = res
                setRows(data)
                setPage(0)
                setSubmitting()
            }).catch((e) => {
                setRows([])
                setPage(0)
                setSubmitting()

            })
        }
    })


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
    // "desc","asc"
    const rowsFilter = _.orderBy(rows, [oderByKey], [oderBy])
    return (
        <Page title="Overspeed by date" >
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Ev summary</Typography>
                </Box>
            </Container>
            <Divider />
            <MainStyle>
                <Container sx={{ padding: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6}>
                                        <MobileDatePicker
                                            ampm={false}
                                            disableFuture={true}
                                            label="วันเวลาเริ่ม"
                                            allowKeyboardControl={false}
                                            value={values.date_s}
                                            inputFormat="dd/MM/yyyy"
                                            format="dd/MM/yyyy"
                                            onChange={(val) => {
                                                setFieldValue('date_s', val);
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_r')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_s && errors.date_s)}
                                                helperText={touched.date_s && errors.date_s} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <MobileDatePicker
                                            minDate={new Date(values.date_s)}
                                            ampm={false}
                                            disableFuture={true}
                                            label="ถึงวันเวลา"
                                            allowKeyboardControl={false}
                                            value={values.date_e}
                                            inputFormat="dd/MM/yyyy"
                                            format="dd/MM/yyyy"
                                            onChange={(val) => {
                                                setFieldValue('date_e', val);
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_e')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_e && errors.date_e)}
                                                helperText={touched.date_e && errors.date_e} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} justifyContent="flex-end" justifyItems="center">
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <LoadingButton
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}

                                            >
                                                ค้นหา
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Button
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="info"
                                                onClick={() => {
                                                    navigate('/report/event')
                                                }}

                                            >
                                                กลับ
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Container>
                {
                    rowsFilter.length > 0 && (
                        <Container sx={{ padding: 3 }} maxWidth={false}>
                            <Box sx={{ padding: 1 }}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Button as={CSVLink} filename={`${fDateTo(values.date_s, 'dd_MM_yyyy_HH:mm')}-${fDateTo(values.date_e, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                                    rowsFilter.map((value, index) => {
                                        const {
                                            vehicle_id,
                                            odometer,
                                            startCharging,
                                            startTime,
                                            endCharging,
                                            endTime,
                                            NextOrder,
                                            NextCh,
                                            usesoc,
                                            total
                                        } = value;
                                        return {
                                            "ทะเบียนรถ": `=""${vehicle_id}""`,
                                            "มาตรวัดระยะทาง (m.)": `=""${odometer}""`,
                                            "เริ่มชาร์จเวลา": `=""${fDateTo(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}""`,
                                            "เริ่มชาร์จ (%)": `=""${startCharging}""`,
                                            "หยุดชาร์จเวลา": `=""${fDateTo(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}""`,
                                            "หยุดชาร์จ (%)": `=""${endCharging}""`,
                                            "มาตรวัดระยะทางครั้งก่อน (m.)": `=""${NextOrder}""`,
                                            "ค่าพลังงานครั้งก่อน (%)": `=""${NextCh}""`,
                                            "ใช้พลังงาน (%)": `=""${usesoc}""`,
                                            "รวมระยะทาง (Km)": `=""${total}""`
                                        }
                                    }
                                    )
                                } variant="contained" style={{
                                    textDecorationLine: 'none',
                                }} >
                                    Download
                                </Button>
                            </Box>
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            {
                                                listTableHead.map((item, index) => {
                                                    return <TableCell
                                                        style={{ textTransform: 'none', cursor: "pointer" }}
                                                        key={index}
                                                        onClick={() => {
                                                            if (oderByKey === item.dataKey) {
                                                                if (oderBy === "asc") {
                                                                    setOderBy("desc")
                                                                } else {
                                                                    setOderBy("asc")
                                                                }
                                                            } else {
                                                                setOderBy("asc")
                                                                setOderByKey(item.dataKey)
                                                            }
                                                        }}
                                                    >
                                                        {`${item.title} `}
                                                        {oderByKey === item.dataKey ? oderBy === "asc" ? <SortIcon sx={{ fontSize: "16px", transform: 'scaleY(-1)' }} /> : <SortIcon sx={{ fontSize: "16px" }} /> : ''}
                                                    </TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            rowsFilter.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => {
                                                const {
                                                    vehicle_id,
                                                    odometer,
                                                    startCharging,
                                                    startTime,
                                                    endCharging,
                                                    endTime,
                                                    NextOrder,
                                                    NextCh,
                                                    usesoc,
                                                    total
                                                } = value;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell style={{ whiteSpace: "nowrap" }}>{vehicle_id}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{odometer}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap" }}>{fDateTo(new Date(startTime), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{startCharging.toLocaleString("th-TH", { minimumFractionDigits: 2 })}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap" }}>{fDateTo(new Date(endTime), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{endCharging.toLocaleString("th-TH", { minimumFractionDigits: 2 })}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{NextOrder}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{NextCh.toLocaleString("th-TH", { minimumFractionDigits: 2 })}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{usesoc.toLocaleString("th-TH", { minimumFractionDigits: 2 })}</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", textAlign: "right" }}>{total.toLocaleString("th-TH", { minimumFractionDigits: 2 })}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[
                                    { value: 10, label: '10' },
                                    { value: 20, label: '20' },
                                    { value: 30, label: '30' },
                                    { value: 50, label: '50' },
                                    // { value: 100, label: 100 },
                                    // { value: listLog.length, label: 'All' }
                                ]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Container>
                    )}
            </MainStyle>
        </Page>
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));