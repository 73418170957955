import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, Polyline, TileLayer, ZoomControl } from 'react-leaflet';
import PolylineUtil from 'polyline-encoded';
import { styled, useTheme } from '@mui/styles';
import { Backdrop, useMediaQuery, Grid, Box, IconButton, Button, Menu, MenuItem, Slider, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete } from '@mui/material';
import ListTileLayer from '../../layouts/ListTileLayer';
import { useSearchParams } from 'react-router-dom';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import crypto from 'crypto-js';
import { Api } from '../../components/api';
import axios from 'axios';
import { fDateTo } from '../../utils/formatTime';
import https from 'https';
import HistoryPlayBack from './HistoryPlayBack';
import MarkerRotatedWithLabel from '../route/MarkerRotatedWithLabel';

const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    height: '100vh',
    width: "calc(100vw)",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
        width: "100vw",
    },
}));



const center = [13.839660198254604, 100.63469639884744];
const zoom = 6;


export default function HistoryMap() {
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")
    const refMapStyle = useRef(null);
    const [map, setMap] = useState(null);
    const [searchParams] = useSearchParams();
    const [listReplay, setListReplay] = useState([])
    const [listTables, setListTables] = useState([])
    const [markerSelect, setMarkerSelect] = useState(null)
    const [move, setMove] = useState(false)
    const [sliderValue, setSliderValue] = useState(0)





    function encodeBase64(value) {
        if (value !== '') {
            var word = crypto.enc.Base64.parse(searchParams.get("data"))
            var textString = crypto.enc.Utf8.stringify(word);
            return textString;
        }
        return ''
    }
    const dataParams = encodeBase64(searchParams.get("data") || '')


    const selectedTime = new Date();


    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const formik = useFormik({
        initialValues: {
            device: "",
            dateStart: selectedTime.setHours(0, 0, 0),
            dateEnd: selectedTime.setHours(23, 59, 59),
        },
        onSubmit: (values, { setSubmitting }) => {
            if (isJson(dataParams)) {

                const dataJson = JSON.parse(dataParams)

                const { device, url } = dataJson.history
                axios.get(`${url}/tracking/history`, {
                    params: {
                        device: device,
                        dateStart: fDateTo(values.dateStart, 'yyyy-MM-dd HH:mm:ss'),
                        dateEnd: fDateTo(values.dateEnd, 'yyyy-MM-dd HH:mm:ss')
                    },
                    httpsAgent: new https.Agent({ rejectUnauthorized: false }),
                    headers: {
                        scheme: "http"
                    }
                }).then((res) => {
                    const { encode, gpslog } = res.data
                    if (encode) {
                        setListTables(gpslog)
                        const _encode = PolylineUtil.decode(encode)
                        map.flyToBounds(_encode, { duration: 1, paddingTopLeft: [8, 8] })
                        setListReplay(_encode);
                        setSubmitting()
                    }
                }).catch((e) => {
                });
            }
        }
    });




    const { errors, touched, isSubmitting, values, setFieldValue, handleSubmit, getFieldProps } = formik;
    const ItemTableRow = listTables.map((row, i) =>
        <TableRow hover role="checkbox" sx={{
            cursor: "pointer"
        }} key={"tableRow" + i} onClick={() => {
            setMarkerSelect(row)
            setMove(true)
            setSliderValue(i)
        }}
            id={"tableRow" + i}
        >
            <TableCell>{fDateTo(row.datetime_gps.replace("Z",""), 'dd-MM-yyyy HH:mm:ss')}</TableCell>
            <TableCell>{row.speed}</TableCell>
            <TableCell></TableCell>
        </TableRow>
    );



    useEffect(() => {
        const element = document.getElementById("tableRow" + sliderValue);
        if (markerSelect) {
            element?.classList.add("tableRow-active")
            // element.scrollIntoView({ block: "end" })
        }
        return () => {
            element?.classList?.remove("tableRow-active")
        }
    }, [sliderValue, markerSelect])


    useEffect(() => {
        if (markerSelect && move) {
            map.setView([markerSelect?.latitude, markerSelect?.longitude], map.getZoom(), {
                "animate": true,
                "duration": 1
            })
        }
    }, [markerSelect, move])

    return (
        <Box>
            <Box sx={{
                position: "absolute", zIndex: 9,
                top: "10px",
                left: "50%",
                transform: "translate(-50%, 0)",
                width: "410px",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "20px"
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <MobileDateTimePicker
                                        ampm={false}
                                        disableFuture={true}
                                        label="วันเวลาเริ่ม"
                                        allowKeyboardControl={false}
                                        value={values.dateStart}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={(val) => {
                                            setFieldValue('dateStart', val);
                                        }}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        InputProps={{
                                            style: {
                                                height: "30px"
                                            }
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            {...params}
                                            {...getFieldProps('dateStart')}
                                            fullWidth
                                            placeholder="DD/MM/YYYY"
                                            autoComplete="off"
                                            error={Boolean(touched.dateStart && errors.dateStart)}
                                            helperText={touched.dateStart && errors.dateStart}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <MobileDateTimePicker
                                        ampm={false}
                                        disableFuture={true}
                                        label="ถึงวันเวลา"
                                        allowKeyboardControl={false}
                                        value={values.dateEnd}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={(val) => {
                                            setFieldValue('dateEnd', val);
                                        }}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        InputProps={{
                                            style: {
                                                height: "30px"
                                            }
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            {...params}
                                            {...getFieldProps('dateEnd')}
                                            placeholder="DD/MM/YYYY"
                                            autoComplete="off"
                                            fullWidth
                                            error={Boolean(touched.dateEnd && errors.dateEnd)}
                                            helperText={touched.dateEnd && errors.dateEnd}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ justifyContent: "center", display: "flex" }}>
                                    <Button type="submit" variant="outlined" style={{ maxHeight: '30px', minHeight: '30px' }} >ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </LocalizationProvider>
            </Box>

            <Box sx={{
                position: "absolute", zIndex: 9,
                top: "100px",
                left: "50%",
                transform: "translate(-50%, 0)",
                width: "410px",
                padding: "10px",
                borderRadius: "20px"
            }}>
                <HistoryPlayBack
                    listTables={listTables} setMarkerSelect={setMarkerSelect}
                    markerSelect={markerSelect} setMove={setMove}
                    sliderValue={sliderValue} setSliderValue={setSliderValue}
                />
            </Box>
            <MapContainerStyle
                whenCreated={m => {
                    setMap(m)
                }}
                center={center}
                zoom={zoom}
                minZoom={3}
                zoomControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                attributionControl={false}
            >
                <TileLayer ref={refMapStyle}
                    url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                <ZoomControl position='topright' />
                <Polyline positions={listReplay} weight={10} />
                {
                    markerSelect && (
                        <MarkerRotatedWithLabel
                            position={[markerSelect?.latitude, markerSelect?.longitude]}
                            rotationAngle={markerSelect?.bearing}
                            rotationOrigin="center"
                        />
                    )
                }
            </MapContainerStyle>

            <Box sx={(theme) => (
                {
                    position: "absolute",
                    zIndex: 9,
                    bottom: "5px",
                    left: "10%",
                    width: "90vw",
                    transform: "translate(-5%, 0)",
                    [theme.breakpoints.up('md')]: {
                        left: "40%",
                        width: "60vw",
                        transform: "translate(-30%, 0)",
                    },
                    backgroundColor: "white",
                    borderRadius: "20px",
                    minHeight: "160px",
                    maxHeight: "160px",
                    overflow: "auto"
                }
            )}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>datetime_gps</TableCell>
                            <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>speed</TableCell>
                            <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>odometer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ItemTableRow}
                    </TableBody>
                </Table>
            </Box >
        </Box >
    )
}
