import React, { useEffect } from 'react'
import { Box, Grid, Typography, Divider, Container, Button, Stack, Switch, List, ListItemButton, ListItemText, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useState } from 'react'
import { styled } from '@mui/styles'
import _ from 'lodash'
import Page from '../../components/Page'
import PermissionsGate from './PermissionsGate'
import { PERMISSIONPAGES, SCOPES } from './permission-maps'
import PermissionDialog from './PermissionDialog'
import { Api } from '../../components/api'

export default function MainPermission() {

    const [permission, setPermission] = useState({})
    const pagePermissions = PERMISSIONPAGES
    const [open, setOpen] = useState(false)


    // useEffect(() => {
    //     const getData = () => {
    //         Api.get(`/Tracking/Permission?cname=TSB&userid=booking.tsb&permission=SITGPS`)
    //             .then(res => {
    //                 const { value } = res.data
    //                 setPermission(JSON.parse(value))
    //             }
    //             );
    //     }
    //     if (open) {
    //         getData()
    //     }
    // }, [open])

    const getData = () => {
        Api.get(`/Tracking/Permission?cname=TSB&userid=booking.tsb&permission=SITGPS`)
            .then(res => {
                const { value } = res.data
                setPermission(JSON.parse(value))
                handleOpen(true)
            }
            )
            .catch((error) => {
                handleOpen(true)
            });
    }




    const onChange = (name, value) => {

        if (permission[name]) {
            setPermission((self) => {
                return { ...self, [name]: permission[name].concat(value) }
            })
        } else {
            setPermission((self) => {
                return { ...self, [name]: [].concat(value) }
            })
        }
    }
    const onChangeUnCheck = (name, value) => {
        const unCheck = permission[name].filter(v => v !== value) || []
        if (unCheck.length > 0) {
            setPermission((self) => {
                return { ...self, [name]: unCheck }
            })
        } else {
            let delPer = permission
            delete delPer[name]
            // console.log('delPer', delPer)
            setPermission((self) => {
                return { ...delPer }
            })
        }
    }

    const handleSubmit = () => {
        // console.log("permission", JSON.stringify(permission))


        const req = {
            "cname": "TSB",
            "userid": "booking.tsb",
            "permission": "SITGPS",
            "name": "SITGPS",
            "value": JSON.stringify(permission)
        }
        // console.log("permission", req)

        Api.post(`/Tracking/Permission`, req).then((res) => {

            console.log("permission", JSON.stringify(res.data.value))

        }).catch(e => {
            console.log('error', e)
        })
        // localStorage.setItem()
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <Page title="permission">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Permission</Typography>
                </Box>
                <Divider />
                <MainStyle>

                    <Button onClick={getData}>open</Button>

                    <PermissionDialog title={`Permission`} open={open} handleClose={handleClose}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: "column", padding: "15px" }}>
                            {
                                pagePermissions.map((actPer, index) => {
                                    return (
                                        <React.Fragment key={`acPer ${index}`}>
                                            <Grid container >
                                                <Grid item xs={12} padding="15px">
                                                    <Typography> {actPer.title} </Typography>
                                                </Grid>
                                                <Stack direction="row" spacing={1} margin="8px" sx={{ flexWrap: 'wrap', gap: 1 }}>
                                                    {
                                                        actPer.actions.map((actval, indexact) => (
                                                            <PermissionsGate
                                                                RenderError={() => <Button variant="outlined" size="small" onClick={() => { onChange(actPer.name, actval.value) }}>{actval.name}</Button>}
                                                                permissions={permission !== null ? permission[actPer.name] : []}
                                                                scopes={[actval.value]}
                                                                key={`actions${indexact}`}
                                                            >
                                                                <Button variant="contained" size="small" onClick={() => { onChangeUnCheck(actPer.name, actval.value) }}>{actval.name}</Button>
                                                            </PermissionsGate>
                                                            // <>
                                                            //     {
                                                            //         actval.ref ?
                                                            //             <PermissionsGate
                                                            //                 RenderError={() => <Button variant="outlined" size="small" disabled>{actval.name}</Button>}
                                                            //                 permissions={permission !== null ? permission[actPer.name] : []}
                                                            //                 scopes={}
                                                            //                 key={`actions${indexact}`}
                                                            //             >
                                                            //                 <PermissionsGate
                                                            //                     RenderError={() => <Button variant="outlined" size="small" onClick={() => { onChange(actPer.name, actval.value) }}>{actval.name}</Button>}
                                                            //                     permissions={permission !== null ? permission[actPer.name] : []}
                                                            //                     scopes={[actval.value]}
                                                            //                     key={`actions${indexact}`}
                                                            //                 >
                                                            //                     <Button variant="contained" size="small" onClick={() => { onChangeUnCheck(actPer.name, actval.value) }}>{actval.name}</Button>
                                                            //                 </PermissionsGate>
                                                            //             </PermissionsGate>
                                                            //             :
                                                            //             <PermissionsGate
                                                            //                 RenderError={() => <Button variant="outlined" size="small" onClick={() => { onChange(actPer.name, actval.value) }}>{actval.name}</Button>}
                                                            //                 permissions={permission !== null ? permission[actPer.name] : []}
                                                            //                 scopes={[actval.value]}
                                                            //                 key={`actions${indexact}`}
                                                            //             >
                                                            //                 <Button variant="contained" size="small" onClick={() => { onChangeUnCheck(actPer.name, actval.value) }}>{actval.name}</Button>
                                                            //             </PermissionsGate>
                                                            //     }
                                                            // </>
                                                        )
                                                        )
                                                    }
                                                </Stack>
                                            </Grid>
                                            <Divider />
                                        </React.Fragment>
                                    )
                                })
                            }
                            {/* <Grid container > */}
                            <Grid padding="15px"
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button variant="outlined" color="secondary" onClick={handleSubmit}>Save</Button>
                            </Grid>
                        </Box>
                    </PermissionDialog>
                </MainStyle>
            </Container>
        </Page>
    )
}


const ListButton = styled((props) => <ListItemButton disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.body2,
        height: 48,
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: 15,
        paddingRight: 15,
        color: "black",
        backgroundColor: "white",
        '&:before': {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: "''",
            display: 'none',
            position: 'absolute',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4
        }
    })
);
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 0,
    overflow: 'auto'
}));