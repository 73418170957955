import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// material
import {
  Button,
  Divider,
  TextField,
  Container,
  Avatar,
  Collapse,
  ListItemButton,
  List,
  ListSubheader,
  ListItemText,
  Grid
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { Box } from '@mui/system';

// components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



export default function ChangepassProfile({ account, userId }) {

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };


  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
  };
  const LoginSchema = Yup.object().shape({
    password: Yup.string().min(6, 'must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string().min(6, "must be at least 6 characters").required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        userid: userId,
        password: values.password
      });
      await Api.put('v0/app/resetpassword', data)
        .then(res => {
          handleOnClose()
          handleSnackbar({ variant: 'success', message: 'success!' })
        }
        ).catch(error => {
          const { message } = error
          handleSnackbar({ variant: 'error', message: message });
        }
        )
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;
  const handleOnClose = () => {
    navigate('/account');
    // setErrors({});
    // resetForm();
  }
  return (
    <Page title="account">
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
            <Box width={400} margin="auto" padding={2} >
              <List
                sx={{ width: '100%', maxWidth: 320, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary="Change password" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box padding={2} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          type={'password'}
                          label="New Password"
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          type={'password'}
                          label="Confirm Password"
                          {...getFieldProps('confirmPassword')}
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
                <Divider style={{ marginTop: 10 }} />

                <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                  <Grid item xs={6} justifyContent="center">
                    <Button
                      fullWidth
                      onClick={handleOnClose}
                      size="medium"
                      variant="outlined"
                      color="info"
                    // fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} justifyContent="center">
                    <LoadingButton
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                      color="info"
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </List>
            </Box>
          </Form>
        </FormikProvider>
      </Container>
    </Page >
  );
}
