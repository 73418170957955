import React, { useEffect, useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import CloseIcon from '@mui/icons-material/Close';
import { Box, TextField, InputAdornment, IconButton, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, Stack } from '@mui/material';
import { styled } from '@mui/styles';
import CardSideDash from './CardSideDash';
import { fakerdata } from './fakerDash';
import InfiniteScroll from 'react-infinite-scroll-component';
import PlaylistCustom from './PlaylistDash/PlaylistCustom';
import PlaylistDash from './PlaylistDash/PlaylistDash';
import { Virtuoso } from 'react-virtuoso';
import PlaylistEditCustom from './PlaylistDash/PlaylistEditCustom';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { fDateTo } from '../../utils/formatTime';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { CSVLink } from "react-csv"

export default function ControlSideDash({ account, groupSelect, search, subscribe, handleChange, handleClickOpenMenu, handleClickDashboard, devicesSub, filter, onClickSelectDivce, userId, handleSubscribe, onGetGroup, handleSubscribeCustom, sideDash, setSideDash, mapView }) {
    const { c_coname } = account;
    const [page, setPage] = useState(0)
    const perpage = 10


    useEffect(() => {
        setPage(e => 0)
    }, [search, subscribe])



    const loadMore = () => {
        setPage(p => p + 1)
    }
    // const filter = devicesSub.filter((val) => val.name.includes(search))

    const offLine = filter.filter(value => {
        const dateNow = new Date()
        const minOffLine = dateNow.setMinutes(dateNow.getMinutes() - 5)
        return value.datetime === null || new Date(value.datetime) < minOffLine
    })


    const onClickPlayList = () => {
        setOpenCustom(true)
    }

    const [openCustom, setOpenCustom] = useState(false)

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }

    const [header, setHeader] = useState([
        {
            label: "ชื่อรถ",
            key: "name",
            sub: false,
            show: true,
            align: "left"
        },
        {
            label: "ความเร็ว",
            key: "speed",
            sub: false,
            show: true,
            align: "right"
        },
        {
            label: "ตำแหน่ง",
            key: "widget_bar",
            sub: true,
            show: true,
            subkey: "value",
            align: "left"
        },
        {
            label: "เวลา",
            key: "datetime",
            sub: false,
            date: true,
            show: true,
            align: "left"
        },
        {
            label: "latitude",
            key: "latitude",
            sub: false,
            show: true,
            align: "left"
        },
        {
            label: "longitude",
            key: "longitude",
            sub: false,
            show: true,
            align: "left"
        },
        {
            label: "device",
            key: "device",
            sub: false,
            show: false,
            align: "left"
        },
        {
            label: "charging_status",
            key: "charging_status",
            sub: false,
            show: false,
            align: "left"
        }
    ])

    const [openHeader, setOpenHeader] = useState(false)
    const handleClickOpenHeader = () => {
        setOpenHeader(true);
    };

    const handleCloseHeader = () => {
        setOpenHeader(false);
    };
    return (
        <>
            <Box sx={{ px: "0px", py: "0px" }}>
                <ToolbarStyleSearch>
                    <TextField
                        fullWidth
                        size="small"
                        label={groupSelect?.group_id ? groupSelect.group_id : "search"}
                        value={search}
                        onChange={handleChange}
                        autoComplete='off'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end' >
                                    <IconButton
                                        edge="end"
                                        onClick={handleClickOpenMenu}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-haspopup="true"
                                    >
                                        <FilterListIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton
                        style={{ borderRadius: 0, marginLeft: "5px" }}
                        size="small"
                        onClick={onClickPlayList}>
                        <PlaylistAddIcon />
                    </IconButton>
                    {sideDash == "widget" && (
                        <IconButton
                            style={{ borderRadius: 0, marginLeft: "5px" }}
                            size="small"
                            onClick={() => {
                                setSideDash("table")
                                localStorage.setItem("sideDashMode", "table")
                            }}>
                            <TableChartTwoToneIcon />
                        </IconButton>
                    )}
                    {sideDash == "table" && (
                        <IconButton
                            style={{ borderRadius: 0, marginLeft: "5px" }}
                            size="small"
                            onClick={() => {
                                setSideDash("widget")
                                localStorage.setItem("sideDashMode", "widget")
                            }}>
                            <TableRowsTwoToneIcon />
                        </IconButton>
                    )}
                    <IconButton
                        style={{ borderRadius: 0, marginLeft: "5px" }}
                        size="small"
                        onClick={handleClickDashboard}>
                        {mapView ? <DashboardRoundedIcon /> : <MapRoundedIcon />}
                        {/* <DashboardRoundedIcon /> */}
                    </IconButton>
                </ToolbarStyleSearch>
            </Box>
            {
                sideDash == "widget" && (
                    <Box>
                        <Box sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px'
                        }}>
                            <Grid spacing={1} container justifyContent="center">
                                <Grid item xs={4} justifyContent="center">
                                    <Typography textAlign="center" variant="h6">{filter.length}</Typography>
                                    <Typography textAlign="center" color="#A9A9A9">DEVICE</Typography>
                                </Grid>
                                <Grid item xs={4} justifyContent="center">
                                    <Typography textAlign="center" variant="h6">{filter.length - offLine.length}</Typography>
                                    <Typography textAlign="center" color="#A9A9A9">ONLINE</Typography>
                                </Grid>
                                <Grid item xs={4} justifyContent="center">
                                    <Typography textAlign="center" variant="h6">{offLine.length}</Typography>
                                    <Typography textAlign="center" color="#A9A9A9">OFFLINE</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                overflowY: "auto"
                            }}
                            id="DeviceScroll"
                        >
                            <Virtuoso
                                style={{ height: "calc(100vh - 190px)" }}
                                data={filter.filter((val) => val.name.toUpperCase().includes(search.toUpperCase()))}
                                itemContent={(index, data) => {
                                    return (
                                        <CardSideDash
                                            onClick={() => {
                                                onClickSelectDivce({ device: data.device, latitude: data.latitude, longitude: data.longitude, isZoom: true })
                                            }}
                                            data={data}
                                            key={`CardSideDash${index}`}
                                        />
                                    )
                                }
                                }
                            />
                        </Box>
                    </Box>
                )
            }
            {
                sideDash == "table" && (
                    <Box>
                        <Box sx={{ paddingY: "10px", width: "100%" }} justifyContent="flex-end" justifyItems="flex-end" display="flex">
                            <Stack spacing={1} direction="row">
                                {/* <Box>
                                    <IconButton variant="outlined" size="small" onClick={handleClickOpenHeader}>
                                        <RuleRoundedIcon />
                                    </IconButton>
                                </Box> */}
                                <Box>
                                    <IconButton variant="outlined" size="small"
                                        as={CSVLink} filename={`tracking.csv`} data={
                                            filter.filter((val) => val.name.toUpperCase().includes(search.toUpperCase())).map((value, index) => {
                                                const {
                                                    widget_bar, name, device, datetime, latitude, longitude,
                                                    speed, charging_status
                                                } = value;
                                                return {
                                                    "ชื่อรถ": `=""${name}""`,
                                                    "ตำแหน่ง": `=""${widget_bar.value}""`,
                                                    "เวลา": `=""${fDateTo(new Date(datetime), 'HH:mm:ss')}""`,
                                                    "ละติจูด": `=""${latitude}""`,
                                                    "ลองจิจูด": `=""${longitude}""`,
                                                    "ความเร็ว": `=""${speed}""`,
                                                    "device": `=""${device}""`,
                                                    "charging_status": `=""${charging_status}""`,


                                                }
                                            }
                                            )
                                        }
                                        style={{
                                            textDecorationLine: 'none'
                                        }}
                                    >
                                        <DownloadRoundedIcon  />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                        <Box >
                            <TableContainer component={Paper} style={{ maxHeight: "calc(100vh - 178px)", overflow: "auto" }} >
                                <Table size="small" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                header.map((value, index) => {

                                                    return (
                                                        <>
                                                            {
                                                                value.show && (
                                                                    <TableCell align={value.align}>{value.label}</TableCell>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filter.filter((val) => val.name.toUpperCase().includes(search.toUpperCase())).map((row) => (
                                            <TableRow key={row.name}
                                                onClick={() => {
                                                    onClickSelectDivce({ device: row.device, latitude: row.latitude, longitude: row.longitude, isZoom: true })
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {

                                                    header.map((value, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    value.show && (
                                                                        <TableCell style={{ whiteSpace: "nowrap" }} align={value.align}>{value.sub ? value.date ? fDateTo(row[value.key].value, "dd-MM-yy HH:mm:ss") : row[value.key].value : value.date ? fDateTo(row[value.key], "dd-MM-yy HH:mm:ss") : row[value.key]}</TableCell>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Dialog
                            // fullScreen={true}
                            max
                            open={openHeader}
                            onClose={handleCloseHeader}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">
                                ตั้งค่าเนื้อหา
                            </DialogTitle>
                            <DialogContent>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={100}>{``}</TableCell>
                                                <TableCell width={300}>{`ชื่อ`}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                header.map((head, index) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>
                                                                <Checkbox checked={head.show} size="small" />
                                                            </TableCell>
                                                            <TableCell>{head.label}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleCloseHeader} size="small">
                                    save
                                </Button>
                                <Button onClick={handleCloseHeader} autoFocus size="small">
                                    cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                )
            }
            <PlaylistDash c_coname={c_coname} handleSubscribe={handleSubscribe} openCustom={openCustom} handleDialogCustom={() => { setOpenCustom(!openCustom) }} userId={userId} onGetGroup={onGetGroup} handleSubscribeCustom={handleSubscribeCustom} />
        </>
    )
}



const ToolbarStyleSearch = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    // padding: theme.spacing(1, 2, 1, 2)
}));