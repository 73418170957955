import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import FindInPageIcon from '@mui/icons-material/FindInPage';

export default function ButtonDialogCustomer({ disabled, onHandle }) {
    const [customer, setCustomer] = useState({
        "id": "123456",
        "name": "John Doe",
        "email": "john.doe@example.com",
        "phone": "+1234567890",
        "address": {
            "street": "123 Main St",
            "city": "Anytown",
            "state": "CA",
            "zip": "12345",
            "country": "USA"
        }
    })

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        setOpen(false)
        // onHandle({ ...customer })
        setCustomer({
            "id": "123456",
            "name": "John Doe",
            "email": "john.doe@example.com",
            "phone": "+1234567890",
            "address": {
                "street": "123 Main St",
                "city": "Anytown",
                "state": "CA",
                "zip": "12345",
                "country": "USA"
            }
        })
    }



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setCustomer(e => {
            return ({ ...e, [name]: value })
        })
    }
    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><FindInPageIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>ข้อมูลลูกค้า</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่' placeholder='เลขที่' onChange={handleChange} value={customer.id} name='id' fullWidth />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='ชื่อ' placeholder='ชื่อ' onChange={handleChange} value={customer.name} name='name' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='วันเกิด' onChange={handleChange} value={customer.birthday} name='birthday' fullWidth
                                        placeholder='DD-MM-YYYY'
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='email' placeholder='email' onChange={handleChange} value={customer.email} name='email' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='street' placeholder='street' onChange={handleChange} value={customer.address.street} name='street' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='city' placeholder='city' onChange={handleChange} value={customer.address.city} name='city' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='state' placeholder='state' onChange={handleChange} value={customer.address.state} name='state' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='zip' placeholder='zip' onChange={handleChange} value={customer.address.zip} name='zip' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='country' placeholder='country' onChange={handleChange} value={customer.address.country} name='country' fullWidth />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField size="small" label='หมายเหตุ' value={``} multiline maxRows={3} minRows={3} name='remake' fullWidth />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}