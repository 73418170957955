import styled from '@emotion/styled';
import { Autocomplete, Popper, Box, Collapse, Container, Divider, Grid, List, ListItemButton, Button, ListItemText, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function EditDevice({ listModel = [], masterGroup = [], dltVender, state, listVehicleType = [], listProvince = [], listVehicleRegisterType = [] }) {

    const { id } = useParams();
    // const location = useLocation();

    // const { gpsmodel, devicename } = location.state;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const [open, setOpen] = useState({ required: true, attributes: true, group: true })

    const DeviceSchema = Yup.object().shape({
        // gpsmodel: Yup.object().shape({
        // gpsmodel: Yup.string().required('Model Gps is required'),
        // }).nullable().required('Model Gps is required'),
        // devicename: Yup.string().required('Device Name is required'),
        // imei: Yup.string().required('Imei is required'),
        // device: Yup.string().required('Deviceid is required'),
        // telgps: Yup.string().required('Telgps is required'),
        group: Yup.object().shape({
            group_id: Yup.string().required('Group is required'),
        }).nullable().required('Group is required'),

    })
    const { gpsmodel, vehicle_type, province_code, vehicle_register_type, group_id } = state;
    const groups = masterGroup.find((e, index) => e.group_id === group_id);
    const formik = useFormik({
        initialValues: {
            gpsmodel: '',
            devicename: '',
            imei: '',
            device: '',
            telgps: '',
            ...state,
            dateinstall: new Date(state.dateinstall),
            dateexp: new Date(state.dateexp),
            gpsmodel: listModel.find((e, index) => e.gpsmodel === gpsmodel),
            vehicle_type: listVehicleType.find((e, index) => e.vehicle_type === vehicle_type),
            province_code: listProvince.find((e, index) => e.province_code === province_code),
            vehicle_register_type: listVehicleRegisterType.length > 0 ? listVehicleRegisterType.find((e, index) => e.vehicle_register_type === vehicle_register_type) : {},
            group: groups === undefined ? null : masterGroup.find((e, index) => e.group_id === group_id),
        },
        validationSchema: DeviceSchema,

        onSubmit: (values) => {
            const data = JSON.stringify({
                device: state.device,
                car_id: values.car_id,
                group_id: values.group?.group_id,
            });
            Api.put('/Tracking/DevicesInfo', data)
                .then(res => {
                    // if (values.dlt_status) {
                    //     const dataUp = JSON.stringify({
                    //         cocode: dltVender.cocode,
                    //         cobcode: dltVender.cobcode,
                    //         device: values.device,
                    //     });
                    //     Api.post('v0/gps/masterfile_dlt', dataUp);
                    // }
                    handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                    navigate('/settings/devices')
                }
                ).catch(error => {
                    console.log('error', error)
                }
                )
        },
        enableReinitialize: true
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const handleClick = (name) => {
        setOpen({ ...open, [name]: !open[name] });
    };
    const handleOnClose = () => {
        navigate('/settings/devices')
    }
    const dltVenderField = dltVender.vender_id !== undefined ? dltVender.vender_id + " : " + dltVender.vender_name_th : ""

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <Grid container >
                            <Grid item xs={12} sm={12} md={4} >
                                <ListItemButton onClick={() => {
                                    handleClick("required")
                                }} >
                                    <ListItemText primary="Required" />
                                    {
                                        open.required ?
                                            <ExpandLess />
                                            :
                                            <ExpandMore />

                                    }
                                </ListItemButton>
                                <Collapse in={open.required} timeout="auto" unmountOnExit>
                                    <Box padding={2} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="ผู้ประกอบการ GPS"
                                                    // {...getFieldProps('name')}
                                                    value={dltVenderField}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    label="ชื่ออุปกรณ์"
                                                    {...getFieldProps('devicename')}
                                                    error={Boolean(touched.devicename && errors.devicename)}
                                                    helperText={touched.devicename && errors.devicename}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    id="gpsmodel"
                                                    size="small"
                                                    name="gpsmodel"
                                                    options={listModel}
                                                    getOptionLabel={(option) => option.name}
                                                    fullWidth
                                                    value={values.gpsmodel}
                                                    // {...getFieldProps('gpsmodel')}
                                                    isOptionEqualToValue={(option, value) => option.gpsmodel === value.gpsmodel}
                                                    onChange={(e, value) => {
                                                        // const gpsmodel = value !== null ? value.gpsmodel : null;
                                                        setFieldValue("gpsmodel", value)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            label="เลือกรุ่น GPS"
                                                            fullWidth
                                                            autoComplete="off"
                                                            name="gpsmodel"
                                                            {...params}
                                                            {...getFieldProps('gpsmodel')}
                                                            error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                                                            helperText={touched.gpsmodel && errors.gpsmodel}
                                                        />
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="เลข IMEI"
                                                    {...getFieldProps('imei')}
                                                    inputProps={{ maxLength: 20 }}
                                                    error={Boolean(touched.imei && errors.imei)}
                                                    helperText={touched.imei && errors.imei}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    size="small"
                                                    label="รหัสอุปกรณ์"
                                                    {...getFieldProps('device')}
                                                    error={Boolean(touched.device && errors.device)}
                                                    helperText={touched.device && errors.device}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="เบอร์โทร GPS"
                                                    {...getFieldProps('telgps')}
                                                    error={Boolean(touched.telgps && errors.telgps)}
                                                    helperText={touched.telgps && errors.telgps}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} >
                                <ListItemButton onClick={() => {
                                    handleClick("attributes")
                                }} >
                                    <ListItemText primary="Attributes" />
                                    {
                                        open.attributes ?
                                            <ExpandLess />
                                            :
                                            <ExpandMore />

                                    }
                                </ListItemButton>
                                <Collapse in={open.attributes} timeout="auto" unmountOnExit>
                                    <Box padding={2} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    autoComplete="off"
                                                    type="text"
                                                    label="เลขตัวถัง"
                                                    {...getFieldProps('vehicle_chassis_no')}
                                                    error={Boolean(touched.vehicle_chassis_no && errors.vehicle_chassis_no)}
                                                    helperText={touched.vehicle_chassis_no && errors.vehicle_chassis_no}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    size="small"
                                                    id="vehicle_type"
                                                    name="vehicle_type"
                                                    options={listVehicleType}
                                                    value={values.vehicle_type}
                                                    getOptionLabel={(option) => option.vehicle_type}
                                                    isOptionEqualToValue={(option, value) => option.vehicle_type === value.vehicle_type}
                                                    fullWidth
                                                    onChange={(e, value) => {
                                                        // const vehicle_type = value !== null ? value.vehicle_type.toString() : '';
                                                        setFieldValue("vehicle_type", value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="off"
                                                            name="vehicle_type"
                                                            {...params}
                                                            type="text"
                                                            label="ยี่ห้อรถ"
                                                            {...getFieldProps('vehicle_type')}
                                                            error={Boolean(touched.vehicle_type && errors.vehicle_type)}
                                                            helperText={touched.vehicle_type && errors.vehicle_type}
                                                        />
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    autoComplete="off"
                                                    type="text"
                                                    label="ชื่อรถ"
                                                    {...getFieldProps('car_id')}
                                                    error={Boolean(touched.car_id && errors.car_id)}
                                                    helperText={touched.car_id && errors.car_id}
                                                // onChange={(e) => console.log(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    autoComplete="off"
                                                    type="text"
                                                    label="ป้ายทะเบียน"
                                                    {...getFieldProps('vehicle_id')}
                                                    error={Boolean(touched.vehicle_id && errors.vehicle_id)}
                                                    helperText={touched.vehicle_id && errors.vehicle_id}
                                                    disabled
                                                // onChange={(e) => console.log(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    size="small"
                                                    id="province_code"
                                                    name="province_code"
                                                    options={listProvince}
                                                    getOptionLabel={(option) => option.province_name}
                                                    value={values.province_code}
                                                    isOptionEqualToValue={(option, value) => option.province_code === value.province_code}
                                                    fullWidth
                                                    onChange={(e, value) => {
                                                        setFieldValue("province_code", value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="off"
                                                            name="province_code"
                                                            {...params}
                                                            type="text"
                                                            label="จังหวัด"
                                                            {...getFieldProps('province_code')}
                                                            error={Boolean(touched.province_code && errors.province_code)}
                                                            helperText={touched.province_code && errors.province_code}
                                                        />
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    size="small"
                                                    id="vehicle_register_type"
                                                    name="vehicle_register_type"
                                                    options={listVehicleRegisterType}
                                                    getOptionLabel={(option) => option.vehicle_register_name}
                                                    isOptionEqualToValue={(option, value) => option.vehicle_register_type === value.vehicle_register_type}
                                                    fullWidth
                                                    value={values.vehicle_register_type}
                                                    onChange={(e, value) => {
                                                        setFieldValue("vehicle_register_type", value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="off"
                                                            name="vehicle_register_type"
                                                            {...params}
                                                            type="text"
                                                            label="ประเภทรถ"
                                                            {...getFieldProps('vehicle_register_type')}
                                                            error={Boolean(touched.vehicle_register_type && errors.vehicle_register_type)}
                                                            helperText={touched.vehicle_register_type && errors.vehicle_register_type}
                                                        />
                                                    )}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MobileDatePicker
                                                    disableFuture={true}
                                                    // allowSameDateSelection
                                                    label="วันติดตั้ง"
                                                    allowKeyboardControl={false}
                                                    value={values.dateinstall}
                                                    inputFormat="dd/MM/yyyy"
                                                    format="dd/MM/yyyy"
                                                    onChange={(val) => {
                                                        setFieldValue('dateinstall', val);
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        size="small"
                                                        {...params}
                                                        {...getFieldProps('dateinstall')}
                                                        fullWidth
                                                        placeholder="DD/MM/YYYY"
                                                        autoComplete="off"
                                                        error={Boolean(touched.dateinstall && errors.dateinstall)}
                                                        helperText={touched.dateinstall && errors.dateinstall} />}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MobileDatePicker
                                                    // disableFuture={false}  
                                                    // disablePast={true}
                                                    // allowSameDateSelection
                                                    minDate={values.dateinstall}
                                                    label="วันหมดอายุ"
                                                    allowKeyboardControl={false}
                                                    value={values.dateexp}
                                                    inputFormat="dd/MM/yyyy"
                                                    format="dd/MM/yyyy"
                                                    onChange={(val) => {
                                                        setFieldValue('dateexp', val);
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        size="small"
                                                        {...params}
                                                        {...getFieldProps('dateexp')}
                                                        fullWidth
                                                        placeholder="DD/MM/YYYY"
                                                        autoComplete="off"
                                                        error={Boolean(touched.dateexp && errors.dateexp)}
                                                        helperText={touched.dateexp && errors.dateexp} />}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                            </Grid>
                            {/* <Divider style={{ marginTop: 10 }} /> */}
                            <Grid item xs={12} sm={12} md={4} >
                                <ListItemButton onClick={() => {
                                    handleClick("group")
                                }} >
                                    <ListItemText primary="Group" />
                                    {
                                        open.group ?
                                            <ExpandLess />
                                            :
                                            <ExpandMore />

                                    }
                                </ListItemButton>
                                <Collapse in={open.group} timeout="auto" unmountOnExit>
                                    <Box padding={2} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    size="small"
                                                    id="group"
                                                    name="group"
                                                    options={masterGroup}
                                                    value={values.group}
                                                    {...getFieldProps('group')}
                                                    getOptionLabel={(option) => option.group_name}
                                                    isOptionEqualToValue={(option, value) => option.group_id === value.group_id}
                                                    fullWidth
                                                    onChange={(e, value) => {
                                                        setFieldValue("group", value);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            autoComplete="off"
                                                            name="group"
                                                            {...params}
                                                            type="text"
                                                            label="กลุ่ม"
                                                            {...getFieldProps('group')}
                                                            error={Boolean(touched.group && errors.group)}
                                                            helperText={touched.group && errors.group}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Box
                            sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                        >
                            <Box sx={{ margin: "10px" }}>
                                <Button
                                    sx={{
                                        width: '120px'
                                    }}
                                    onClick={handleOnClose}
                                    size="medium"
                                    variant="outlined"
                                    color="info"
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box sx={{ margin: "10px" }}>
                                <LoadingButton
                                    sx={{
                                        width: '120px'
                                    }}
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    color="info"
                                    loading={isSubmitting}
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Box>
                    </List>
                    {/* </Box> */}
                </Form>
            </FormikProvider>
        </LocalizationProvider >
    )
}
