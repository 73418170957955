import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/main';
import NoneLayout from './layouts/NoneLayout';
import ReportLayout from './layouts/ReportLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import UserNew from './pages/UserNew';
import PrivateRoute from './components/PrivateRoute';
import Map from './pages/map/Map';
import Device from './pages/Device';
import DeviceNew from './pages/DeviceNew';
import DeviceEdit from './pages/DeviceEdit';
import DeviceToDLT from './pages/DeviceToDLT';
import Log from './pages/Log';
import Home from './pages/Home';
import Replay from './pages/route/Replay';
import SettingsLayout from './layouts/SettingsLayout';
import SettingsProfile from './pages/profile/SettingsProfile';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { userAtom } from './components/_state';
import AccountLayout from './layouts/AccountLayout';
import MainDevice from './pages/devices/MainDevice';
import Geofences from './pages/geofences/Geofences';
import GeofencesForm from './pages/geofences/GeofencesForm';
import EditDevice from './pages/devices/EditDevice';
import NewDevice from './pages/devices/NewDevice';
import { UseAesCbc } from './components/_crypto/AesCbc';
import { dltVenderAtom, listModelAtom, listProvinceAtom, listVehicleRegisterTypeAtom, listVehicleTypeAtom, permissionAtom } from './components/_state/users';
import ChangepassProfile from './pages/profile/ChangepassProfile';
import GeofencesNew from './pages/geofences/GeofencesNew';
import MainAccount from './pages/accounts/MainAccount';
import NewAccount from './pages/accounts/NewAccount';
import EditAccount from './pages/accounts/EditAccount';
import EditDeviceMain from './pages/devices/EditDeviceMain';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Dlt from './pages/Dlt';
import PrintDltCer from './pages/dlt/PrintDltCer';
import DltCer from './pages/dlt/DltCer';
import MainChart from './pages/chart/MainChart';
import { Typography } from '@mui/material';
import SpeedChart from './pages/chart/SpeedChart';
import EventMain from './pages/event/EventMain';
import OverspeedByDevice from './pages/event/OverspeedByDevice';
import OverspeedByGroup from './pages/event/OverspeedByGroup';
import MainRoutes from './pages/settingsRoute/MainRoute';
import MainRouteTrips from './pages/settingsRoute/MainRouteTrips';
import NewRouteTrips from './pages/settingsRoute/NewRouteTrips';
import UpdateRouteTrips from './pages/settingsRoute/UpdateRouteTrips';
import RouteMain from './pages/settings_route/RouteMain';
import RouteStation from './pages/settings_route/RouteStation';
import DashboardGroup from './pages/dashboard_group/DashboardGroup';
import DashboardMapGroup from './pages/dashboard_group/DashboardMapGroup';
import CardSwipe from './pages/event/CardSwipe';
import CardSwipeByGroup from './pages/event/CardSwipeByGroup';
import Events from './pages/route/Events';
import Groups from './groups/Groups';
import GroupsNew from './groups/GroupsNew';
import GroupsEdit from './groups/GroupEdit';
import PrintDltCerByChassis from './pages/devices/PrintDltCerByChassis';
import OverspeedByDate from './pages/event/OverspeedByDate';
import CardSwipeTotalByEvents from './pages/event/CardSwipeTotalByEvents';
import OdometerByEvents from './pages/event/OdometerByEvents';
import LogEvents from './pages/event/LogEvents';
import SpeedChartMonth from './pages/chart/SpeedChartMonth';
import OverspeedByDateTwo from './pages/event/OverspeedByDateTwo';
import { listTablesAtom } from './pages/route/markerAtom';
import PoiLogByDevice from './pages/event/PoiLogByDevice';
import MainDashboards from './pages/test/MainDashboard';
import MainDashboard from './pages/NewHome/MainDashboard';
import TheNewMain from './pages/TheNew/TheNewMain';
import PoiLogByGroup from './pages/event/PoiLogByGroup';
import LogEventsByGroup from './pages/event/LogEventsByGroup';
import MainWork from './pages/work/MainWork';
import MainPermission from './pages/permission/MainPermission';
import PermissionsGate from './pages/permission/PermissionsGate';
import { PERMISSIONSPAGE, ROLESPAGE, SCOPES, SCOPESPAGE } from './pages/permission/permission-maps';
import { ACCOUNTS_PERMISSION, SCOPES_CRUD } from './pages/permission/SCOPESPAGES';
import { usePermission } from './pages/permission/fnPermission';
// import Profile from './pages/Profile';
// import MainPoi from './pages/poi_x/MainPoi';
import MainPoi from './pages/poi/MainPoi';

import MainPoiOld from './pages/poi_x/MainPoi';
import PrintDltCerDate from './pages/dlt/PrintDltCerDate';
import MapGl from './pages/mapGl/MapGl';
import EvSummary from './pages/summary/EvSummary';
import MainDrivers from './pages/drivers/MainDrivers';
import NewDriver from './pages/drivers/NewDriver';


import NotMainBooking from './pages/bookings/NotMainBooking';



import HistoryMap from './pages/history/HistoryMap';
import AccountForm from './pages/accountsForm/AccountForm';
import CustomerForm from './pages/accountsForm/CustomerForm';
import DriverForm from './pages/accountsForm/DriverForm';
import GuideForm from './pages/accountsForm/GuideForm';
import Offlinedevice from './pages/event/Offlinedevice';
// import VTwoTest from './pages/accountsForm/VTwoTest';
// import CreateBooking from './pages/accountsForm/CreateBooking';
// import UpdateBooking from './pages/accountsForm/UpdateBooking';
import WorkType from './pages/accountsForm/work/WorkType';
import CreateWorkType from './pages/accountsForm/work/CreateWorkType';
import SubWorkType from './pages/accountsForm/work/SubWorkType';
import CarsType from './pages/accountsForm/cars/CarsType';
import Customer from './pages/accountsForm/customers/Customer';
import Contypes from './pages/accountsForm/contypes/Contypes';
import TMainAccounts from './pages/testaccounts/TMainAccounts';
import Concode from './pages/accountsForm/concode/Concode';
import AccountsByConType from './pages/testaccounts/AccountsByConType';

// Booking



import MainBooking from './pages/mainbooking/MainBooking';
import CreateBooking from './pages/mainbooking/CreateBooking';
import UpdateBooking from './pages/mainbooking/UpdateBooking';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const { closeSnackbar } = useSnackbar();
  const listTables = useResetRecoilState(listTablesAtom)





  useEffect(() => {
    closeSnackbar()
    listTables()
  }, [location, closeSnackbar, listTables])

  // const aesCbc = UseAesCbc();
  const account = useRecoilValue(userAtom);
  const permissions = useRecoilValue(permissionAtom);
  const listModel = useRecoilValue(listModelAtom);
  const dltVender = useRecoilValue(dltVenderAtom)
  const listVehicleType = useRecoilValue(listVehicleTypeAtom)
  const listProvince = useRecoilValue(listProvinceAtom)
  const listVehicleRegisterType = useRecoilValue(listVehicleRegisterTypeAtom)
  const userId = localStorage.getItem('userId')
  // console.log('account', account)

  // const actionsPermission = usePermission();
  // useEffect(() => {
  //   actionsPermission.getAndSet()
  // }, [])

  return useRoutes([
    // {
    //   path: '/',
    //   element: <PrivateRoute><NoneLayout /></PrivateRoute>,
    //   children: [
    //     // { index: true, element: <Navigate to="/dashboard/map" replace /> },
    //     // { index: true, element: <Map userId={userId} />, },
    //     { index: true, element: <DashboardMapGroup userId={userId} account={account} />, },
    //   ]
    // },
    {
      path: '/',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        { index: true, element: <MainDashboard account={account} userId={userId} /> }
      ]
    },
    {
      path: '/',
      element: <NoneLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgotpassword', element: <Login /> },
        { path: 'register', element: <Register /> },
      ]
    },
    {
      path: '/report',
      element: <PrivateRoute><ReportLayout /></PrivateRoute>,
      children: [
        { index: true, element: <Navigate to="/report/route" replace /> },
        { path: 'route', element: <NoPermission message={'No Permission'} /> },
        { path: 'event', element: <EventMain /> },
        { path: 'event/overspeedbydevice', element: <OverspeedByDevice account={account} userId={userId} /> },
        { path: 'event/overspeedbygroup', element: <OverspeedByGroup account={account} userId={userId} /> },
        { path: 'event/overspeedbydate', element: <OverspeedByDate account={account} userId={userId} /> },
        { path: 'event/overspeedbydate2', element: <OverspeedByDateTwo account={account} userId={userId} /> },
        { path: 'event/cardswipe', element: <CardSwipe account={account} userId={userId} /> },
        { path: 'event/cardswipebygroup', element: <CardSwipeByGroup account={account} userId={userId} /> },
        { path: 'event/cardswipetotalbydate', element: <CardSwipeTotalByEvents account={account} userId={userId} /> },
        { path: 'event/odometerbydate', element: <OdometerByEvents account={account} userId={userId} /> },
        { path: 'event/logevents', element: <LogEvents account={account} userId={userId} /> },
        { path: 'event/logeventsbygroup', element: <LogEventsByGroup account={account} userId={userId} /> },
        { path: 'event/poilogbydevice', element: <PoiLogByDevice account={account} userId={userId} /> },
        { path: 'event/poilogbygroup', element: <PoiLogByGroup account={account} userId={userId} /> },
        { path: 'event/offlinedevice', element: <Offlinedevice account={account} userId={userId} /> },

        { path: 'trip', element: <NoPermission message={'No Permission'} /> },
        { path: 'stop', element: <NoPermission message={'No Permission'} /> },
        { path: 'ev-summary', element: <EvSummary account={account} userId={userId} /> },
        {
          path: 'chart', element: <MainChart />
        },
        { path: 'chart/speed', element: <SpeedChart account={account} userId={userId} /> },
        { path: 'chart/speedmonth', element: <SpeedChartMonth account={account} userId={userId} /> },
        { path: 'log', element: <Log account={account} userId={userId} /> },
        { path: 'dlt', element: account?.contype === '00' || userId === "sit" ? <Dlt account={account} /> : <NoPermission message={'No Permission'} /> },
        { path: 'dlt_cer', element: account?.contype === '00' || userId === "sit" ? <DltCer account={account} /> : <NoPermission message={'No Permission'} /> },
        {
          path: 'work', element: <MainWork />
        }
      ]
    },
    {
      path: '/settings',
      element: <PrivateRoute><SettingsLayout /></PrivateRoute>,
      children: [
        { index: true, element: <Navigate to="/settings/preferences" replace /> },
        { path: 'preferences', element: <NoPermission message={'No Permission'} /> },
        // {path: 'routes', element: <MainRoutes /> },
        // {path: 'routes/:route_no', element: <MainRouteTrips /> },
        // {path: 'routes/:route_no/new', element: <UpdateRouteTrips /> },
        // {path: 'routes/:route_no/:sub_route_no', element: <UpdateRouteTrips /> },
        { path: 'routes', element: <NoPermission message={'No Permission'} /> },
        { path: 'groups', element: <Groups userId={userId} account={account} /> },
        { path: 'groups/new', element: <GroupsNew userId={userId} account={account} /> },
        { path: 'groups/edit', element: <GroupsEdit userId={userId} account={account} /> },


        { path: 'notifications', element: <NoPermission message={'No Permission'} /> },

        // { path: 'accounts', element: <MainAccount account={account} /> },
        // { path: 'accounts/account', element: <NewAccount account={account} /> },
        // { path: 'accounts/account/:id', element: <EditAccount account={account} /> },

        // {
        //   path: 'accounts', element:
        //     <PermissionsGate
        //       RenderError={() => <NoPermission message={'No Permission'} />}
        //       permissions={permissions["account"]}
        //       scopes={ACCOUNTS_PERMISSION.actions.map(p => p.value)}
        //     >
        //       <MainAccount account={account} />
        //     </PermissionsGate>
        // },
        // {
        //   path: 'accounts/new', element:
        //     <PermissionsGate
        //       RenderError={() => <NoPermission message={'No Permission'} />}
        //       permissions={permissions["account"]}
        //       scopes={[SCOPES_CRUD.canCreate]}
        //     >
        //       <NewAccount account={account} />
        //     </PermissionsGate>
        // },
        // {
        //   path: 'accounts/edit', element: <PermissionsGate
        //     RenderError={() => <NoPermission message={'No Permission'} />}
        //     permissions={permissions["account"]}
        //     scopes={[SCOPES_CRUD.canEdit, SCOPES_CRUD.canDelete]}
        //   >
        //     <EditAccount account={account} />
        //   </PermissionsGate>
        // },


        { path: 'accounts', element: account?.contype === '00' ? <MainAccount account={account} /> : <NoPermission message={'No Permission'} /> },
        { path: 'accounts/new', element: account?.contype === '00' ? <NewAccount account={account} /> : <NoPermission message={'No Permission'} /> },
        { path: 'accounts/edit', element: account?.contype === '00' ? <EditAccount account={account} /> : <NoPermission message={'No Permission'} /> },



        // { path: 'accounts', element: <MainAccount account={account} /> },
        // { path: 'accounts/new', element: <NewAccount account={account} /> },
        // { path: 'accounts/edit', element: <EditAccount account={account} /> },


        { path: 'devices', element: <MainDevice account={account} /> },
        { path: 'devices/device/:id', element: < EditDeviceMain account={account} listModel={listModel} dltVender={dltVender} listVehicleType={listVehicleType} listProvince={listProvince} listVehicleRegisterType={listVehicleRegisterType} /> },
        { path: 'devices/device', element: account?.contype === '00' ? <NewDevice account={account} listModel={listModel} dltVender={dltVender} listVehicleType={listVehicleType} listProvince={listProvince} listVehicleRegisterType={listVehicleRegisterType} /> : <NoPermission message={'No Permission'} /> },

        { path: 'drivers', element: <NoPermission message={'No Permission'} /> },
        // { path: 'drivers', element: <MainDrivers /> },
        { path: 'drivers/new', element: <NewDriver /> },
        { path: 'calendars', element: <NoPermission message={'No Permission'} /> },
        { path: 'attributes', element: <NoPermission message={'No Permission'} /> },
        { path: 'maintenances', element: <NoPermission message={'No Permission'} /> },
        { path: 'commands', element: <NoPermission message={'No Permission'} /> },
        // { path: 'booking', element: <MainBooking /> },
        {
          path: 'geofences/geofence/:id', element:
            account?.contype === '00' || userId === "sit" ?
              <GeofencesForm account={account} />
              : <NoPermission message={'No Permission'} />
        },
        {
          path: 'geofences/geofence', element:
            account?.contype === '00' || userId === "sit" ?
              <GeofencesNew account={account} />
              : <NoPermission message={'No Permission'} />
          // <GeofencesNew account={account} />
        },
        { path: 'customers', element: <Customer account={account} /> },
        { path: 'contypes', element: <Contypes account={account} /> },
        { path: 'concodes', element: <Concode account={account} /> },
        { path: 'test/accounts', element: <TMainAccounts account={account} /> },
        { path: 'test/accounts/:id', element: <AccountsByConType account={account} /> },

        // {
        //   path: 'permission', element:
        //     <PermissionsGate
        //       RenderError={() => <NoPermission message={'No Permission'} />}
        //       // permissions={[SCOPES.canView]}
        //       permissions={permissions["account"]}
        //       scopes={SCOPESPAGE[ROLESPAGE.account]}
        //     >
        //       <MainPermission />
        //     </PermissionsGate>
        // }

        {
          path: 'permission', element: <MainPermission />
        }
      ]
    },
    {
      path: '/account',
      element: <PrivateRoute><AccountLayout /></PrivateRoute>,
      children: [
        // {index: true, element: <Navigate to="/" replace /> },
        { index: true, element: <Navigate to="/account/profile" replace /> },
        { path: 'profile', element: <SettingsProfile account={account} /> },
        { path: 'changepass', element: <ChangepassProfile account={account} userId={userId} /> },
      ]
    },
    {
      path: '/report/replay',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        { index: true, element: <Replay account={account} userId={userId} /> },
      ]
    },
    {
      path: '/report/events',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        { index: true, element: <Events account={account} userId={userId} /> },
      ]
    },
    {
      path: '/settings/geofences',
      element: <PrivateRoute>{account?.contype === '00' || userId === "sit" ? <NoneLayout /> : <SettingsLayout />}</PrivateRoute>,
      children: [
        // {index: true, element: <Geofences account={account} /> },
        { index: true, element: account?.contype === '00' || userId === "sit" ? <Geofences account={account} /> : <NoPermission message={'No Permission'} /> },
        // {path: ':id', element: <SettingsLayout><Geofences /></SettingsLayout> },
      ]
    },
    // {
    //   path: '/settings/booking',
    //   element: <PrivateRoute><SettingsLayout /></PrivateRoute>,
    //   children: [
    //     { path: 'account', element: <AccountForm account={account} /> },
    //     { path: 'customer', element: <CustomerForm account={account} /> },
    //     { path: 'driver', element: <DriverForm account={account} /> },
    //     { path: 'guide', element: <GuideForm account={account} /> },
    //     { path: 'test', element: <VTwoTest /> },
    //     { path: 'test/create', element: <CreateBooking /> },
    //     { path: 'test/update', element: <UpdateBooking /> },
    //     { path: 'test/worktype', element: <WorkType /> },
    //     { path: 'test/worktype/create', element: <CreateWorkType /> },
    //     { path: 'test/worktype/sub', element: <SubWorkType /> },
    //     { path: 'test/cars', element: <CarsType /> },
    //     { path: 'test/customer', element: <Customer /> }
    //   ]
    // },
    // {
    //   path: '/settings/poi',
    //   element: <PrivateRoute>
    //     <PermissionsGate
    //       RenderError={() => <SettingsLayout />}
    //       permissions={permissions["poi"]}
    //       scopes={[SCOPES_CRUD.canRead]}
    //     >
    //       <NoneLayout />
    //     </PermissionsGate>

    //   </PrivateRoute>,
    //   children: [
    //     {
    //       index: true, element:
    //         <PermissionsGate
    //           RenderError={() => <NoPermission message={'No Permission'} />}
    //           permissions={permissions["poi"]}
    //           scopes={[SCOPES_CRUD.canRead]}
    //         >
    //           <MainPoi account={account} permissions={permissions["poi"]} />
    //         </PermissionsGate>
    //     }
    //   ]
    // },
    {
      path: '/settings/poi',
      element: <PrivateRoute>
        <NoneLayout />
      </PrivateRoute>,
      children: [
        {
          index: true, element:
            <MainPoiOld account={account} />
        }
      ]
    },
    {
      path: '/log',
      element: <PrivateRoute><DashboardLayout /></PrivateRoute>,
      children: [
        { index: true, element: account?.contype === '00' ? <Log /> : <NoPermission message={'No Permission'} /> }
      ]
    },
    {
      path: '/report/dlt_cer/print/:id',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        { index: true, element: <PrintDltCer account={account} /> }

        // { index: true, element: account?.contype === '00' || userId === "sit" ? <PrintDltCer account={account} /> : <NoPermission message={'No Permission'} /> }
      ]
    },
    {
      path: '/report/dlt_cer/print_dltdate/:dltdate',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        { index: true, element: <PrintDltCerDate account={account} /> }

        // { index: true, element: account?.contype === '00' || userId === "sit" ? <PrintDltCer account={account} /> : <NoPermission message={'No Permission'} /> }
      ]
    },
    {
      path: '/settings/devices/print/:id',
      element: <PrivateRoute><NoneLayout /></PrivateRoute>,
      children: [
        { index: true, element: <PrintDltCerByChassis account={account} /> }
      ]
    },
    {
      path: '/test/dashboard',
      element: <NoneLayout />,
      children: [
        { index: true, element: <MainDashboards account={account} userId={userId} /> }
      ]
    },
    {
      path: '/test/dashboard-1',
      element: <NoneLayout />,
      children: [
        { index: true, element: <TheNewMain account={account} userId={userId} /> }
      ]
    },
    {
      path: '/mapgl',
      element: <NoneLayout />,
      children: [
        { index: true, element: <MapGl /> },
      ]
    },
    {
      path: '/history',
      element: <NoneLayout />,
      children: [
        { index: true, element: <HistoryMap /> },
      ]
    },
    {
      path: '/booking',
      element: <PrivateRoute><SettingsLayout /></PrivateRoute>,
      children: [
        { index: true, element: <MainBooking account={account} /> },
        { path: 'create', element: <CreateBooking /> },
        { path: 'update', element: <UpdateBooking /> },
        { path: 'worktype', element: <WorkType /> },
        { path: 'worktype/create', element: <CreateWorkType /> },
        { path: 'worktype/sub', element: <SubWorkType /> },
        { path: 'cars', element: <CarsType /> },
        { path: 'customer', element: <Customer /> }
      ]
    },
    {
      path: '/404',
      element: <NoneLayout />,
      children: [
        { index: true, element: <>404</> },
      ]
    },
    // {path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

const NoPermission = ({ message }) => {
  return (
    <Typography textAlign="center" variant="h4" marginTop="20px"> {message}</Typography>
  )
}