import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------
export function fDateTo(date, f) {
  return format(new Date(date), f);
}
export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}
export function fDateTimeThai(date) {
  return format(new Date(date), 'dd-MM-yyyy HH:mm');
}
export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
