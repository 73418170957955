import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// import { getComparator, stableSort } from './GetComparator';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

import { Api } from '../../components/api';
import { useState } from 'react';
import { getComparator, stableSort } from '../poi_x/GetComparator';
import _ from 'lodash';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        // minWidth: "80vw",
        // maxWidth: "80vw",
        minHeight: "40vh",
        maxHeight: "40vh"
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function PoiDialogs({ open, handleClose, onChanges, cConame }) {
    const [listGroupPoi, setListGroupPoi] = useState([]);
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);

    React.useEffect(() => {
        // handleOpenBackdrop()
        Api.get(`/Tracking/poigeocodegroups?cname=${cConame}`)
            // Api.get(`/Tracking/poigeocodegroups?cname=FIRST=${cocode}`)
            .then(res => {
                setListGroupPoi(res.data)
                // handleCloseBackdrop()
            }
            )
    }, [])


    const onClickSelect = (group_id) => {
        setLoading(true)
        // handleOpenBackdrop()
        Api.get(`/Tracking/poigeocodegroup?cname=${cConame}&group_id=${group_id}`)
            .then(
                (res) => {
                    const poi_id = res.data.map(e => e.id)
                    const req = {
                        "cname": cConame,
                        "poi_id": poi_id
                    }
                    Api.post(`/Tracking/poigeocodelist`, req)
                        .then(res => {
                            // console.log('res.data', res.data)
                            onChanges((poi) => poi.filter(p => p.group_id !== group_id).concat(res.data))
                            setGroups((group) => group.filter(g => g !== group_id).concat(group_id))
                            setLoading(false)
                            handleClose()
                            // setListPoiShow(res.data)
                            // setListPoiShow(e => e.filter(p => p.group_id !== group_id).concat(res.data))
                            // handleCloseBackdrop()
                        }
                        ).catch((e) => {
                            setLoading(false)
                        })
                }
            ).catch((e) => {
                setLoading(false)
            })
    }

    const onClickUnSelect = (group_id) => {
        onChanges((poi) => poi.filter(p => p.group_id !== group_id))
        setGroups((group) => group.filter(g => g !== group_id))
        setLoading(false)
        handleClose()
    }
    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                POI
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{
                minWidth: "350px",
                // minHeight: "800px"
            }}>
                {
                    _.map(stableSort(listGroupPoi, getComparator("asc", "name")), (group, id_group) => (
                        <Box key={"geoGroup" + id_group}>
                            <Box
                                sx={{
                                    marginTop: "5px",
                                    marginBottom: "5px",
                                    cursor: loading ? "wait" : "pointer"
                                }}
                                onClick={
                                    () => {
                                        if (groups.filter(g => g === group.group_id).length > 0) {
                                            onClickUnSelect(group.group_id)
                                        } else {
                                            onClickSelect(group.group_id)
                                        }
                                    }
                                }
                            >
                                <Stack direction="row" alignItems="center" gap={1}>
                                    {groups.filter(g => g === group.group_id).length > 0 && (<CheckBoxOutlinedIcon />)}
                                    <Typography variant="subtitle2"  > {group.name}</Typography>
                                </Stack>
                            </Box>
                            {/* <PoiComponent poiByGroup={stableSort(filterPoi(group.group_id), getComparator("asc", "poi_name"))} checkMode={checkMode} onEdited={onEdited} onDeletePoi={onDeletePoi} map={map} id={`scrollablePoi${id_group}`} /> */}
                            <Divider sx={{ borderBottomWidth: '2px' }} />
                        </Box>
                    ))
                }
            </DialogContent>
            {/* <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Save changes
                </Button>
            </DialogActions> */}
        </StyledDialog>
    );
}