import styled from '@emotion/styled';
import { Container, Divider, Grid, TextField, Typography, Stack, Button } from '@mui/material'
import { Box } from '@mui/system'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react'
import { Api } from '../../components/api';
import Page from '../../components/Page'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { Link } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { fDateTo } from '../../utils/formatTime';

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function DltCer() {

    const [mode, setMode] = useState("docno")

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const DeviceSchema = Yup.object().shape({

        docno: mode === "docno" ? Yup.string().required('Docno is required').nullable() : Yup.string().notRequired(),
        dltdate: mode === "dltdate" ? Yup.string().required('dltdate is required').nullable() : Yup.string().notRequired(),

    })
    const formik = useFormik({
        initialValues: {
            docno: '',
            dltdate: new Date(),
        },
        validationSchema: DeviceSchema,
        onSubmit: (values, { setSubmitting }) => {
            // Link({ to: `/report/dlt_cer/print/${values.docno}`, target: "_blank" })
            setSubmitting()
            if (mode === "docno") {
                window.open(`/report/dlt_cer/print/${values.docno}`, "_blank")
            } else {
                window.open(`/report/dlt_cer/print_dltdate/${fDateTo(values.dltdate, "yyyy-MM-dd")}`, "_blank")
            }
            // setSubmitting(false)
            // return <Link to={`/report/dlt_cer/print/${values.docno}`} target="_blank" /> 
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;






    return (
        <Page title="DLT certificate">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="h4">DLT certificate </Typography>
                        <Button onClick={() => { setMode("docno") }} variant={mode === "docno" ? "contained" : "outlined"}> docno </Button>
                        <Button onClick={() => { setMode("dltdate") }} variant={mode === "dltdate" ? "contained" : "outlined"}> dltdate </Button>
                    </Stack>
                </Box>
                <Divider />
                <MainStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                                <Box width={400} margin="auto" padding={2}
                                // border={1} borderRadius={1}
                                >
                                    {
                                        mode === "docno" && (
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            label="docno"
                                                            {...getFieldProps('docno')}
                                                            error={Boolean(touched.docno && errors.docno)}
                                                            helperText={touched.docno && errors.docno}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} justifyContent="center">
                                                        <LoadingButton
                                                            fullWidth
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            color="info"
                                                            loading={isSubmitting}
                                                        >
                                                            Search
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                    {
                                        mode === "dltdate" && (
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={10}>
                                                        <MobileDatePicker
                                                            ampm={false}
                                                            // disablePast={true}
                                                            label="วันที่"
                                                            allowKeyboardControl={false}
                                                            value={values.dltdate}
                                                            inputFormat="dd/MM/yyyy"
                                                            format="dd/MM/yyyy"
                                                            onChange={(val) => {
                                                                setFieldValue('dltdate', val);
                                                                // }
                                                            }}
                                                            renderInput={(params) => <TextField
                                                                size="small"
                                                                {...params}
                                                                {...getFieldProps('dltdate')}
                                                                fullWidth
                                                                placeholder="DD/MM/YYYY"
                                                                autoComplete="off"
                                                                error={Boolean(touched.dltdate && errors.dltdate)}
                                                                helperText={touched.dltdate && errors.dltdate} />}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2} justifyContent="center">
                                                        <LoadingButton
                                                            fullWidth
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            color="info"
                                                            loading={isSubmitting}
                                                        >
                                                            Search
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </MainStyle>
            </Container>
        </Page>
    )
}
