export const SCOPES_CRUD = {
    canCreate: "can-create",
    canEdit: "can-edit",
    canDelete: "can-delete",
    canRead: "can-read"
};
export const SCOPES_POI = {
    canCreateCircle: "can-create-circle",
    canCreateRectangle: "can-create-rectangle",
    canCreatePolyline: "can-create-polyline",
    canCreatePolygon: "can-create-polygon"
};


export const ACCOUNTS_PERMISSION = {
    title: "ACCOUNTS",
    name: "ACCOUNTS",
    actions: [{ name: "Read", value: SCOPES_CRUD.canRead }, { name: "Create", value: SCOPES_CRUD.canCreate }, { name: "Edit", value: SCOPES_CRUD.canEdit }, { name: "Delete", value: SCOPES_CRUD.canDelete }]
}