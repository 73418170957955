// const DrawControl = ({ editMode, setEditMode, editRef, drawCircle }) => {

import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { Box, IconButton, Grid, TextField, MenuItem, Button } from '@mui/material';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { Api } from '../../components/api';
import { useSnackbar } from 'notistack';

export default function CreatePOI({
    handleEditData,
    listGroupPoi,
    setHandleEditData,
    setPoiSelect,
    setListPoiShow,
    handleOpenBackdrop,
    handleCloseBackdrop,
    onGetPoiGroup,
    listPoiShow
}) {
    const { enqueueSnackbar } = useSnackbar();
    const handleChange = (e) => {
        const { name, value } = e.target
        const dataHand = {
            ...handleEditData,
            [name]: value
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)

    }

    const handleChangeRadius = (e) => {
        const { name, value } = e.target

        const dataHand = {
            ...handleEditData,
            properties: {
                ...handleEditData?.properties,
                [name]: value.replace(/[^0-9]/g, ''),

            }
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)
    }

    const handleChangeLat = (e) => {
        const { name, value } = e.target
        const dataHand = {
            ...handleEditData,
            geometry: {
                ...handleEditData?.geometry,
                coordinates: [handleEditData.geometry.coordinates[0], value],

            }
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)

    }
    const handleChangeLng = (e) => {
        const { name, value } = e.target
        const dataHand = {
            ...handleEditData,
            geometry: {
                ...handleEditData?.geometry,
                coordinates: [value, handleEditData.geometry.coordinates[1]],

            }
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)

    }

    const onClose = () => {
        setHandleEditData(null)
        setPoiSelect(null)
    }

    const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

    const handleSubmit = e => {
        e.preventDefault();

        const req = {
            "cname": handleEditData?.cname,
            "poi_id": handleEditData?.poi_id,
            "poi_name": handleEditData?.poi_name,
            "group_id": handleEditData?.group_id,
            "poi_data": {
                "type": "Feature",
                "geometry": {
                    "type": capitalize(handleEditData?.properties.shape),
                    "coordinates": handleEditData?.properties.shape === "circle" ? [handleEditData?.geometry.coordinates] : handleEditData?.geometry.coordinates
                },
                "properties": {
                    ...handleEditData?.properties
                    // "radius": handleEditData?.properties.radius,
                    // "shape": handleEditData?.properties.shape
                }
            }
        }
        handleOpenBackdrop()
        Api.post(`/Tracking/poigeocode`, req)
            .then(res => {
                const hand = listPoiShow.filter(v => v.group_id === req.group_id)
                if (hand.length === 0) {
                    onGetPoiGroup(req.group_id)
                    onClose()
                } else {
                    setListPoiShow(l => l.concat(res.data))
                    onClose()
                    handleCloseBackdrop()
                }



            }
            ).catch(error => {
                handleCloseBackdrop()
                // enqueueSnackbar(`Failed to create POI !`, { variant: "error" });
                enqueueSnackbar(`Failed to create POI !`, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" }, autoHideDuration: 500 })
            })
    };

    const ApiPost = (req) => {
        Api.post(`/Tracking/poigeocode`, req)
            .then(res => {
                const hand = listPoiShow.filter(v => v.group_id === req.group_id)
                if (hand.length === 0) {
                    onGetPoiGroup(req.group_id)
                    onClose()
                } else {
                    setListPoiShow(l => l.concat(res.data))
                    onClose()
                    handleCloseBackdrop()
                }



            }
            ).catch(error => {
                handleCloseBackdrop()
                // enqueueSnackbar(`Failed to create POI !`, { variant: "error" });
                enqueueSnackbar(`Failed to create POI !`, { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "center" }, autoHideDuration: 500 })
            })
    };


    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).replaceAll(' ', '').toUpperCase();
    };
    return (
        <Box
            sx={{
                zIndex: 2,
                position: "absolute",
                top: "10px",
                // textAlign: "center",
                // flexDirection: "row",
                // display: "flex",
                // width: "250px",
                right: "40px",
                backgroundColor: "white",
                borderRadius: "5px"

            }}
        >
            <Box sx={{
                padding: "10px"
            }}>
                <form onSubmit={handleSubmit} autoComplete="off" >
                    <Grid container spacing={1} direction="column">
                        <Grid item sm={12} >
                            <TextField size="small" onChange={handleChange} name="poi_id" value={handleEditData?.poi_id} fullWidth label="ID" InputLabelProps={{ shrink: true }}
                                // helperText={handleEditData?.poi_id === undefined || handleEditData?.poi_id === '' ? "required" : ''}
                                // FormHelperTextProps={{
                                //     sx: {
                                //         color: "red"
                                //     }
                                // }}
                                // error={handleEditData?.poi_id === undefined}
                                // required
                                // helperText={handleEditData?.poi_id === '' ? "required" : ''}
                                required
                                onInput={toInputUppercase}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField size="small" onChange={handleChange} name="poi_name" value={handleEditData?.poi_name} fullWidth label="ชื่อ" InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField select size="small" onChange={handleChange} name="group_id" value={handleEditData?.group_id} fullWidth label="กลุ่ม" InputLabelProps={{ shrink: true }}
                                required
                            >
                                {
                                    listGroupPoi.map((value, id) => (
                                        <MenuItem value={value.group_id} key={"G" + id}>{value.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        {handleEditData?.properties.shape === "circle" && (
                            <>
                                <Grid item sm={12}>
                                    <TextField size="small" onChange={handleChangeLat} name="lat" value={handleEditData?.geometry.coordinates[1]} fullWidth label="lat" InputLabelProps={{ shrink: true }}
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField size="small" onChange={handleChangeLng} name="lng" value={handleEditData?.geometry.coordinates[0]} fullWidth label="lng" InputLabelProps={{ shrink: true }}
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField size="small" onChange={handleChangeRadius} name="radius" value={handleEditData?.properties.radius} fullWidth label="radius(m)" InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                </Grid>
                            </>
                        )
                        }
                        < Box
                            sx={{
                                // p: 1,
                                // m: 1,
                                // bgcolor: 'background.paper',
                                // borderRadius: 1,
                                // borderBottomStyle: "solid",
                                // borderBottomColor: "GrayText",
                                // borderBottomWidth: "0.5px",
                                display: 'flex',
                                justifyContent: "space-between",
                                padding: "10px",
                                marginLeft: "5px"
                            }}
                        >

                            <Button variant="contained" type="submit" color="success" fullWidth style={{ marginRight: "5px" }}>
                                Save
                            </Button>
                            <Button variant="contained" color="error" fullWidth style={{ marginLeft: "5px" }}
                                onClick={onClose}
                            >
                                Close
                            </Button>

                        </Box>
                    </Grid>
                </form>
            </Box>
        </Box >
    )
}