import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography, IconButton, Divider, TextField } from '@mui/material';
import { getComparator, stableSort } from './GetComparator';


import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import AddBoxIcon from '@mui/icons-material/AddBox';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Virtuoso } from 'react-virtuoso';

export default function ControlSide({ onOpenCreateGroup, listGroupPoi, onEditGroup, listPoiShow, setListPoiShow, onGetPoiGroup, setModeFun, setHandleEditData, setPoiSelect, map, handleClickDel, handleClickDelGroup, onHandleChange }) {


    const filterPoi = (group_id) => {
        const hand = _.filter(listPoiShow, (v => v.group_id === group_id))
        return hand
    }

    const onEdited = (select) => {
        setPoiSelect(select)
        setHandleEditData(select)
        setModeFun('edit')
    }


    const onDeletePoi = (select) => {
        setPoiSelect(null)
        setHandleEditData(null)
        handleClickDel(select)
        // setModeFun('edit')
    }
    const onDeleteGroup = (select) => {
        setPoiSelect(null)
        setHandleEditData(null)
        handleClickDelGroup(select)
        // setModeFun('edit')
    }

    const checkMode = () => {
        setPoiSelect(null)
        setHandleEditData(null)
    }


    const IconButtonGetGroup = (group_id) => {

        const hand = listPoiShow.filter(v => v.group_id === group_id)
        return hand.length > 0 ?
            <>
                <IconButton
                    onClick={() => {
                        setPoiSelect(null)
                        setHandleEditData(null)
                        onGetPoiGroup(group_id)
                    }}
                    size="small"
                >
                    <RefreshOutlinedIcon fontSize='10px' />
                </IconButton>
                <IconButton
                    onClick={() => {
                        setPoiSelect(null)
                        setHandleEditData(null)
                        setListPoiShow(e => e.filter(p => p.group_id !== group_id))
                    }}
                    size="small"
                >
                    <VisibilityOffOutlinedIcon fontSize='10px' />
                </IconButton>
            </>
            :
            <IconButton
                onClick={() => {
                    setPoiSelect(null)
                    setHandleEditData(null)
                    onGetPoiGroup(group_id)
                }}
                size="small"
            >
                <VisibilityOutlinedIcon fontSize='10px' />
            </IconButton>
    }

    const [filterSearch, setfilterSearch] = useState("");

    useEffect(() => {
        const timeOutId = setTimeout(() => onHandleChange(filterSearch), 500);
        return () => clearTimeout(timeOutId);
    }, [filterSearch]);

    const handleChange = (e) => {
        const { value } = e.target
        setfilterSearch(value)

    }


    // slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) 0
    // slice(page * 100, page * 100 + 100) 0
    return (
        <Box sx={{
            paddingLeft: "0px",
            paddingRight: "0px"
        }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    // p: 1,
                    // m: 1,
                    // bgcolor: 'background.paper',
                    // borderRadius: 1,
                }}
            >
                <Box>
                    <Typography variant="subtitle1"  >Group</Typography>
                </Box>
                <Box>
                    <IconButton
                        onClick={onOpenCreateGroup}
                    >
                        <AddBoxIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box
                sx={{
                    paddingBottom: "15px"
                }}
            >
                <TextField
                    placeholder='Search POI'
                    fullWidth
                    size="small"
                    inputProps={{
                        style: {
                            height: "15px",
                        },
                    }}
                    name="search"
                    onChange={handleChange}
                    autoComplete="off"
                />
            </Box>
            <Box>
                {
                    _.map(stableSort(listGroupPoi, getComparator("asc", "name")), (group, id_group) => (
                        <Box key={"geoGroup" + id_group}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box
                                    sx={{
                                        marginTop: "5px",
                                        marginBottom: "5px"
                                    }}
                                >
                                    <Typography variant="subtitle2"  >{group.name}</Typography>
                                </Box>
                                <Box>
                                    {
                                        IconButtonGetGroup(group.group_id)
                                    }
                                    <IconButton
                                        onClick={() => {
                                            onEditGroup(group)
                                        }}
                                        size="small"
                                    >
                                        <ModeEditSharpIcon fontSize='10px' />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            onDeleteGroup(group)
                                        }}
                                        size="small"
                                    >
                                        <DeleteForeverOutlinedIcon fontSize='10px' />
                                    </IconButton>
                                </Box>
                            </Box>
                            <PoiComponent poiByGroup={stableSort(filterPoi(group.group_id), getComparator("asc", "poi_name"))} checkMode={checkMode} onEdited={onEdited} onDeletePoi={onDeletePoi} map={map} id={`scrollablePoi${id_group}`} />
                            <Divider sx={{ borderBottomWidth: '2px' }} />
                        </Box>
                    ))
                }
            </Box>
        </Box >
    )
}



const PoiComponent = ({ poiByGroup, onDeletePoi, map, onEdited, checkMode, id }) => {
    const [page, setPage] = useState(0)

    const [items, setItems] = useState(poiByGroup.slice(0, 50))

    useEffect(() => {
        setItems(poiByGroup.slice(0, 50))

        return () => {
            setPage(0)
        }
    }, [poiByGroup])


    const loadMore = () => {
        setTimeout(() => {
            const isPage = page + 1;
            setPage(isPage)
            setItems(i => i.concat(poiByGroup.slice(isPage * 50, isPage * 50 + 50)))
        }, 500);

        // console.log('loadMore')
    }

    const itemsPOI = poiByGroup || []
    return (
        <Box
            // sx={
            //     {
            //         maxHeight: "300px",
            //         overflow: "auto",
            //         "::-webkit-scrollbar": {
            //             width: "3px"
            //         },
            //         "::-webkit-scrollbar-thumb": {
            //             background: "#00000038",
            //             borderRadius: "4px"
            //         }
            //     }
            // }
            id={id}
            key={id}
        >
            <Virtuoso
                style={{
                    height: items.length * 28 >= 300 ? `300px` : `${items.length * 28}px`,
                    // maxHeight: "300px",
                    // overflow: "auto",
                    // "::-webkit-scrollbar": {
                    //     width: "3px"
                    // },
                    // "::-webkit-scrollbar-thumb": {
                    //     background: "#00000038",
                    //     borderRadius: "4px"
                    // }
                }}
                // style={{ height: items.length * 28 >= 300 ? `calc(100vh - 280px)` : `${items.length * 28}px` }}
                data={itemsPOI}
                itemContent={(index, data) => {
                    return (
                        < Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            key={"aoi" + index}
                        >
                            <Typography
                                variant="inherit"
                                style={{
                                    cursor: "pointer",
                                    display: 'flex',
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: '13rem'
                                }}
                                noWrap={true}
                                textOverflow={"ellipsis"}
                                onClick={() => {
                                    checkMode()
                                    if (data.poi_data?.geometry.type === "Circle") {
                                        map.setView([data.poi_data.geometry.coordinates[0][1], data.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                    if (data.poi_data?.geometry.type === "Rectangle") {
                                        map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        }), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                    if (data.poi_data?.geometry.type === "Polyline") {
                                        map.fitBounds(data.poi_data.geometry.coordinates.map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        }), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                    if (data.poi_data?.geometry.type === "Polygon") {
                                        map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        }), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }

                                }}
                            >
                                {data.poi_name}
                            </Typography>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        if (data.poi_data?.geometry.type === "Circle") {
                                            const select = {
                                                "cname": data.cname,
                                                "poi_id": data.poi_id,
                                                "poi_name": data.poi_name,
                                                "group_id": data.group_id,
                                                "poi_data": data.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    "radius": data.poi_data.properties.radius,
                                                    "shape": "circle"
                                                },
                                                "geometry": {
                                                    "type": "Point",
                                                    "coordinates": data.poi_data.geometry.coordinates[0]
                                                }
                                            }
                                            // editRef._toolbars.edit._modes.edit.handler.enable()
                                            onEdited(select)
                                            // console.log('editRef', editRef)
                                            map.setView([data.poi_data.geometry.coordinates[0][1], data.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                                                "   ": true,
                                                "duration": 1,
                                            })
                                            return
                                        }
                                        if (data.poi_data?.geometry.type === "Rectangle") {
                                            const select = {
                                                "cname": data.cname,
                                                "poi_id": data.poi_id,
                                                "poi_name": data.poi_name,
                                                "group_id": data.group_id,
                                                "poi_data": data.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    ...data.poi_data.properties,
                                                    "shape": "rectangle"
                                                },
                                                "geometry": {
                                                    "type": "Polygon",
                                                    "coordinates": data.poi_data.geometry.coordinates
                                                }
                                            }
                                            // editRef._toolbars.edit._modes.edit.handler.enable()
                                            onEdited(select)
                                            map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            }), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                            return
                                        }
                                        if (data.poi_data?.geometry.type === "Polyline") {
                                            const select = {
                                                "cname": data.cname,
                                                "poi_id": data.poi_id,
                                                "poi_name": data.poi_name,
                                                "group_id": data.group_id,
                                                "poi_data": data.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    ...data.poi_data.properties,
                                                    "shape": "polyline"
                                                },
                                                "geometry": {
                                                    "type": "LineString",
                                                    "coordinates": data.poi_data.geometry.coordinates
                                                }
                                            }
                                            // editRef._toolbars.edit._modes.edit.handler.enable()
                                            onEdited(select)
                                            map.fitBounds(data.poi_data.geometry.coordinates.map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            }), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                            return
                                        }
                                        if (data.poi_data?.geometry.type === "Polygon") {
                                            const select = {
                                                "cname": data.cname,
                                                "poi_id": data.poi_id,
                                                "poi_name": data.poi_name,
                                                "group_id": data.group_id,
                                                "poi_data": data.poi_data,
                                                "type": "Feature",
                                                "properties": {
                                                    ...data.poi_data.properties,
                                                    "shape": "polygon"
                                                },
                                                "geometry": {
                                                    "type": "Polygon",
                                                    "coordinates": data.poi_data.geometry.coordinates
                                                }
                                            }
                                            // editRef._toolbars.edit._modes.edit.handler.enable()
                                            onEdited(select)
                                            map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            }), {
                                                "animate": true,
                                                "duration": 1,
                                            })
                                            return
                                        }

                                    }}
                                    size="small"
                                >
                                    <ModeEditSharpIcon fontSize='10px' />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        onDeletePoi(data)
                                    }}
                                    size="small"
                                >
                                    <DeleteForeverOutlinedIcon fontSize='10px' />
                                </IconButton>
                            </Box>
                        </Box>
                    )
                }
                }
            />
        </Box>
    )
}
