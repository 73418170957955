import React, { useState } from 'react'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import DialogDriver from './DialogDriver';
import { useNavigate } from 'react-router-dom';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MainBooking() {
  // const bookings = [
  //   {
  //     "booking_id": "BK123456",
  //     name: 'บริษัท บางกอกเซอร์วิส จำกัด',
  //     "date": "2024-07-20",
  //     "pickup_location": "Hotel",
  //     "pickup_date": "2024-07-20",
  //     "pickup_time": "11:00 AM",
  //     "dropoff_location": "Airport",
  //     "dropoff_date": "2024-07-30",
  //     "dropoff_time": "11:00 AM",
  //   },
  //   {
  //     "booking_id": "BK123457",
  //     name: 'บริษัท โกลเบิล จำกัด',
  //     "date": "2024-07-21",
  //     "pickup_location": "Hotel",
  //     "pickup_date": "2024-07-20",
  //     "pickup_time": "11:00 AM",
  //     "dropoff_location": "Airport",
  //     "dropoff_date": "2024-07-30",
  //     "dropoff_time": "11:00 AM",
  //   },
  //   {
  //     "booking_id": "BK123458",
  //     name: 'นายวิษณุ  บุญดี',
  //     "date": "2024-07-21",
  //     "pickup_location": "Hotel",
  //     "pickup_date": "2024-07-20",
  //     "pickup_time": "11:00 AM",
  //     "dropoff_location": "Airport",
  //     "dropoff_date": "2024-07-30",
  //     "dropoff_time": "11:00 AM",
  //   },
  // ];

  const [bookings, setBookings] = useState([
    {
      "booking_id": "BK123456",
      "date_create": "2024-05-13",
      "booking_start": "2024-07-20",
      "booking_end": "2024-07-30",
      "booking_type": "ลูกค้าทั่วไป",
      "booking_work_type": "รับส่งลูกค้า",
      "booking_ref": "REF123456",
      "contact_phone": "+1234567890",
      "contact": "คุณหวัง",
      "status": "รอชำระเงิน",
      "remark": "",
      "requirement": "",
      "customer": {
        "prefix": "-",
        "name": "บริษัท บางกอกเซอร์วิส จำกัด",
        "address": "123 Main Street",
        "address_type": "Office",
        "branch": "Headquarters",
        "district": "Bangkok",
        "subdistrict": "Sukhumvit",
        "province": "Bangkok",
        "postcode": "10110",
        "tax_id": "1234567890123",
        "phone": "+1234567890",
      },
      "trips": [
        {
          "trip_id": "1",
          "trip_type": "To Airport",
          "pickup_location": "Hotel",
          "pickup_date": "2024-07-20",
          "pickup_time": "11:00",
          "dropoff_location": "Airport",
          "dropoff_date": "2024-07-30",
          "dropoff_time": "11:00",
          "remark": "",
          "employees": { "driver_capacity": "4", "bushostess_capacity": "1" },
          "trips_cars": [
            {
              "trip_car_id" : "TC0001",
              "car_type": "BUS-44",
              "car_name": "รถบัส 44 ที่นั่ง",
              "car_capacity": 1
            },
            {
              "trip_car_id" : "TC0002",
              "car_type": "VAN-13",
              "car_name": "รถตู้ 13 ที่นั่ง",
              "car_capacity": 1
            }
          ],
          "cars_control": [
            {
              "cars_control_id": "CT00001",
              "car_type": "BUS-44",
              "car_name": "รถบัส 44 ที่นั่ง",
              "works": [
                {
                  "works_id": "CT00001",
                  "car_id": "CAR001",
                  "car_type": "BUS-44",
                  "work_type": "MAIN",
                  "passenger_capacity": 1,
                  "pickupDate": "2024-06-01",
                  "pickupLocation": "Hotel",
                  "pickupTime": "09:00 AM",
                  "returnDate": "2024-06-10",
                  "returnLocation": "Airport",
                  "returnTime": "11:00 AM",
                  "licensePlate": "XYZ1234",
                  "status": "confirmed",
                  "remark": "",
                  "drivers": [
                    {
                      "employee_id": "EMP001",
                      "name": "ประสิทธิ์ จงจิตกลาง",
                      "phone": "+1987654321"
                    },
                    {
                      "employee_id": "EMP002",
                      "name": "ปริญญา ศรีท้วม",
                      "phone": "+1122334455"
                    }
                  ],
                  "bushostess": []
                },
                {
                  "works_id": "CT00002",
                  "car_id": "CAR002",
                  "car_type": "BUS-44",
                  "work_type": "MAIN",
                  "passenger_capacity": 1,
                  "pickupDate": "2024-06-01",
                  "pickupLocation": "Hotel",
                  "pickupTime": "09:00 AM",
                  "returnDate": "2024-06-10",
                  "returnLocation": "Airport",
                  "returnTime": "11:00 AM",
                  "licensePlate": "XYZ2234",
                  "status": "confirmed",
                  "drivers": [
                    {
                      "employee_id": "EMP001",
                      "name": "นายสุรชาติ  ดอกรัก",
                      "phone": "+1987654321"
                    },
                    {
                      "employee_id": "EMP002",
                      "name": "นายอมรินทร์  ชะลำนัย",
                      "phone": "+1122334455"
                    }
                  ],
                  "bushostess": []
                }
              ]
            },
            {
              "cars_control_id": "CT00002",
              "car_type": "VAN-13",
              "car_name": "รถตู้ 13 ที่นั่ง",
              "works": [
              ]
            }
          ],
          "payment": {
            "payment_method": "Credit Card",
            "amount_paid": 140.00,
            "payment_status": "Paid"
          },
          "additional_info": {
            "notes": "Call upon arrival at hotel.",
            "special_instructions": "Driver should be bilingual."
          }
        }
      ]
    }
  ])

  const navigation = useNavigate()

  const onCreate = () => {
    navigation("create")
  }
  const onUpdate = (data) => {
    navigation("update", { state: data })
  }
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClickVariant = (message, variant) => {
    enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

  };
  const handleSnackWithAction = ({ message, data, onHandle }) => {
    closeSnackbar()
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: "center",
        vertical: 'bottom'
      },
      variant: 'default',
      // style: { bgcolor: "whitesmoke !important" },
      action: (key) => {
        return (
          <React.Fragment>
            <Stack direction="row" spacing={1}>
              <Button
                size='small'
                color="error"
                variant="contained"
                onClick={() => {
                  console.log('data', data)
                  closeSnackbar(key)
                  onHandle()
                }}
              >
                <CheckRoundedIcon fontSize="small" />
              </Button>
              <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                <CloseRoundedIcon fontSize="small" />
              </Button>
            </Stack>
          </React.Fragment>
        )
      }
    });
  };


  const onHandleBooking = () => {
    handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
  }


  return (
    <div>
      <Box sx={{ padding: "10px" }}>
        <Stack spacing={1}>
          <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <Box>
              <TextField size="small" label='ค้นหา'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box>
              <IconButton onClick={onCreate} ><PostAddOutlinedIcon /></IconButton>
            </Box>
          </Stack>
          <Box>
            <Table aria-label="booking table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">เลขที่ใบจอง</TableCell>
                  <TableCell align="left">ชื่อลูกค้า</TableCell>
                  <TableCell align="center">ผู้ติดต่อ</TableCell>
                  <TableCell align="center">เบอร์ผู้ติดต่อ</TableCell>
                  <TableCell align="left">สถานะ</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.map((booking, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{booking.booking_id}</TableCell>
                    <TableCell align="left">{booking.customer.name}</TableCell>
                    <TableCell align="center">{booking.contact}</TableCell>
                    <TableCell align="center">{booking.contact_phone}</TableCell>
                    <TableCell align="left">{booking.status}</TableCell>
                    <TableCell align="right">
                      <Stack spacing={1} direction="row" justifyContent="space-between" >
                        <IconButton onClick={() => onUpdate(booking)} size="small"><EditNoteIcon /></IconButton>
                        <IconButton onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลใบจอง", data: booking, onHandle: onHandleBooking }) }} size="small"><DeleteOutlineIcon /></IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      </Box>
    </div>
  )
}
