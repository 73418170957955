import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

export default function DialogDriver({ data, onChange }) {

    // const [drivers, setDrivers] = useState([])

    const drivers = [
        {
            "employee_id": "EMP001",
            "name": "ประสิทธิ์ จงจิตกลาง",
            "phone": "+1987654321"
        },
        {
            "employee_id": "EMP002",
            "name": "ปริญญา ศรีท้วม",
            "phone": "+1122334455"
        }
    ];

    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState(data || []);
    useEffect(() => {
        setChecked(data)
    }, [data])

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        setOpen(false)
        onChange(checked)
    }

    const handleCheckboxChange = (emp) => {
        if (checked.find((item) => item.employee_id === emp.employee_id)) {
            setChecked(checked.filter((item) => item.employee_id !== emp.employee_id));
        } else {
            setChecked([...checked, emp]);
        }
    };

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between">
                <Box>
                    พนักงานขับรถ
                </Box>
                <Box>
                    <Button variant="outlined" size="small" color="error" onClick={handleOpen}>
                        <AddOutlinedIcon fontSize="small" />
                    </Button>
                </Box>
            </Stack>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                        <Box>
                            พนักงานขับรถ
                        </Box>
                        <Box sx={{ marginRight: "50px" }}>
                            <TextField size="small" label='ค้นหา'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" >
                                            <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                    </Stack>

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>เลือก</TableCell>
                                    <TableCell>รหัสพนักงาน</TableCell>
                                    <TableCell align="left">ชื่อ-นามสกุล</TableCell>
                                    <TableCell align="left">เบอร์โทร</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {drivers.map((employee) => (
                                    <TableRow key={employee.employee_id}>
                                        <TableCell component="th" scope="row">
                                            <Checkbox
                                                checked={checked.find((item) => item.employee_id === employee.employee_id)}
                                                onChange={() => handleCheckboxChange(employee)}
                                                value={checked.find((item) => item.employee_id === employee.employee_id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {employee.employee_id}
                                        </TableCell>
                                        <TableCell align="left">{employee.name}</TableCell>
                                        <TableCell align="left">{employee.phone}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog>
        </Box >
    )
}
