import TuneIcon from '@mui/icons-material/Tune'
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { FormGroup, Backdrop, TableContainer, Table, TableBody, useMediaQuery, TableHead, TableRow, TableCell, Checkbox, FormControlLabel, alpha, Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Divider, Button, MenuItem, Container, Fab, Grid, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Paper, styled, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ViewListIcon from '@mui/icons-material/ViewList';
import DashboardMap from './DashboardMap'
import CheckIcon from '@mui/icons-material/Check'
import { Api } from '../../components/api'
import DashboardItem from './DashboardItem';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { filterGroupState, groupRouteState, listDevicesChartState, listDevicesCustomState, listDevicesState, listGroupsState, searchState, selectDeviceState, stationDashboardState } from './DashboardStore';
import { useTheme } from '@mui/styles';
import DashboardViewer from './DashboardViewer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';

import lodash from 'lodash';

const drawerWidth = 400;

export default function DashboardMapGroup({ userId, account }) {
    const [dashboard, setDashboard] = useState(
        localStorage.getItem("dashboardView") === "false" ? false : true
    )
    const [open, setOpen] = useState(true)
    const [openDialog, setOpenDialog] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchGroup, setSearchGroup] = useState("");
    const [search, setSearch] = useRecoilState(searchState);
    const [filterGroup, setFilterGroup] = useRecoilState(filterGroupState);
    const [listDevices, setListDevices] = useRecoilState(listDevicesState)
    const [listGroups, setListGroups] = useRecoilState(listGroupsState)
    const [selectDevice, setSelectDevice] = useRecoilState(selectDeviceState);
    const [stationsDashboard, setListStationDashboard] = useRecoilState(stationDashboardState);

    const [map, setMap] = useState(null);


    const [listDevicesCustom, setListDevicesCustom] = useRecoilState(listDevicesCustomState)
    const [groupRoute, setGroupRoute] = useRecoilState(groupRouteState)
    const [listDevicesChart, setListDevicesChart] = useRecoilState(listDevicesChartState)




    const [fristLoading, setFristLoading] = useState(true)

    // map.flyToBounds(PolylineUtil.decode(encode), { duration: 1 })
    /////////////////////////////////////////////
    const { c_coname } = account;

    useEffect(() => {
        // Api.get(`Tracking/GroupAll?userid=${userId}&cname=${c_coname}`)
        Api.get(`Tracking/GroupByUser?userid=${userId}`)
            .then(res => {
                var list = res.data;
                setListGroups(list);

                if (listDevicesChart.length > 0) {
                    const data = {
                        userid: userId,
                        list: listDevicesChart
                    }
                    Api.post(`/Tracking/DevicesByList`, data).then((res) => {
                        var list = res.data;
                        setListDevices(list);
                        // setLoading(false)
                    }
                    ).catch(e => {
                        console.log('error', e)
                    })
                } else {
                    if (filterGroup === null && listDevicesCustom.length === 0) {
                        const group = list[0]
                        const group_id = group?.group_id
                        if (group_id !== undefined) {
                            setFilterGroup(group)

                            if (group.cname === "") {
                                Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${group_id}`).then((res) => {
                                    var list = res.data;
                                    setListDevices(list);
                                    setListStationDashboard([])
                                    // setLoading(false)
                                }
                                ).catch(e => {
                                    console.log('error', e)
                                })
                            }
                            if (group.cname !== "") {
                                Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${group_id}&cname=${group.cname}`).then((res) => {
                                    var list = res.data;
                                    setListDevices(list);
                                    // setLoading(false)
                                }
                                ).catch(e => {
                                    console.log('error', e)
                                })
                                Api.get(`/Tracking/StationDashboard?cname=${group.cname}&group_id=${group_id}`).then((res) => {
                                    var list = res.data;
                                    setListStationDashboard(list);
                                    // setLoading(false)
                                }
                                ).catch(e => {
                                    console.log('error', e)
                                })
                            }
                            Api.get(`/Tracking/GroupRoute?group_id=${group_id}`).then((res) => {
                                var data = res.data.routes;
                                setGroupRoute(data);
                                // setLoading(false)
                            }
                            ).catch(e => {
                                console.log('error', e)
                            })
                        }
                    } else if (listDevicesCustom.length > 0) {
                        const data = {
                            userid: userId,
                            list: listDevicesCustom.map(v => v.device)
                        }
                        Api.post(`/Tracking/DevicesByList`, data).then((res) => {
                            var list = res.data;
                            setListDevices(list);
                            // setLoading(false)
                        }
                        ).catch(e => {
                            console.log('error', e)
                        })
                    }
                }
                setFristLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
                setFristLoading(false)
            })
    }, [])


    useEffect(() => {
        const interval = setInterval(() => {
            if (listDevicesChart.length > 0) {
                const data = {
                    userid: userId,
                    list: listDevicesChart
                }
                Api.post(`/Tracking/DevicesByList`, data).then((res) => {
                    var list = res.data;
                    setListDevices(list);
                    // setLoading(false)
                }
                ).catch(e => {
                    console.log('error', e)
                })
            } else {
                if (filterGroup !== null) {
                    if (filterGroup.cname === "") {
                        Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${filterGroup?.group_id}`).then((res) => {
                            var list = res.data;
                            setListDevices(list);
                            setListStationDashboard([])
                            // setLoading(false)
                        }
                        ).catch(e => {
                            console.log('error', e)
                        })
                    }
                    if (filterGroup.cname !== "") {
                        Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${filterGroup?.group_id}&cname=${filterGroup.cname}`).then((res) => {
                            var list = res.data;
                            setListDevices(list);
                            // setLoading(false)
                        }
                        ).catch(e => {
                            console.log('error', e)
                        })
                        Api.get(`/Tracking/StationDashboard?cname=${filterGroup.cname}&group_id=${filterGroup?.group_id}`).then((res) => {
                            var list = res.data;
                            setListStationDashboard(list);
                            // setLoading(false)
                        }
                        ).catch(e => {
                            console.log('error', e)
                        })
                    }
                    // Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${filterGroup}`).then((res) => {
                    //     var list = res.data;
                    //     setListDevices(list);
                    //     // setLoading(false)
                    // }
                    // ).catch(e => {
                    //     console.log('error', e)
                    // })   
                } else {
                    if (listDevicesCustom.length > 0) {
                        const data = {
                            userid: userId,
                            list: listDevicesCustom.map(v => v.device)
                        }
                        Api.post(`/Tracking/DevicesByList`, data).then((res) => {
                            var list = res.data;
                            setListDevices(list);
                            // setLoading(false)
                        }
                        ).catch(e => {
                            console.log('error', e)
                        })
                    }
                }
            }
        }, 5000);

        return () => {
            clearInterval(interval)
        }
    }, [filterGroup, listDevicesCustom, listGroups, listDevices, listDevicesChart])



    /////////////////////////////////////////////

    const getDevicesByCustom = (list) => {
        setFristLoading(true)

        const data = {
            userid: userId,
            list: list.map(v => v.device)
        }
        Api.post(`/Tracking/DevicesByList`, data).then((res) => {
            var list = res.data;
            setListDevices(list);
            setFristLoading(false)

            // setLoading(false)
        }
        ).catch(e => {
            console.log('error', e)
            setFristLoading(false)
        })
    }


    const getDevicesByAll = () => {
        setFristLoading(true)
        Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
            var list = res.data;
            const _listDevice = list.map(v => v.device)
            const data = {
                userid: userId,
                list: _listDevice
            }
            Api.post(`/Tracking/DevicesByList`, data).then((res) => {
                var list = res.data;
                setListDevicesChart(_listDevice);
                setListDevices(list);
                setFristLoading(false)
                // setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
                setFristLoading(false)
            })
            // setLoading(false)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }


    const getDevicesByGroups = (group_id, cname) => {
        setFristLoading(true)
        if (cname === "") {
            Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${group_id}`).then((res) => {
                var list = res.data;
                setListDevices(list);
                setListStationDashboard([])
                setFristLoading(false)
                // setLoading(false)

            }
            ).catch(e => {
                console.log('error', e)
                setFristLoading(false)
            })
        }
        if (cname !== "") {
            Api.get(`/Tracking/devicesbygroups?userid=${userId}&groups=${group_id}&cname=${cname}`).then((res) => {
                var list = res.data;
                setListDevices(list);
                setFristLoading(false)
                // setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
                setFristLoading(false)
            })
            getStationDashboard(group_id, cname)

        }

        // getGroupRoute(group_id)


    }
    const getStationDashboard = (group_id, cname) => {
        Api.get(`/Tracking/StationDashboard?cname=${cname}&group_id=${group_id}`).then((res) => {
            var list = res.data;
            setListStationDashboard(list);
            // setLoading(false)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }
    const getGroupRoute = (group_id) => {
        Api.get(`/Tracking/GroupRoute?group_id=${group_id}`).then((res) => {
            var data = res.data.routes;
            setGroupRoute(data);
            // setLoading(false)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }

    /////////////////////////////////////////////



    const handleChange = (event) => {
        const { value } = event.target
        setSelectDevice(null)
        setSearch(value)
    }

    const openMenu = Boolean(anchorEl);
    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setSearchGroup("")
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchGroup("")
    };
    const handleDialogToggle = () => {
        setOpenDialog(!openDialog)
    };
    const handleChangeGroup = (event) => {
        const { value } = event.target
        setSearchGroup(value)
    }
    // const _filterList = listDevices.sort((a, b) => a.name.localeCompare(b.name))
    const sorted = lodash.sortBy(listDevices, ['name']);
    const filterList = sorted.filter((val) => val.name.toUpperCase().includes(search.toUpperCase()))
    // const filterList = listDevices.filter((val) => val.name.toUpperCase().includes(search.toUpperCase())).filter((val) => {
    //     const isFind = val.group.filter(a => a.group_id === '')
    //     if (isFind.length > 0) {
    //         return val
    //     }
    //     return
    // })
    const filterGroups = listGroups.filter((value) => value.group_name?.toUpperCase().includes(searchGroup.toUpperCase()) || value.group_id.toUpperCase().includes(searchGroup.toUpperCase()))
    const offLine = filterList.filter(value => {
        const dateNow = new Date()
        const minOffLine = dateNow.setMinutes(dateNow.getMinutes() - 5)
        return value.datetime === null || new Date(value.datetime) < minOffLine
    })
    /////////////////////////////////////////////



    /////////////////////////////////////////////
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const filterGroupsCname = filterGroups.filter((value) => value.cname !== "")
    const filterGroupsCustom = filterGroups.filter((value) => value.cname === "")
    /////////////////////////////////
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            // handleDel(item)
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </>
            )
        });
    };
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }


    const handleDelCustomGroup = (group_id) => {
        const { c_coname } = account
        Api.delete(`/Tracking/GroupCustom?userid=${userId}&group_id=${group_id}`)
            .then(res => {
                handleSnackbar({ variant: 'success', message: 'success!' })
                Api.get(`Tracking/GroupByUser?userid=${userId}`)
                    .then(res => {
                        var list = res.data;
                        setListGroups(list);
                        if (filterGroup?.group_id === group_id) {
                            setSelectDevice(null)
                            setFilterGroup(null)
                            setListDevices([])
                        }
                        handleClose()

                    }
                    ).catch(e => {
                        console.log('error', e)
                    })
            }
            ).catch(error => {
                handleSnackbar({ variant: 'error', message: error.response.data.Message });
            }
            )
    };

    return (
        <Page title="Dashboard" >
            <Container maxWidth={false} style={{ padding: 0, overflow: 'hidden' }}>

                {
                    fristLoading && (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={fristLoading}
                        // onClick={handleClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )
                }

                {
                    !open && (
                        <HeaderBox>
                            <Button
                                sx={{
                                }}
                                variant="contained"
                                style={{ backgroundColor: "white", borderRadius: 0, color: "black" }}
                                startIcon={<ViewListIcon />}
                                onClick={() => {
                                    setOpen(!open)
                                }}>
                                Device
                            </Button>
                        </HeaderBox>
                    )
                }
                {/* ///////////////////////////////////// */}

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{
                        display: fullScreen && selectDevice !== null ? "none" : !open ? 'none' : 'unset'
                    }}>
                        <SidebarBox >
                            <ToolbarStyle>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label={filterGroup === null ? "search" : listGroups.find(v => v.group_id === filterGroup.group_id && v.cname === filterGroup.cname)?.group_name}
                                    value={search}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end' >
                                                <IconButton
                                                    edge="end"
                                                    onClick={handleClickOpenMenu}
                                                    size="small"
                                                    sx={{ ml: 2 }}
                                                    aria-controls={openMenu ? 'search-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? 'true' : undefined}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <IconButton
                                    style={{ borderRadius: 0 }}
                                    size="small"
                                    onClick={() => {
                                        setSelectDevice(null)
                                        handleDialogToggle()
                                    }}>
                                    <PlaylistAddIcon />
                                </IconButton>

                                <IconButton
                                    style={{ borderRadius: 0 }}
                                    size="small"
                                    onClick={() => {
                                        setDashboard(!dashboard)
                                        localStorage.setItem("dashboardView", String(!dashboard))
                                        setSelectDevice(null)
                                        setTimeout(() => { map.invalidateSize() }, 100)
                                    }}>
                                    {dashboard ? <MapRoundedIcon /> : <DashboardRoundedIcon />}
                                </IconButton>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <IconButton
                                    style={{ borderRadius: 0 }}
                                    size="small"
                                    onClick={() => {
                                        setOpen(!open)
                                    }}>
                                    <CloseRoundedIcon />
                                </IconButton>
                            </ToolbarStyle>
                            <Box sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '10px'
                            }}>
                                <Grid spacing={1} container justifyContent="center">
                                    <Grid item xs={4} justifyContent="center">
                                        <Typography textAlign="center" variant="h6">{filterList.length}</Typography>
                                        <Typography textAlign="center" color="#A9A9A9">DEVICE</Typography>
                                    </Grid>
                                    <Grid item xs={4} justifyContent="center">
                                        <Typography textAlign="center" variant="h6">{filterList.length - offLine.length}</Typography>
                                        <Typography textAlign="center" color="#A9A9A9">ONLINE</Typography>
                                    </Grid>
                                    <Grid item xs={4} justifyContent="center">
                                        <Typography textAlign="center" variant="h6">{offLine.length}</Typography>
                                        <Typography textAlign="center" color="#A9A9A9">OFFLINE</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                sx={{
                                    height: "calc(100vh - 185px)",
                                    overflowY: "auto"
                                }}
                            >
                                {
                                    filterList.map((value, i) => {

                                        return (
                                            <DashboardItem onClick={
                                                () => {
                                                    setDashboard(false)
                                                    localStorage.setItem("dashboardView", "false")
                                                    setTimeout(() => {
                                                        map.invalidateSize()
                                                        setSelectDevice(null)
                                                        map.setView([value.latitude, value.longitude], 19, {
                                                            "animate": true,
                                                            "duration": 1,
                                                        })
                                                        setSelectDevice(value.device)
                                                    }, 100)
                                                }
                                            }
                                                data={value}
                                                key={i}
                                            />
                                        )
                                    })
                                }
                            </Box>
                        </SidebarBox>
                    </Box>

                    {/* ///////////////////////////////////// */}

                    <Box
                        sx={theme => ({
                            [theme.breakpoints.down("md")]: {
                                position: "absolute",
                                left: 0,
                                width: 'calc(100vw)',
                                // height: `calc(100vh - 56px)`,
                                // bottom: "56px",
                            },
                            width: `calc(100vw - ${open ? drawerWidth : 0}px)`,
                            height: `calc(100vh)`,
                            overflow: "auto"
                            // backgroundColor: "gray"
                        })}
                    >
                        {
                            dashboard && (
                                <DashboardViewer setFristLoading={setFristLoading} userId={userId} setGroupRoute={setGroupRoute} c_coname={c_coname} getDevicesByGroups={getDevicesByGroups} setFilterGroup={setFilterGroup} setListDevicesChart={setListDevicesChart} setListStationDashboard={setListStationDashboard} getGroupRoute={getGroupRoute} />
                            )
                        }
                        <DashboardMap open={open} stationsDashboard={stationsDashboard} groupRoute={groupRoute} setMapDashboard={setMap} listDevices={filterList} userId={userId} selectDevice={selectDevice} setSelectDevice={setSelectDevice} setView={!dashboard} />
                    </Box>

                </Box>

                {/* ///////////////////////////////////// */}
                <MenuBottomNav>
                    <BottomNavigationBar ative="map" />
                </MenuBottomNav>

                {/* ///////////////////////////////////// */}

                <StyledMenu
                    id="group-menu"
                    MenuListProps={{
                        'aria-labelledby': 'group-button'
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                >
                    <Box sx={{
                        padding: "10px"
                    }}>
                        <MenuItem onKeyDown={(e) => e.stopPropagation()}
                            sx={{
                                backgroundColor: "white !important",
                                '&:active': {
                                    backgroundColor: 'white !important',
                                },
                                '&:hover': {
                                    backgroundColor: 'white',
                                },
                            }}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                label={"search"}
                                value={searchGroup}
                                autoFocus
                                onChange={handleChangeGroup}
                                autoComplete='off'
                            />
                        </MenuItem>
                    </Box>
                    {
                        searchGroup === '' && (

                            <ListItem
                                disablePadding
                            // key={i}
                            >
                                <ListItemButton onClick={() => {
                                    // setSelectDevice(null)
                                    // getDevicesByGroups(value.group_id, value.cname)
                                    // getGroupRoute(value.group_id)
                                    setListDevices([])
                                    setSelectDevice(null)
                                    handleClose()
                                    getDevicesByAll()
                                    setFilterGroup(null)
                                    setListStationDashboard([])
                                    setGroupRoute([])
                                    // setFilterGroup(value)
                                    // setListDevicesCustom([])
                                    // setListDevicesChart([])
                                }}>
                                    <ListItemIcon>
                                        {/* {
                                        filterGroup?.group_id === value.group_id && filterGroup?.cname === value.cname && (
                                            <CheckIcon />
                                        )
                                    } */}
                                    </ListItemIcon>
                                    <ListItemText primary={`(${"ALL"}) ALL`} />
                                </ListItemButton>
                            </ListItem>

                        )
                    }
                    {
                        filterGroupsCname.length > 0 && (
                            <nav aria-label="main">
                                <List
                                    sx={{
                                        width: 370,
                                        textOverflow: "ellipsis"
                                    }}
                                >
                                    {
                                        filterGroupsCname.map((value, i) => {

                                            return (
                                                <ListItem
                                                    disablePadding
                                                    key={i}
                                                >
                                                    <ListItemButton onClick={() => {
                                                        setListDevices([])
                                                        setSelectDevice(null)
                                                        getDevicesByGroups(value.group_id, value.cname)
                                                        getGroupRoute(value.group_id)
                                                        handleClose()
                                                        setFilterGroup(value)
                                                        setListDevicesCustom([])
                                                        setListDevicesChart([])
                                                    }}>
                                                        <ListItemIcon>
                                                            {
                                                                filterGroup?.group_id === value.group_id && filterGroup?.cname === value.cname && (
                                                                    <CheckIcon />
                                                                )
                                                            }
                                                        </ListItemIcon>
                                                        <ListItemText primary={`(${value.group_id}) ${value.group_name}`} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </nav>
                        )
                    }

                    {
                        filterGroupsCustom.length > 0 && (
                            <nav aria-label="main">
                                <List
                                    sx={{
                                        width: 370,
                                        textOverflow: "ellipsis"
                                    }}
                                >
                                    <Divider />
                                    <Box sx={{
                                        paddingLeft: "20px"
                                    }}>
                                        <Typography color="gray">custom</Typography>
                                    </Box>

                                    {
                                        filterGroupsCustom.map((value, i) => {

                                            return (
                                                <ListItem
                                                    disablePadding
                                                    key={i}
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete"
                                                            onClick={() => {
                                                                handleSnackWithAction({
                                                                    message: `delete ${value.group_name}?`, handle: () => {
                                                                        handleDelCustomGroup(value.group_id)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <DeleteForeverIcon color="error" />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemButton onClick={() => {
                                                        setSelectDevice(null)
                                                        getDevicesByGroups(value.group_id, value.cname)
                                                        getGroupRoute(value.group_id)
                                                        handleClose()
                                                        setFilterGroup(value)
                                                        setListDevicesCustom([])
                                                        setListDevicesChart([])
                                                    }}>
                                                        <ListItemIcon>
                                                            {
                                                                filterGroup?.group_id === value.group_id && filterGroup.cname === value.cname && (
                                                                    <CheckIcon />
                                                                )
                                                            }
                                                        </ListItemIcon>
                                                        <ListItemText primary={`(${value.group_id}) ${value.group_name}`} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </nav>
                        )
                    }
                </StyledMenu>

                {/* //////////////////////////////////// */}
                <DialogCustom openDialog={openDialog} handleDialogToggle={handleDialogToggle} handleSubscribe={
                    (values) => {
                        handleDialogToggle()
                        // if (values.length > 0) {
                        setListDevices([])
                        setFilterGroup(null)
                        setListDevicesCustom(values)
                        getDevicesByCustom(values)
                        setGroupRoute([])
                        setListDevicesChart([])
                        // }
                    }
                }
                    userId={userId}
                    listDevicesCustom={listDevicesCustom}
                    c_coname={c_coname}

                    getDevicesByGroups={getDevicesByGroups}
                    getGroupRoute={getGroupRoute}
                    setListStationDashboard={setListStationDashboard}
                />
                {/* //////////////////////////////////// */}
            </Container>
        </Page >
    )
}

const MenuBottomNav = styled(Paper)(({ theme }) => (
    {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        position: 'fixed',
    }));

const SidebarBox = styled(Box)(({ theme }) => (
    {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
        },
        [theme.breakpoints.down("md")]: {
            position: 'fixed',
        },
        width: "100vw",
        height: "calc(100vh - 57px)",
        zIndex: 999,
        backgroundColor: "white"
    }));

const HeaderBox = styled(Paper)(({ theme }) => (
    {
        left: 0,
        // right: 0,
        top: 0,
        position: 'fixed',
        zIndex: 999,
    }));
const ToolbarStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1, 2)
}));


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 370,
        maxHeight: "calc(100vh - 100px)",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


const DialogCustom = ({ openDialog, handleDialogToggle, handleSubscribe, userId, listDevicesCustom, c_coname, getDevicesByGroups, getGroupRoute, setListStationDashboard }) => {
    const [valueList, setValueList] = useState(listDevicesCustom)
    const [openDialogSearch, setOpenDialogSearch] = useState(false)
    const [openDialogSave, setOpenDialogSave] = useState(false)


    const [listUnSelect, setListUnSelect] = useState([])

    const handleDialogSaveToggle = () => {
        setOpenDialogSave(!openDialogSave)
    };

    const handleDialogSearchToggle = () => {
        setOpenDialogSearch(!openDialogSearch)
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleDialogToggle}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                ".MuiBackdrop-root": {
                    backgroundColor: "rgba(111, 126, 140, 0.2)"
                }
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <Box>
                        Subscribe
                    </Box>
                    <Box
                        sx={{
                            cursor: "pointer"
                        }}
                    >
                        <IconButton
                            onClick={() => {
                                handleDialogSearchToggle()
                            }}
                        >
                            <SearchIcon />

                        </IconButton>

                    </Box>
                    <DialogCustomSearch userId={userId} openDialogSearch={openDialogSearch} handleDialogSearchToggle={handleDialogSearchToggle}
                        setToListSub={(list) => {
                            setValueList(list)
                        }}
                        selectsValuex={valueList}
                    />
                </Box>
            </DialogTitle>
            <DialogContent
                dividers={true}
                sx={{
                    // width: theme => theme.breakpoints.up("md") ? "600px" : "100%",
                    width: !fullScreen ? "600px" : "auto",
                    height: "500px",
                    textAlign: "center",
                    padding: "5px",
                    // overflow: "hidden"
                }}
            >
                {
                    valueList.length > 0 && (
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={'medium'}

                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell >

                                        </TableCell>
                                        <TableCell >
                                            ชื่อ
                                        </TableCell>
                                        <TableCell>
                                            ทะเบียน
                                        </TableCell>
                                        <TableCell>
                                            เลขตัวถัง
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody  >
                                    {
                                        valueList.map((v, i) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    sx={{
                                                        // cursor: "pointer"
                                                    }}
                                                    key={"A" + i}
                                                >
                                                    <TableCell >
                                                        <IconButton
                                                            sx={{ padding: 0 }}
                                                            onClick={() => {
                                                                if (listUnSelect.find(s => s.device === v.device) !== undefined) {
                                                                    setListUnSelect(listUnSelect.filter(x => x.device !== v.device))
                                                                } else {
                                                                    const news = listUnSelect.concat(v)
                                                                    // console.log('new', news)
                                                                    setListUnSelect(news)
                                                                }
                                                            }}
                                                        >
                                                            {
                                                                listUnSelect.find(s => s.device === v.device) === undefined ?
                                                                    <CheckBoxOutlinedIcon />
                                                                    :
                                                                    <CheckBoxOutlineBlankOutlinedIcon />

                                                            }
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography> {v.name}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography> {v.vehicle_id}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography> {v.vehicle_chassis_no}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
                <DialogCustomSave openDialogSave={openDialogSave} handleDialogToggle={handleDialogSaveToggle} valueList={valueList} c_coname={c_coname} userId={userId} setValueList={setValueList} handleDialogAll={() => {
                    handleDialogSaveToggle()
                    handleDialogToggle()
                }}
                    getDevicesByGroups={getDevicesByGroups}
                    getGroupRoute={getGroupRoute}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
                <Box>
                    <Button disabled={valueList.length === 0} onClick={handleDialogSaveToggle}>Save</Button>
                </Box>
                <Box>

                    <Button onClick={handleDialogToggle}>Cancel</Button>
                    <Button onClick={
                        () => {
                            const xy = valueList.map(v => {
                                if (listUnSelect.find(x => x.device === v.device) === undefined) {
                                    return v
                                }
                            })
                            const xyN = xy.filter(x => x !== undefined)
                            handleSubscribe(xyN)
                            setValueList(xyN)
                            setListUnSelect([])
                            setListStationDashboard([])
                        }
                    }
                        disabled={valueList.length === 0}
                    >Subscribe</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const DialogCustomSearch = ({ openDialogSearch, selectsValuex = [], handleDialogSearchToggle, userId, setToListSub }) => {
    const [search, setSearch] = useState('')
    const [listSearch, setListSearch] = useState([])
    const [loading, setLoading] = useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [listSelect, setListSelect] = useState(selectsValuex)

    // useEffect(() => {
    //     setListSelect(selectsValuex)
    // }, [selectsValuex])

    const handleDialog = () => {
        handleDialogSearchToggle()
        setListSelect(selectsValuex)
    }

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (search !== '') {
                Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=${search}`).then((res) => {
                    var list = res.data;
                    setListSearch(list);
                    setLoading(false)
                }
                ).catch(e => {
                    console.log('error', e)
                })

                // setLoading(false)
            } else {
                setListSearch([])
                setLoading(false)
            }
        }, 500);

        return () => {
            clearTimeout(timeOut)
        }
    }, [search])
    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialogSearch}
            onClose={handleDialog}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="md"
            sx={{
                ".MuiBackdrop-root": {
                    backgroundColor: "rgba(111, 126, 140, 0.2)",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <TextField
                        fullWidth
                        size="small"
                        label={"search"}
                        value={search}
                        autoFocus
                        onChange={(e) => {
                            setListSearch([])
                            setLoading(true)
                            setSearch(e.target.value)
                        }}
                        autoComplete='off'
                    />
                    {/* <Box>
                        <TextField
                            fullWidth
                            size="small"
                            label={"search"}
                            value={search}
                            autoFocus
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            autoComplete='off'
                        />
                    </Box> */}
                </Box>
            </DialogTitle>
            <DialogContent
                dividers={true}

                sx={{
                    // width: theme => theme.breakpoints.up("md") ? "600px" : "100%",
                    width: !fullScreen ? "600px" : "auto",
                    height: "500px",
                    textAlign: "center",
                    padding: "5px",
                    // overflow: "hidden"
                }}
            >
                {
                    loading && (
                        <Typography gutterBottom color="GrayText" >
                            Loading...
                        </Typography>
                    )
                }
                {
                    (listSearch.length === 0 && search !== '' && !loading) && (
                        <Typography gutterBottom color="GrayText" >
                            No data
                        </Typography>
                    )
                }
                {
                    (!loading && search === '') && (
                        <Typography gutterBottom color="GrayText" >
                            Plase Search
                        </Typography>
                    )
                }
                {
                    listSearch.length > 0 && (
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={'medium'}

                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell >

                                        </TableCell>
                                        <TableCell >
                                            ชื่อ
                                        </TableCell>
                                        <TableCell>
                                            ทะเบียน
                                        </TableCell>
                                        <TableCell>
                                            เลขตัวถัง
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody  >
                                    {
                                        listSearch.map((v, i) => {
                                            return (
                                                <TableRow
                                                    onClick={() => {
                                                        if (listSelect.find(s => s.device === v.device) !== undefined) {
                                                            setListSelect(listSelect.filter(x => x.device !== v.device))
                                                        } else {
                                                            const news = listSelect.concat(v)
                                                            // console.log('new', news)
                                                            setListSelect(news)
                                                        }
                                                    }}
                                                    hover
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    key={"s" + i}
                                                >
                                                    <TableCell >
                                                        {
                                                            listSelect.find(s => s.device === v.device) !== undefined ?
                                                                <CheckBoxOutlinedIcon />
                                                                :
                                                                <CheckBoxOutlineBlankOutlinedIcon />

                                                        }
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography> {v.name}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography> {v.vehicle_id}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography> {v.vehicle_chassis_no}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </DialogContent>
            <DialogActions>
                {/* <Typography >{listSelect.length}</Typography> */}
                <Button onClick={handleDialog}>Cancel</Button>
                <Button onClick={() => {
                    setToListSub(listSelect)
                    handleDialogSearchToggle()
                }}>Ok</Button>
            </DialogActions>
        </Dialog >
    )
}


const DialogCustomSave = ({ openDialogSave, handleDialogToggle, valueList, c_coname, userId, setValueList, handleDialogAll, getDevicesByGroups,
    getGroupRoute }) => {
    // const [groupId, setGroupId] = useState('')
    // const [groupName, setGroupName] = useState('')

    const setFilterGroup = useSetRecoilState(filterGroupState)
    const setListGroups = useSetRecoilState(listGroupsState)
    const setSelectDevice = useSetRecoilState(selectDeviceState);
    const setListDevicesCustom = useSetRecoilState(listDevicesState)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleDialog = () => {
        handleDialogToggle()
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };
    const handleGetGroupAll = (values) => {
        Api.get(`Tracking/GroupByUser?userid=${userId}`)
            .then(res => {
                var list = res.data;
                const filter_group = list.find(v => v.group_id === values.groupId)
                setListGroups(list);
                setSelectDevice(null)
                // setFilterGroup(values.groupId)
                setFilterGroup(filter_group)
                setListDevicesCustom([])
                setValueList([])
                getDevicesByGroups(filter_group?.group_id, filter_group?.cname)
                getGroupRoute(filter_group?.group_id)

            }
            ).catch(e => {
                console.log('error', e)
            })
    }
    const Schema = Yup.object().shape({
        groupId: Yup.string().required('group id is required'),
        groupName: Yup.string().required('group Name is required'),
    });
    const formik = useFormik({
        initialValues: {
            groupId: '',
            groupName: ''
        },
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false)
            const data = JSON.stringify({
                userid: userId,
                group_id: values.groupId,
                group_name: values.groupName,
                devices: valueList.map(v => v.device)
            });
            Api.post('/Tracking/GroupCustom', data)
                .then(res => {
                    handleDialogAll()
                    handleGetGroupAll(values)
                    handleSnackbar({ variant: 'success', message: 'success!' })
                }
                ).catch(e => {
                    console.log('error', e)
                    // handleSnackbar({ variant: 'error', message: error.response.data.Message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialogSave}
            onClose={handleDialog}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="md"
            sx={{
                ".MuiBackdrop-root": {
                    backgroundColor: "rgba(111, 126, 140, 0.2)",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                {/* <Typography > Save custom group </Typography> */}
                Save custom group
            </DialogTitle>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                    <DialogContent
                        dividers={true}
                        sx={{
                            // width: theme => theme.breakpoints.up("md") ? "600px" : "100%",
                            width: !fullScreen ? "600px" : "auto",
                            height: "500px",
                            textAlign: "center",
                            padding: "5px",
                            // overflow: "hidden"
                        }}
                    >
                        <Box sx={{
                            padding: "10px"
                        }}>
                            <TextField
                                fullWidth
                                size="small"
                                label={"GROUP ID"}
                                autoFocus
                                {...getFieldProps('groupId')}
                                error={Boolean(touched.groupId && errors.groupId)}
                                helperText={touched.groupId && errors.groupId}
                                autoComplete='off'
                            />
                        </Box>
                        <Box sx={{
                            padding: "10px"
                        }}>
                            <TextField
                                fullWidth
                                size="small"
                                label={"GROUP NAME"}
                                {...getFieldProps('groupName')}
                                error={Boolean(touched.groupName && errors.groupName)}
                                helperText={touched.groupName && errors.groupName}
                                autoComplete='off'
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {/* <Typography >{listSelect.length}</Typography> */}

                        <Button onClick={handleDialog}>Cancel</Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>

                </Form>
            </FormikProvider>
        </Dialog >
    )
}