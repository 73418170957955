import { SCOPES_CRUD, SCOPES_POI } from "./SCOPESPAGES";

export const ROLESPAGE = {
    report: "REPORT",
    account: "ACCOUNT",
    poi: "POI"
};
export const ROLES = {
    viewer: "VIEWER",
    editor: "EDITOR",
    owner: "OWNER"
};

// export const PERMISSIONSROLE = {
//     [ROLES.viewer]: [SCOPES.canView],
//     [ROLES.editor]: [SCOPES.canView, SCOPES.canEdit],
//     [ROLES.owner]: [
//         SCOPES.canView,
//         SCOPES.canEdit,
//         SCOPES.canCreate,
//         SCOPES.canDelete
//     ]
// };

export const ROLESPAGES = {
    poi: {
        title: "POI",
        name: "poi",
    },
    report: {
        title: "REPORT",
        name: "report",
    }
};

export const PERMISSIONPAGEPOI = {
    title: ROLESPAGES.poi.title,
    name: ROLESPAGES.poi.name,
    actions: [
        { name: "Read", value: SCOPES_CRUD.canRead },
        { name: "Create", value: SCOPES_CRUD.canCreate },
        { name: "Edit", value: SCOPES_CRUD.canEdit },
        { name: "Delete", value: SCOPES_CRUD.canDelete },
        { name: "Circle", value: SCOPES_POI.canCreateCircle, ref: [SCOPES_CRUD.canCreate] },
        { name: "Polygon", value: SCOPES_POI.canCreatePolygon, ref: [SCOPES_CRUD.canCreate] },
        { name: "Polyline", value: SCOPES_POI.canCreatePolyline, ref: [SCOPES_CRUD.canCreate] },
        { name: "Rectangle", value: SCOPES_POI.canCreateRectangle, ref: [SCOPES_CRUD.canCreate] }
    ]
}

export const PERMISSIONPAGES = [
    PERMISSIONPAGEPOI
]


export const FORMCONTYPE = [
    {
        name: "SUPERADMIN",
        contype: "00",
        actions: [
            { name: "Read", value: "canRead", type: "boolean" },
            { name: "Create", value: "canCreate", type: "boolean" },
            { name: "Edit", value: "canEdit", type: "boolean" },
            { name: "Delete", value: "canDelete", type: "boolean" }
        ]
    },
    {
        name: "ADMIN",
        contype: "10",
        actions: [
            { name: "Read", value: "canRead", type: "boolean" },
            { name: "Create", value: "canCreate", type: "boolean" },
            { name: "Edit", value: "canEdit", type: "boolean" },
            { name: "Delete", value: "canDelete", type: "boolean" }
        ]
    }, {
        name: "OFFICER",
        contype: "11",
        actions: [
            { name: "Read", value: "canRead", type: "boolean" },
            { name: "Create", value: "canCreate", type: "boolean" },
            { name: "Edit", value: "canEdit", type: "boolean" },
            { name: "Delete", value: "canDelete", type: "boolean" }
        ]
    }, {
        name: "DRIVER",
        contype: "21",
        actions: [
            { name: "Read", value: "canRead", type: "boolean" },
            { name: "Create", value: "canCreate", type: "boolean" },
            { name: "Edit", value: "canEdit", type: "boolean" },
            { name: "Delete", value: "canDelete", type: "boolean" }
        ]
    }, {
        name: "SALES",
        contype: "31",
        actions: [
            { name: "Read", value: "canRead", type: "boolean" },
            { name: "Create", value: "canCreate", type: "boolean" },
            { name: "Edit", value: "canEdit", type: "boolean" },
            { name: "Delete", value: "canDelete", type: "boolean" }
        ]
    }
]