import styled from '@emotion/styled';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Divider, Grid, TextField, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const listTableHead = [
    {
        title: 'วันที่',
    },
    {
        title: 'ทะเบียนรถ',
    },
    {
        title: 'หมายเลขรถ',
    },
    {
        title: 'สายรถ',
    },
    {
        title: 'รูดบัตรเข้า',
    },
    {
        title: 'รูดบัตรออก',
    },
    {
        title: 'ไม่รูดบัตร',
    },

]

export default function CardSwipeTotalByEvents({ account, userId }) {
    const navigate = useNavigate()
    // const [groups, setGroups] = useState([])
    const [overspeeds, setOverspeeds] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // useEffect(() => {
    //     Api.get(`/Tracking/DeviceGroup?userid=${userId}`)
    //         .then(res => {
    //             var list = res.data.sort((a, b) => a.name.localeCompare(b.name));
    //             setGroups(list)
    //         }
    //         ).catch(e => {
    //             console.log('error', e)
    //         })
    // }, [userId])

    const schema = Yup.object().shape({

    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            groupsid: null,
            // speed: 60,
            date_s: selectedTime.setHours(0, 0, 0),
            date_e: selectedTime.setHours(23, 59, 59),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { groupsid, date_s, date_e,
                // speed 
            } = values
            const { c_coname } = account;

            Api.get('Tracking/CardSwipeTotalByEvents', {
                params: {
                    // cocode: cocode,
                    cname: c_coname,
                    dateStart: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
                    dateEnd: fDateTo(date_e, 'yyyy-MM-dd HH:mm'),
                    // speed: speed
                }
            }).then((res) => {
                const { data } = res
                setOverspeeds(data)
                setPage(0)
                setSubmitting()
            }).catch((e) => {
                setOverspeeds([])
                setPage(0)
                setSubmitting()

            })
        }
    })


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
    return (
        <Page title="Card swipe total by date">
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event : Card swipe total by date</Typography>
                </Box>
            </Container>
            <Divider />
            <MainStyle>
                <Container sx={{ padding: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={6}>
                                        <MobileDateTimePicker
                                            ampm={false}
                                            disableFuture={true}
                                            label="วันเวลาเริ่ม"
                                            allowKeyboardControl={false}
                                            value={values.date_s}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(val) => {
                                                setFieldValue('date_s', val);
                                                setFieldValue('date_e', new Date(val).setHours(23, 59, 59));
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_r')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_s && errors.date_s)}
                                                helperText={touched.date_s && errors.date_s} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <MobileDateTimePicker
                                            minDate={new Date(values.date_s)}
                                            maxDate={new Date(values.date_s).setDate(new Date(values.date_s).getDate() + 7)}
                                            ampm={false}
                                            disableFuture={true}
                                            label="ถึงวันเวลา"
                                            allowKeyboardControl={false}
                                            value={values.date_e}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(val) => {
                                                setFieldValue('date_e', val);
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_e')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_e && errors.date_e)}
                                                helperText={touched.date_e && errors.date_e} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} justifyContent="flex-end" justifyItems="center">
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <LoadingButton
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}

                                            >
                                                ค้นหา
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Button
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="info"
                                                onClick={() => {
                                                    navigate('/report/event')
                                                }}

                                            >
                                                กลับ
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Container>
                {
                    overspeeds.length > 0 && (
                        <Container sx={{ padding: 3 }}>
                            <Box sx={{ padding: 1 }}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Button as={CSVLink} filename={`${fDateTo(values.date_s, 'dd_MM_yyyy_HH:mm')}-${fDateTo(values.date_e, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                                    overspeeds.map((value, index) => {
                                        const {
                                            vehicle_id, car_id, group_id, date, LOGIN, LOGOUT, LOGNO
                                        } = value;
                                        return {
                                            "วันที่": `=""${fDateTo(new Date(moment(date, "DD-MM-YYYY")), 'dd/MM/yyyy')}""`,
                                            "ทะเบียนรถ": `=""${vehicle_id}""`,
                                            "หมายเลขรถ": `=""${car_id}""`,
                                            "สายรถ": `=""${group_id}""`,
                                            "รูดบัตรเข้า": `=""${LOGIN}""`,
                                            "รูดบัตรออก": `=""${LOGOUT}""`,
                                            "ไม่รูดบัตร": `=""${LOGNO}""`
                                        }
                                    }
                                    )
                                } variant="contained" style={{
                                    textDecorationLine: 'none',
                                }} >
                                    Download
                                </Button>
                            </Box>
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            {
                                                listTableHead.map((item, index) => {
                                                    return <TableCell style={{ textTransform: 'capitalize' }} key={index} >{item.title}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            overspeeds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => {
                                                const {
                                                    vehicle_id, car_id, group_id, date, LOGIN, LOGOUT, LOGNO
                                                } = value;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell>{fDateTo(new Date(moment(date, "DD-MM-YYYY")), 'dd/MM/yyyy')}</TableCell>
                                                        <TableCell>{vehicle_id}</TableCell>
                                                        <TableCell>{car_id}</TableCell>
                                                        <TableCell>{group_id}</TableCell>
                                                        <TableCell>{LOGIN}</TableCell>
                                                        <TableCell>{LOGOUT}</TableCell>
                                                        <TableCell>{LOGNO}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[
                                    { value: 10, label: '10' },
                                    { value: 20, label: '20' },
                                    { value: 30, label: '30' },
                                    { value: 50, label: '50' },
                                    // { value: 100, label: 100 },
                                    // { value: listLog.length, label: 'All' }
                                ]}
                                component="div"
                                count={overspeeds.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Container>
                    )}
            </MainStyle>
        </Page>
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));