import { Container, Box, Menu, MenuItem, useMediaQuery, IconButton, } from '@mui/material';
import { MapContainer, Marker, TileLayer, ZoomControl } from 'react-leaflet'
import { divIcon, icon } from 'leaflet'
// import ic from '../../img/car.png'
import icFocus from '../../img/car_icon_focus.svg'
import icOffline from '../../img/car_icon_offline.svg'
import ic from '../../img/car_icon.svg'
import { iconCar } from '../../img/PinCar';

// components
import Page from '../../components/Page';
import React, { useEffect, useRef, useState } from 'react';
import mqtt from 'mqtt';
import { styled } from '@mui/system';
import LayersIcon from '@mui/icons-material/Layers';
import { makeStyles } from '@mui/styles';
import SlideBarMap from './SlideBarMap';
import DashboardSelect from './DashboardSelect';
import IsMarker from './IsMarker';
import { Api } from '../../components/api';
import ListTileLayer from '../../layouts/ListTileLayer';



const useStyles = makeStyles(theme => ({
  DashboardSelect: {
    position: 'absolute',
    bottom: 65,
    zIndex: 2,
    left: "2%",
    right: "2%",
    [theme.breakpoints.up('md')]: {
      bottom: 10,
      left: 420,
      right: "2%",
    },
    [theme.breakpoints.up('lg')]: {
      bottom: 10,
      left: 420,
      right: 420,
    },
  },
  LayerControl: {
    position: 'fixed',
    display: 'block',
    backdropFilter: 'blur(10px)',
    border: "2px solid rgba(0,0,0,0.2)",
    borderRadius: 5,
    top: 90,
    right: 10,
    width: 32,
    height: 32,
    zIndex: 2,
  }
}));

const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  overflow: 'hidden',
  zIndex: 1,
}));


export default function MapComponent({ listDataMqtt, client, userId, getFetchData }) {

  const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")


  const center = [13.839660198254604, 100.63469639884744];
  const zoom = 5;

  const [map, setmap] = useState(null);
  const [select, setSelect] = useState(null);
  const [filterGroup, setFilterGroup] = useState('')
  const [filterList, setFilterList] = useState([])

  // const [expanded, setExpanded] = useState(true);
  const [openDropDown, setDropDown] = useState({ mapStyle: false, account: false, tune: false });
  const [query, setQuery] = useState("");
  useEffect(() => {
    const list = filterGroup !== '' ?
      listDataMqtt.filter((val) => val.name.toUpperCase().includes(query.toUpperCase())).filter((val) => {
        const isFind = val.group.filter(a => a.group_id === filterGroup)
        if (isFind.length > 0) {
          return val
        }
        return
      })
      : listDataMqtt.filter((val) => val.name.toUpperCase().includes(query.toUpperCase()))
    setFilterList(list)
  }, [filterGroup, listDataMqtt, query])

  const handleOpenDropDown = (name) => {
    setDropDown({
      ...openDropDown,
      [name]: !openDropDown[name]
    });
  };

  const IconFocus = icon({
    iconUrl: icFocus,
    iconSize: [40, 40],
    iconAnchor: [19.5, 20],
    popupAnchor: [19.5, -20]
  });
  const IconOffline = icon({
    iconUrl: icOffline,
    iconSize: [40, 40],
    iconAnchor: [19.5, 20],
    popupAnchor: [19.5, -20]
  });
  const Icon = icon({
    iconUrl: ic,
    iconSize: [40, 40],
    iconAnchor: [19.5, 20],
    popupAnchor: [19.5, -20]
  });
  const refMapStyle = useRef(null);
  const refDropDownMapStyle = useRef(null);
  const classes = useStyles();
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const IconMarker = ({ className, icon, bearing }) => {

    return divIcon({
      className: className,
      html: `
  <img src="${icon}" width="20" class="icon-car-center" style="
  transform: rotate(${(360 - bearing)}deg);
  " />
      `,
      iconSize: [40, 40],
      iconAnchor: [19.5, 20],
      popupAnchor: [19.5, -20]
    })
  }

  const LabelMaker = ({ className, name }) => {

    return divIcon({
      className: className,
      html: `
        <div style="background-color: white;text-align: center;border-radius: 10px;border: 2px solid whitesmoke;height: 35px;font-weight: bold;padding-top:8px;white-space: nowrap;padding-left:8px;padding-right:8px;">${name}</div>
      `,
      iconSize: 'auto',
      // iconSize: [145, 40],
      iconAnchor: [((name.length * 9) / 2), 55],
      // popupAnchor: [70, -40]
    })
  }

  const listTileLayer = ListTileLayer


  const getDataIndex = () => {
    const index = filterList.findIndex(values => values.device === select)
    return index
  }

  return (
    <Page title="Map">
      <Container maxWidth={false} style={{ padding: 0, overflow: 'hidden' }}>
        {/* ---------------------------------------------------------------------- */}
        <SlideBarMap
          client={client}
          map={map}
          dataList={filterList}
          onSelect={(event) => {
            setSelect(event)
          }}
          isFullScreen={isFullScreen}
          // isSelect={select}
          isSelect={getDataIndex()}
          userId={userId}
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
          setQuery={setQuery}
        />

        <Box className={classes.LayerControl} sx={{ justifyContent: 'center' }}
          ref={refDropDownMapStyle}
        >
          <IconButton edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={() => {
              handleOpenDropDown('mapStyle')
            }}>
            <LayersIcon fontSize="inherit" />
          </IconButton>
          <Menu
            open={openDropDown.mapStyle}
            anchorEl={refDropDownMapStyle.current}
            onClose={() => handleOpenDropDown('mapStyle')}
            PaperProps={{
              sx: { width: 200, maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {
              listTileLayer.map((value, index) =>
                <MenuItem
                  sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                  onClick={
                    () => {
                      handleOpenDropDown('mapStyle')
                      if (refMapStyle.current) {
                        refMapStyle.current.setUrl(value.url);
                        setSelectedMapStyle(value.id)
                        localStorage.setItem('selectedMapStyle', value.id)
                      }
                    }
                  }
                  key={'MenuItem' + index}
                >
                  {
                    value.name
                  }
                </MenuItem>
              )
            }
          </Menu>
        </Box>
        <MapContainerStyle
          whenCreated={setmap}
          center={center}
          zoom={zoom}
          minZoom={3}
          zoomControl={false}
          scrollWheelZoom={true}
          doubleClickZoom={false}
          attributionControl={false}
        // boundsOptions={{padding: [50, 50]}}
        // bounds={}
        // bounds={center}
        // boundsOptions={{paddingTopLeft: [0, 320]}}
        >
          <TileLayer ref={refMapStyle}
            url={listTileLayer.find(value => value.id === selectedMapStyle).url}
            maxNativeZoom={19}
            maxZoom={22}

          />
          <ZoomControl position='topright' />

          {
            filterList.map((_, index) =>
            (
              <React.Fragment key={'markersFragment' + index}>
                <IsMarker key={'marker' + index} onClick={() => {
                  setSelect(null)
                  map.flyTo([_.latitude, _.longitude], 19, {
                    "animate": true,
                    "duration": 1,
                  })
                  setSelect(_.device)
                }}
                  icon={index === getDataIndex() ? IconMarker({ className: "icon-car-focus", icon: _.icon, bearing: _.bearing }) : new Date(_.datetime) < new Date().setMinutes(new Date().getMinutes() - 5) ? IconMarker({ className: 'icon-car-offline', icon: _.icon, bearing: _.bearing }) : IconMarker({ className: 'icon-car', icon: _.icon, bearing: _.bearing })}
                  data={_}
                  selected={index === getDataIndex()}
                />
                <Marker
                  key={'markers' + index}
                  eventHandlers={{
                    click: () => {
                      setSelect(null)
                      map.flyTo([_.latitude, _.longitude], 19, {
                        "animate": true,
                        "duration": 1,
                      })
                      setSelect(_.device)
                    }
                  }}
                  icon={LabelMaker({ className: index === getDataIndex() ? 'label-maker-focus' : 'label-maker', name: _.name })}
                  position={[_.latitude, _.longitude]}
                >

                </Marker>
              </React.Fragment>
            ))
          }
        </MapContainerStyle>
        {/* ---------------------------------------------------------------------- */}
        {
          select !== null && (
            <Box className={classes.DashboardSelect}>
              <div
                style={{
                  // margin: "auto",
                  minWidth: "100%",
                  // backdropFilter: 'blur(4px)',
                  backgroundColor: "white",
                  borderRadius: 5
                }}
              >
                <DashboardSelect
                  onClick={
                    () => {
                      map.setView([filterList[getDataIndex()].latitude, filterList[getDataIndex()].longitude], map.getZoom(), {
                        "animate": true,
                        "duration": 1,
                      })
                    }
                  }
                  onClose={
                    () => {
                      setSelect(null)
                    }
                  }
                  centerFocus={true}
                  data={filterList[getDataIndex()]}
                  mapControl={map}
                  devices={listDataMqtt.map((values => {
                    return { device: values.device, name: values.name }
                  }))}
                  getFetchData={getFetchData}
                  userId={userId}
                />
              </div>
            </Box>
          )}
        {/* ---------------------------------------------------------------------- */}
      </Container>
    </Page >
  );
}