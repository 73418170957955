import * as Yup from 'yup';
import React, { useState } from 'react';
// material
import {
    Button,
    Divider,
    TextField,
    Container,
    Collapse,
    ListItemButton,
    List,
    ListSubheader,
    ListItemText,
    Grid
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

// components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Page from '../../components/Page';
import { Api } from '../../components/api';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



export default function GeofencesNew({ account }) {

    const [open, setOpen] = useState(true);
    // const [initialValues, setInitialValues] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };
    const handleClick = () => {
        setOpen(!open);
    };



    const LoginSchema = Yup.object().shape({
        poi_id: Yup.string().required('Id is required'),
        poi_name: Yup.string().required('Name is required'),
    });

    const formik = useFormik({
        initialValues: {
            poi_id: '',
            poi_name: ''
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting }) => {
            const { state } = location
            const { cocode, c_coname } = account
            // const { coordinates } = state
            // const newCoordinates = [
            //     [
            //         ...coordinates[0],
            //         coordinates[0][0]
            //     ],
            // ]
            const data = {
                cocode: cocode,
                c_coname: c_coname,
                ...values,
                poi_area: JSON.stringify({ ...state })
                // poi_area: JSON.stringify({ ...state, coordinates: newCoordinates })
            }
            Api.post('/Tracking/PoiStation', data)
                .then(res => {
                    handleOnClose()
                    handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                    setSubmitting()
                }
                ).catch(error => {
                    const { message } = error
                    handleSnackbar({ variant: 'error', message: message });
                    setSubmitting()
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;
    const handleOnClose = () => {
        navigate('/settings/geofences');
        // setErrors({});
        // resetForm();
    }
    return (
        <Page title="geofences">
            <Container>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                        <Box width={400} margin="auto" padding={2} >
                            <List
                                sx={{ width: '100%', maxWidth: 320, bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                            >
                                <ListItemButton onClick={handleClick}>
                                    <ListItemText primary="Required" />
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box padding={2} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Id"
                                                    {...getFieldProps('poi_id')}
                                                    error={Boolean(touched.poi_id && errors.poi_id)}
                                                    helperText={touched.poi_id && errors.poi_id}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    label="Name"
                                                    {...getFieldProps('poi_name')}
                                                    error={Boolean(touched.poi_name && errors.poi_name)}
                                                    helperText={touched.poi_name && errors.poi_name}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                                <Divider style={{ marginTop: 10 }} />

                                <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                    <Grid item xs={6} justifyContent="center">
                                        <Button
                                            fullWidth
                                            onClick={handleOnClose}
                                            size="medium"
                                            variant="outlined"
                                            color="info"
                                        // fullWidth
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} justifyContent="center">
                                        <LoadingButton
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            loading={isSubmitting}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </List>
                        </Box>
                    </Form>
                </FormikProvider>
            </Container>
        </Page >
    );
}
