import styled from '@emotion/styled';
import { Autocomplete, Box, Collapse, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Container, Divider, Grid, List, ListItemButton, Button, ListItemText, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function NewAccount({ account }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const [open, setOpen] = useState({ required: true, conType: true })
    const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;

    const DeviceSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        userid: Yup.string().required('userid is required'),
        password: Yup.string().min(6, 'must be at least 6 characters').required('Password is required'),
        cpassword: Yup.string().min(6, "must be at least 6 characters").required('Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last Name is required'),
        birthday: Yup.string().required('Birthday is required'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
        conType: Yup.string().required('contype name is required'),

    })
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            cpassword: '',
            firstname: '',
            lastname: '',
            userid: '',
            gender: '',
            phone: '',
            birthday: new Date(),
            conType: "00"
        },
        validationSchema: DeviceSchema,
        onSubmit: async (values) => {
            const data = JSON.stringify({
                contype: "00",
                firstname: values.firstname,
                lastname: values.lastname,
                passportid: "",
                ThaiID: "",
                phone: values.phone,
                sex: values.gender,
                birthday: format(
                    new Date(values.birthday),
                    'yyyy-MM-dd'
                ),
                email: values.email,
                userid: values.userid,
                password: values.password,
                temp: "",
                status: "U"
            });
            await Api.post('v0/app/Contact', data)
                .then(res => {
                    navigate('/settings/accounts')
                    handleSnackbar({ variant: 'success', message: 'success!' })
                }
                ).catch(error => {
                    const { message } = error
                    handleSnackbar({ variant: 'error', message: message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const handleClick = (name) => {
        setOpen({ ...open, [name]: !open[name] });
    };
    const handleOnClose = () => {
        navigate('/settings/accounts')
    }


    const modelEray = "Corvette E-Ray COUPE, 3LZ, eAWD"
    const model_Z06 = "Corvette Z06"
    const model_Z07 = "Corvette Z06 Z07 performance package"

    
    return (
        <Page title="account" >
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Account</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Box margin="auto" padding={2}
                                // border={1} borderRadius={1}
                                >
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={() => {
                                            handleClick("required")
                                        }} >
                                            <ListItemText primary="ข้อมูลผู้ใช้งาน" />
                                            {
                                                open.required ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />
                                            }
                                        </ListItemButton>
                                        <Collapse in={open.required} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ชื่อ"
                                                            {...getFieldProps('firstname')}
                                                            error={Boolean(touched.firstname && errors.firstname)}
                                                            helperText={touched.firstname && errors.firstname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="นามสกุล"
                                                            {...getFieldProps('lastname')}
                                                            error={Boolean(touched.lastname && errors.lastname)}
                                                            helperText={touched.lastname && errors.lastname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <MobileDatePicker
                                                            disableFuture={true}
                                                            label="วันเกิด"
                                                            allowKeyboardControl={false}
                                                            value={values.birthday}
                                                            inputFormat="dd/MM/yyyy"
                                                            format="dd/MM/yyyy"
                                                            onChange={(val) => {
                                                                setFieldValue('birthday', val);
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder="DD/MM/YYYY"
                                                                    autoComplete="off"
                                                                    {...getFieldProps('birthday')}
                                                                    error={Boolean(touched.birthday && errors.birthday)}
                                                                    helperText={touched.birthday && errors.birthday}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <RadioGroup
                                                            {...getFieldProps('gender')}
                                                            row
                                                        >
                                                            <FormControlLabel value="M" control={<Radio size="small" />} label="ชาย" />
                                                            <FormControlLabel value="F" control={<Radio size="small" />} label="หญิง" />
                                                            <FormControlLabel value={''} control={<Radio size="small" />} label="อื่นๆ" />
                                                        </RadioGroup>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            inputMode="tel"
                                                            label="Phone"
                                                            {...getFieldProps('phone')}
                                                            error={Boolean(touched.phone && errors.phone)}
                                                            helperText={touched.phone && errors.phone}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="Email"
                                                            inputMode="email"
                                                            {...getFieldProps('email')}
                                                            error={Boolean(touched.email && errors.email)}
                                                            helperText={touched.email && errors.email}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ชื่อผู้ใช้งาน"
                                                            {...getFieldProps('userid')}
                                                            error={Boolean(touched.userid && errors.userid)}
                                                            helperText={touched.userid && errors.userid}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="password"
                                                            label="รหัสผ่าน"
                                                            {...getFieldProps('password')}
                                                            error={Boolean(touched.password && errors.password)}
                                                            helperText={touched.password && errors.password}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="password"
                                                            label="ยืนรหัสผ่าน"
                                                            {...getFieldProps('cpassword')}
                                                            error={Boolean(touched.cpassword && errors.cpassword)}
                                                            helperText={touched.cpassword && errors.cpassword}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        <ListItemButton onClick={() => {
                                            handleClick("conType")
                                        }} >
                                            <ListItemText primary="ประเภทผู้ใช้งาน" />
                                            {
                                                open.conType ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.conType} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ประเภท"
                                                            {...getFieldProps('conType')}
                                                            error={Boolean(touched.conType && errors.conType)}
                                                            helperText={touched.conType && errors.conType}
                                                            select
                                                            value={values.conType}
                                                        >
                                                            <MenuItem value={"00"}>Super Admin</MenuItem>
                                                            <MenuItem value={"10"}>Admin</MenuItem>
                                                            <MenuItem value={"11"}>User</MenuItem>
                                                            {/* <MenuItem value={"12"}>Driver</MenuItem> */}
                                                            <MenuItem value={"21"}>Customer</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        {/* <ListItemButton onClick={() => {
                                            handleClick("extra")
                                        }} >
                                            <ListItemText primary="Extra" />
                                            {
                                                open.extra ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.extra} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="extra"
                                                            {...getFieldProps('extra')}
                                                            error={Boolean(touched.extra && errors.extra)}
                                                            helperText={touched.extra && errors.extra}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} /> */}
                                        <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                            <Grid item xs={6} justifyContent="end" justifyItems="end" display="flex">
                                                <LoadingButton
                                                    // fullWidth
                                                    sx={{ width: "140px" }}
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    color="info"
                                                    loading={isSubmitting}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item xs={6} justifyContent="center">
                                                <Button
                                                    // fullWidth
                                                    sx={{ width: "140px" }}
                                                    onClick={handleOnClose}
                                                    size="medium"
                                                    variant="outlined"
                                                    color="info"
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </MainStyle>
            </Container>
        </Page>
    )
}
