import * as React from 'react';
import * as L from 'leaflet';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import "leaflet-draw/dist/leaflet.draw-src.css";

export default function EditControlPOI({ poiSelect, setEditRef, handleCreated, refControlPOI, editResize, editMove }) {
    React.useEffect(() => {
        L.Draw.Polyline.prototype._onTouch = L.Util.falseFn;
    }, [])
    React.useEffect(() => {
        const GeoJSONeachLayer = () => {
            L.geoJSON(poiSelect, {
                pointToLayer: function (feature, latlng) {
                    return L.circle(latlng, {
                        radius: Math.floor(feature.properties.radius),
                        // units: 'meters',
                    })
                    // .setStyle({ color: '#9FB398' });

                },
                style: { color: '#9FB398' },
            }).eachLayer((layer) => {
                // console.log('layer', layer.feature.properties.shape = "rectangle")
                if (layer.feature.properties.shape === "rectangle") {
                    // console.log('layer', layer.feature)
                    const rectangleLayer = L.rectangle(layer.getBounds()).setStyle({ color: '#9FB398' });
                    refControlPOI.current?.addLayer(rectangleLayer)
                    return
                    // refControlPOI.current?.addLayer(rectangleLayer)
                }

                if (layer.feature.geometry.type === "LineString") {
                    // console.log('layer', layer.feature)
                    const rectangleLayer = L.polyline(layer.getLatLngs()).setStyle({ color: '#9FB398' });
                    refControlPOI.current?.addLayer(rectangleLayer)
                    return
                    // refControlPOI.current?.addLayer(rectangleLayer)
                }

                if (layer.feature.properties.shape === "polygon") {
                    // console.log('layer', layer.feature)
                    const polygonLayer = L.polygon(layer.getLatLngs()).setStyle({ color: '#9FB398' });
                    refControlPOI.current?.addLayer(polygonLayer)
                    return
                    // refControlPOI.current?.addLayer(rectangleLayer)
                }

                refControlPOI.current?.addLayer(layer)
            });
        }

        if (refControlPOI.current?.getLayers().length === 0 && poiSelect) {
            GeoJSONeachLayer()
        } else {
            refControlPOI.current.clearLayers()
            // ref.current?.clearLayers()
            GeoJSONeachLayer()
        }
    }, [poiSelect, refControlPOI]);

    const [handleRadius, setHandleRadius] = React.useState(0)


    const handleEditResize = () => {

    }

    return (
        <FeatureGroup ref={refControlPOI}>
            <EditControl
                onMounted={(ref) => {
                    setEditRef(ref)
                }}
                draw={
                    {
                        polygon: {
                            guidelineDistance: 10,
                        },
                        // circle: {
                        //     metric: true,
                        //     feet: false
                        // }
                    }
                }
                onCreated={(e) => {
                    // if (ref.current?.getLayers().length !== 0) {
                    //     ref.current.clearLayers()
                    // }
                    if (e.layerType === "circle") {
                        const circle = e.layer.toGeoJSON()
                        const _circle = { ...circle, properties: { ...circle.properties, "shape": "circle", radius: e.layer.getRadius() } }
                        // const _circle = { ...circle, "featureid": "001", properties: { ...circle.properties, id: Date.now(), radius: e.layer.getRadius() } }
                        handleCreated(_circle)

                        return
                    }

                    if (e.layerType === "polyline") {
                        const polyline = e.layer.toGeoJSON()
                        const _polyline = { ...polyline, properties: { ...polyline.properties, "shape": "polyline" } }
                        handleCreated(_polyline)
                        // console.log('lineString', lineString) 
                        return
                    }
                    if (e.layerType === "rectangle") {
                        const rectangle = e.layer.toGeoJSON()
                        const _rectangle = { ...rectangle, properties: { ...rectangle.properties, "shape": "rectangle" } }
                        handleCreated(_rectangle)
                        // console.log('rectangle', _rectangle)
                        return
                    }
                    if (e.layerType === "polygon") {
                        const rectangle = e.layer.toGeoJSON()
                        const _rectangle = { ...rectangle, properties: { ...rectangle.properties, "shape": "polygon" } }
                        handleCreated(_rectangle)
                        console.log('rectangle', _rectangle)
                        return
                    }
                    // const Nlayer = e.layer.toGeoJSON()
                    // const _Nlayer = { ...Nlayer, properties: { ...Nlayer.properties } }
                    // handleCreated(_Nlayer)
                    // handleCreated(e.layer.toGeoJSON())


                }}
                onEditMove={(e) => {

                    if (typeof e.layer.getLatLng === 'function') {
                        const latLng = e.layer.getLatLng()
                        editMove([latLng.lng, latLng.lat])
                        return
                    }

                    if (typeof e.layer.getLatLngs === 'function') {
                        // const latLng = e.layer.getLatLngs()
                        // editMove([latLng.lng, latLng.lat])
                        const latLngs = e.layer.toGeoJSON().geometry.coordinates
                        editMove(latLngs)
                        // console.log('onEditMove', latLngs)
                        return
                    }

                    // const check = typeof e.layer.getLatLng
                    // console.log('onEditMove', check === 'function')

                }}
                onEditResize={(e) => {
                    if (typeof e.layer.getRadius === 'function') {
                        const radius = e.layer.getRadius()
                        editResize(Math.floor(radius))
                        return
                    }

                    if (typeof e.layer.getLatLngs === 'function') {
                        // const latLng = e.layer.getLatLngs()
                        // editMove([latLng.lng, latLng.lat])
                        const latLngs = e.layer.toGeoJSON().geometry.coordinates
                        editMove(latLngs)

                        // console.log('onEditResize', e.layer.toGeoJSON().geometry.coordinates)
                        return
                    }
                }}
                onEditVertex={e => {
                    // const Nlayer = e.layer.toGeoJSON()
                    // const _Nlayer = { ...Nlayer, properties: { ...Nlayer.properties } }
                    // console.log('onEditVertex', e.poly.toGeoJSON())
                    const latLngs = e.poly.toGeoJSON().geometry.coordinates
                    editMove(latLngs)
                }}
            // onEdited={(e) => {
            //     // if (ref.current?.getLayers().length !== 0) {
            //     //     ref.current.clearLayers()
            //     // }
            //     e.layers?.eachLayer(a => {
            //         const _a = a.toGeoJSON()
            //         if (_a.geometry.type === "Point") {
            //             const __a = { ..._a, properties: { ..._a.properties, radius: a.getRadius() } }
            //             handleEdited(__a)
            //             return
            //         } else {
            //             handleEdited(_a)
            //         }
            //     });
            // }}
            />
        </FeatureGroup>
    );
}