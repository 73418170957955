import { Stack, Box, Grid, Dialog, DialogTitle, Divider, IconButton, Typography, Collapse, Badge, TextField, Button, Paper, TableContainer, Table, TableRow, TableHead, TableCell, TableBody } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
// import bgSvg from '../img/bgSvg.svg'
import Gauge from './Guage';

import * as Yup from 'yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { fDateTo } from '../../utils/formatTime';
import styled from '@emotion/styled';
import FilterCenterFocusRoundedIcon from '@mui/icons-material/FilterCenterFocusRounded';
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/Edit';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import { Api } from '../../components/api';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { useSnackbar } from 'notistack';
import { ControlCenterFocus } from './ControlMap';
import NavigationRoundedIcon from '@mui/icons-material/NavigationRounded';
import { TableVirtuoso } from 'react-virtuoso'
import { Dashboard, Download, Handyman, HandymanSharp } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import PlayReplay from './PlayReplay';
import PolylineUtil from 'polyline-encoded';
import SpeedChart from './SpeedChart';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export default function CardDetail({ data, onClose, userId, onMove, centerFocus, onNavigation, setIsPolyline, map, markerSelect, setMarkerSelect, cocode, onClickEvent }) {




    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };


    const [openInfo, setOpenInfo] = useState(false)
    const [openHandyman, setOpenHandyman] = useState(false)
    // const [centerFocus, setCenterFocus] = useState(localStorage.getItem("centerFocus") === "false" ? false : true)
    // const [centerFocus, setCenterFocus] = useState(true)
    const [dataInfo, setDataInfo] = useState(null)
    const [expanded, setExpanded] = useState(true)


    const [listTables, setListTables] = useState([])
    const [listEvents, setListEvents] = useState([]);


    const handleGetReplay = ({ startDate, endDate }) => {
        Api.get('/tracking/history', {
            params: {
                device: data.device,
                dateStart: startDate,
                dateEnd: endDate
                // dateStart: fDateTo(values.dateStart, 'yyyy-MM-dd HH:mm:ss'),
                // dateEnd: fDateTo(values.dateEnd, 'yyyy-MM-dd HH:mm:ss')
            }
        }).then((res) => {
            const { encode, gpslog } = res.data
            if (encode) {
                setListTables(gpslog)
                setSliderValue(0)
                setMarkerSelect(null)
                const _encode = PolylineUtil.decode(encode)
                setIsPolyline(_encode)
                map.flyToBounds(_encode, { duration: 1, paddingTopLeft: [8, 8] })
                // setListReplay(_encode);
            } else {
                // handleSnackbar({ message: 'Not found', variant: 'error' })
                // setLoading(false)
                // setSubmitting()
            }
        }).catch((e) => {

        });
    }


    const handleGetEvent = ({ startDate, endDate }) => {
        Api.get('/tracking/events', {
            params: {
                device: data.device,
                dateStart: startDate,
                dateEnd: endDate
                // dateStart: fDateTo(values.dateStart, 'yyyy-MM-dd HH:mm:ss'),
                // dateEnd: fDateTo(values.dateEnd, 'yyyy-MM-dd HH:mm:ss')
            }
        }).then((res) => {
            setListEvents(res.data);
            onClickEvent(null)
        }).catch((e) => {

        });
    }



    // const handleCenterFocus = () => {
    //     const update = !centerFocus
    //     setCenterFocus(update)
    //     localStorage.setItem('centerFocus', String(update))
    // }
    const handleCloseInfo = () => {
        setOpenInfo(!openInfo)
    }
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    const handleGetInfo = () => {
        Api.get(`Tracking/DevicesInfo?device=${data.device}`)
            .then(res => {
                setDataInfo(res.data);
                handleCloseInfo()
            }
            ).catch(error => {
                console.log('error', error)
            }
            )

    }


    const handleCloseHandyman = () => {
        setOpenHandyman(!openHandyman)
    }
    const handleGetHandyman = () => {
        Api.get(`Tracking/DevicesInfo?device=${data.device}`)
            .then(res => {
                setDataInfo(res.data);
                handleCloseHandyman()
            }
            ).catch(error => {
                console.log('error', error)
            }
            )

    }

    const [openRename, setOpenRename] = useState(false)
    const handleRename = () => {
        setOpenRename(!openRename)
    }
    const { speed, name, datetime, icon } = data
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)',
        // marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));


    const DeviceSchema = Yup.object().shape({
        name: Yup.string().required('Device Name is required'),
    })
    const formik = useFormik({
        initialValues: {
            name: data.name,
        },
        enableReinitialize: true,
        validationSchema: DeviceSchema,
        onSubmit: (values) => {
            // handleOnEditName(values.name)
            handleRename()
            const dataSend = JSON.stringify({
                userid: userId,
                device: data.device,
                name: values.name
                // name: "test"
            });
            Api.put('/Tracking/RenameDevices', dataSend)
                .then(res => {
                    handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                }
                ).catch(error => {
                    console.log('error', error)
                })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;

    const [zoom, setZoom] = useState(true)
    useEffect(() => {
        if (centerFocus) {
            // if (zoom) {
            //     onMove(true)
            //     setZoom(false)
            //     return
            // }
            onMove()
            return
        }
        if (zoom) {
            // if (zoom) {
            //     onMove(true)
            //     setZoom(false)
            //     return
            // }
            onMove()
            setZoom(false)
            return
        }

        //  else {
        //     // if (zoom) {
        //     //     onMove(true)
        //     //     setZoom(false)
        //     //     return
        //     // }
        //     return
        // }
        // if()
    }, [centerFocus, zoom, data])



    const [switchForm, setSwitchForm] = useState("dashbord")


    const selectedTime = new Date();


    const getDateMinus = (value) => {
        const dateTime = new Date();

        const date = new Date(dateTime.setDate(dateTime.getDate() - value))
        return date
    }


    const virtuoso = useRef(null);
    const [sliderValue, setSliderValue] = useState(0)
    const [move, setMove] = useState(false)
    const [speeds, setspeed] = useState([])
    // const [markerSelect, setMarkerSelect] = useState(null)




    const handleGetSpeed = ({ date_s, date_e }) => {
        Api.get('/v0/gps/gps_log', {
            params: {
                cocode: cocode,
                device: data.device,
                StartTime: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
                EndTime: fDateTo(date_e, 'yyyy-MM-dd HH:mm')
            }
        })
            .then(res => {
                var list = res.data;
                const speeds = list.map(values => {
                    return { speed: values.speed, time: values.datetime_gps }
                })
                setspeed(speeds)
            }
            ).catch(e => {
                setspeed([])
            });
    }

    return (
        <Box>
            <Box
                sx={theme => ({
                    flexGrow: 1,
                    p: 1,
                    position: 'absolute',
                    top: 10,
                    zIndex: 2,
                    left: "40px",
                    right: "40px",
                    [theme.breakpoints.down('md')]: {
                        top: 10,
                        left: "40px",
                        right: "40px"
                    },
                    // backgroundColor: "white"
                })}
            >
                <PlayReplay listTables={listTables} virtuoso={virtuoso} sliderValue={sliderValue} setSliderValue={setSliderValue} setMove={setMove} markerSelect={markerSelect} setMarkerSelect={setMarkerSelect} />
            </Box>
            <Box
                sx={theme => ({
                    flexGrow: 1,
                    p: 1,
                    position: 'absolute',
                    bottom: 10,
                    zIndex: 2,
                    left: "2%",
                    right: "2%",
                    [theme.breakpoints.up('md')]: {
                        bottom: 10,
                        // left: "410px",
                        // right: "10px",
                    },
                    backgroundColor: "white"
                    // borderRadius: 1,
                    // border: 1,
                    // width: 450
                    // backgroundImage: `url(${bgSvg})`,
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Typography fontWeight={600} >{name}</Typography>
                        <IconButton onClick={handleRename} size="small">
                            <EditIcon fontSize="small" />
                        </IconButton>

                        {switchForm === "replay" && (
                            <Stack direction="row" spacing={1} >
                                <FormCustom handleGetData={handleGetReplay} getDateMinus={getDateMinus} />
                            </Stack>
                        )}

                        {switchForm === "events" && (
                            <Stack direction="row" spacing={1} >
                                <FormCustom handleGetData={handleGetEvent} getDateMinus={getDateMinus} />
                            </Stack>
                        )}

                        {switchForm === "speed" && (
                            <FormTextField handle={({ startDate, endDate }) => {
                                handleGetSpeed({ date_s: startDate, date_e: endDate })
                            }} />
                        )}
                    </Box>
                    <Box flexGrow={1} sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        textAlign: "center",
                        // backgroundColor: "gray",
                        marginInline: 1,
                        borderRadius: 1
                    }}>
                        {/* <IconButton onClick={onNavigation} size="small">
                        <NavigationRoundedIcon fontSize="small" />
                    </IconButton> */}

                        {
                            switchForm === "dashbord" && (
                                <>
                                    <IconButton onClick={() => {
                                        // if (switchForm === "replay") {
                                        //     setSwitchForm("dashbord")
                                        //     return
                                        // }
                                        navigate('/report/replay', { state: { defaultValue: data.device } })
                                    }} size="small">
                                        <EventRepeatIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        navigate('/report/events', { state: { defaultValue: data.device } })
                                    }} size="small">
                                        <NotificationsActiveRoundedIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton onClick={() => {
                                        navigate('/report/chart/speed', { state: { defaultValue: data.device } })
                                    }} size="small">
                                        <QueryStatsRoundedIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={handleGetInfo} size="small">
                                        <InfoRoundedIcon fontSize="small" />
                                    </IconButton>

                                    {/* 
                                    <IconButton onClick={() => {
                                        if (switchForm === "replay") {
                                            setSwitchForm("dashbord")
                                            return
                                        }
                                        setSwitchForm("replay")
                                        // navigate('/report/replay', { state: { defaultValue: data.device } })
                                    }} size="small">
                                        <EventRepeatIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setSwitchForm("events")
                                        // navigate('/report/events', { state: { defaultValue: data.device } })
                                    }} size="small">
                                        <NotificationsActiveRoundedIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton onClick={() => {
                                        setSwitchForm("speed")
                                        // navigate('/report/chart/speed', { state: { defaultValue: data.device } })
                                    }} size="small">
                                        <QueryStatsRoundedIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={handleGetInfo} size="small">
                                        <InfoRoundedIcon fontSize="small" />
                                    </IconButton> */}
                                </>
                            )
                        }



                        {
                            (switchForm === "replay" && listTables.length > 0) && (<>
                                <CSVLink style={{
                                    textDecoration: "none",
                                }} filename={`data_replay.csv`} data={
                                    listTables.map((value, index) => {
                                        const {
                                            device,
                                            datetime,
                                            datetime_gps,
                                            latitude,
                                            longitude,
                                            speed,
                                            bearing,
                                            input,
                                            ad0,
                                            ad1,
                                            ad2,
                                            ad3,
                                            ad4,
                                            gps,
                                            gsm,
                                            driver,
                                            status,
                                            odometer,
                                            engine_status

                                        } = value;
                                        return {
                                            device: `=""${device}""`,
                                            datetime: `=""${fDateTo(new Date(datetime), 'dd/MM/yyyy HH:mm:ss')}""`,
                                            datetime_gps: `=""${fDateTo(new Date(datetime_gps), 'dd/MM/yyyy HH:mm:ss')}""`,
                                            latitude: `=""${latitude}""`,
                                            longitude: `=""${longitude}""`,
                                            speed: `=""${speed}""`,
                                            bearing: `=""${bearing}""`,
                                            input: `=""${input}""`,
                                            ad0: `=""${ad0}""`,
                                            ad1: `=""${ad1}""`,
                                            ad2: `=""${ad2}""`,
                                            ad3: `=""${ad3}""`,
                                            ad4: `=""${ad4}""`,
                                            gps: `=""${gps}""`,
                                            gsm: `=""${gsm}""`,
                                            driver: `=""${driver}""`,
                                            status: `=""${status}""`,
                                            odometer: `=""${odometer}""`,
                                            engine_status: `=""${engine_status}""`,
                                        }
                                    }
                                    )
                                }  >
                                    <IconButton size="small">
                                        <Download fontSize="small" />
                                    </IconButton>
                                </CSVLink>
                            </>)
                        }
                        {
                            (switchForm === "events" && listEvents.length > 0) && (<>
                                <CSVLink style={{
                                    textDecoration: "none",
                                }} filename={`data_events.csv`} data={
                                    listEvents.map((value, index) => {
                                        const {
                                            event_id, event_name, event_value, date_gps, latitude, longitude, odometer
                                        } = value;
                                        return {
                                            "วันที่": `=""${fDateTo(new Date(date_gps), 'dd/MM/yyyy')}""`,
                                            "เวลา": `=""${fDateTo(new Date(date_gps), 'HH:mm:ss')}""`,
                                            "รหัสเหตุการณ์": `=""${event_id}""`,
                                            "ชื่อเหตุการณ์": `=""${event_name}""`,
                                            "ข้อมูลเหตุการณ์": `=""${event_value}""`,
                                            "ละติจูด": `=""${latitude}""`,
                                            "ลองจิจูด": `=""${longitude}""`,
                                            "มาตรวัดระยะทาง": `=""${odometer}""`,
                                        }
                                    }
                                    )
                                }  >
                                    <IconButton size="small">
                                        <Download fontSize="small" />
                                    </IconButton>
                                </CSVLink>
                            </>)
                        }


                        {
                            switchForm !== "dashbord" && (<>
                                <IconButton onClick={() => {
                                    setSwitchForm("dashbord")
                                    setListTables([])
                                    setIsPolyline(null)
                                    setMarkerSelect(null)
                                    setspeed([])
                                    onClickEvent(null)
                                    setListEvents([])
                                    // navigate('/report/replay', { state: { defaultValue: data.device } })
                                }} size="small">
                                    <Dashboard fontSize="small" />
                                </IconButton>
                            </>)
                        }
                        {/* <IconButton
                            // onClick={() => { }}
                            onClick={handleGetHandyman}
                            size="small"
                        >
                            <HandymanSharp fontSize="small" />
                        </IconButton> */}

                        {
                            handleExpandClick && (
                                <ExpandMore
                                    size="small"
                                    expand={expanded}
                                    onClick={
                                        handleExpandClick
                                    }
                                    // style={{ backgroundColor: "whitesmoke" }}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon fontSize="small" />
                                </ExpandMore>
                            )
                        }
                        <IconButton
                            onClick={() => {
                                setSwitchForm("dashbord")
                                setListTables([])
                                setIsPolyline(null)
                                setMarkerSelect(null)
                                setspeed([])
                                onClickEvent(null)
                                setListEvents([])
                                onClose()
                            }}
                            size="small"
                        // style={{ backgroundColor: "whitesmoke" }}
                        >
                            <CloseRoundedIcon color="error" fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Typography >{fDateTo(datetime, "dd-MM-yy HH:mm:ss")}</Typography>
                    </Box>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ width: "100%" }} >
                    <Divider style={{
                        marginBottom: "5px",
                        // borderColor: '#5C5CFF'
                    }} />
                    {switchForm === "dashbord" && (
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    paddingY: "3px",
                                    paddingX: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        borderRadius: 80,
                                        // border: "5px solid #dbdbe7",
                                        minWidth: 80,
                                        minHeight: 80,
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingY: "15px",
                                        alignItems: 'flex-start',
                                        background: 'rgb(182,33,254)',
                                        background: 'linear-gradient(90deg, rgba(30, 136, 229, 1) 0%, rgba(33, 150, 243, 1) 49%, rgba(187, 222, 251, 1) 100%)',
                                    }}
                                >
                                    <Badge
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant="dot"
                                        badgeContent=' '
                                        // color="#C5C5C5"
                                        sx={{
                                            "& .MuiBadge-badge": {
                                                color: "lightgreen",
                                                backgroundColor:
                                                    datetime === null || new Date(datetime) < new Date().setMinutes(new Date().getMinutes() - 5) ? "#FF0000" : "#00FF00",
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '15px'
                                            },

                                        }}
                                        color="info"
                                    >

                                        {
                                            icon !== null && (
                                                <img src={icon} alt='MainImgIcon' width={50} height={50} />
                                            )
                                        }
                                        {

                                            icon === null && (
                                                <ImageNotSupportedRoundedIcon style={{
                                                    fontSize: "55px",
                                                    color: "#dbdbe7"
                                                }} />
                                            )
                                        }

                                    </Badge>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    overflowX: 'auto',
                                    // backgroundColor: "gray",
                                    borderRadius: 1,
                                    justifyContent: 'space-between',
                                    width: "calc(100% - 80px)",
                                    alignItems: 'flex-start'
                                }}>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: "row",
                                            justifyContent: 'space-around',
                                            paddingTop: '20px'
                                            // flexWrap: 'wrap',
                                        }}
                                    >
                                        {
                                            data.widget
                                                // .sort((a, b) => {
                                                //     return a.name - b.name;
                                                // })
                                                .map((values, index) => (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: "row",
                                                            marginLeft: '10px',
                                                            marginRight: '10px',
                                                            justifyContent: 'end'
                                                            // flexWrap: 'wrap',
                                                        }}
                                                        key={index}
                                                    >
                                                        <Box sx={{ marginRight: '10px', }}>
                                                            <img src={values.icon} alt='imgIcon' width={25} height={25} />
                                                            <Typography noWrap={true} margin="0px" fontSize="12px" marginLeft="5px" paddingTop="0px" textOverflow="unset" overflow="unset" >
                                                                {
                                                                    values.name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ margin: 'auto' }}>
                                                            <Typography noWrap={true} margin="0px" marginLeft="5px" paddingTop="0px" textOverflow="unset" overflow="unset" >
                                                                {
                                                                    values.value
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ))
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{
                                    justifyContent: "center",
                                    textAlign: "center"
                                }}>
                                    <Gauge width={80} units="KM/H" value={speed} fontSize={"0.7em"} fontSizeUnits={"0.7em"} max={200} />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    textAlign: 'end',
                                    // flexWrap: 'wrap',
                                }}
                            >
                                <img src={data.widget_bar.icon} alt='imgIcon' width={20} height={20} style={{ marginRight: '10px', marginLeft: '10px' }} />
                                <Typography noWrap={true} margin="0px" paddingTop="0px" textOverflow="ellipsis" overflow="ellipsis" >
                                    {
                                        data.widget_bar.value
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {switchForm === "replay" && (
                        <Box sx={{ height: "180px", overflow: "auto" }}>
                            {/* replay แก้ต่อ */}
                            <TableVirtuoso
                                ref={virtuoso}
                                style={{ height: "180px" }}
                                data={listTables}
                                components={TableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow sx={{ backgroundColor: "white" }}>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>date time</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right" }}>speed</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right" }}>odometer</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, value) => (
                                    <React.Fragment key={`replay-${index}`}>
                                        <TableCell
                                            sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left", cursor: "pointer" }}
                                            onClick={() => {
                                                setSliderValue(index)
                                                setMarkerSelect(listTables[index])
                                            }}
                                        >{fDateTo(value.datetime_gps, 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right", cursor: "pointer" }}
                                            onClick={() => {
                                                setSliderValue(index)
                                                setMarkerSelect(listTables[index])
                                            }}
                                        >{value.speed}</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right", cursor: "pointer" }}
                                            onClick={() => {
                                                setSliderValue(index)
                                                setMarkerSelect(listTables[index])
                                            }}
                                        >{value.odometer}</TableCell>
                                    </React.Fragment>
                                )}
                            />
                        </Box>
                    )}


                    {switchForm === "events" && (
                        <Box sx={{ height: "180px", overflow: "auto" }}>
                            {/* replay แก้ต่อ */}
                            <TableVirtuoso
                                style={{ height: "180px" }}
                                data={listEvents}
                                components={TableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow sx={{ backgroundColor: "white" }}>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left", width: "120px" }}>date time</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right", width: "190px" }}>event name</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left", width: "220px" }}>event</TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right", width: "190px" }}>odometer</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, value) => (
                                    <React.Fragment key={`replay-${index}`} >
                                        <TableCell
                                            onClick={() => {
                                                onClickEvent(value)
                                            }}
                                            sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>{fDateTo(value.date_gps, 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                                        <TableCell
                                            onClick={() => {
                                                onClickEvent(value)
                                            }}
                                            sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right", cursor: "pointer" }}>{value.event_name}</TableCell>
                                        <TableCell
                                            onClick={() => {
                                                onClickEvent(value)
                                            }}
                                            sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left", cursor: "pointer" }}>{value.event_value}</TableCell>
                                        <TableCell
                                            onClick={() => {
                                                onClickEvent(value)
                                            }} sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "right", cursor: "pointer" }}>{value.odometer}</TableCell>
                                    </React.Fragment>
                                )}
                            />
                        </Box>
                    )}

                    {switchForm === "speed" && (
                        <Box sx={{ height: "180px", overflow: "auto" }}>
                            {
                                speeds.length > 0 && (
                                    <SpeedChart speed={speeds} />
                                )
                            }
                        </Box>
                    )}

                </Collapse >



                <Dialog onClose={handleCloseInfo} open={openInfo}>
                    <DialogTitle>ข้อมูลรถ {data?.name}</DialogTitle>
                    <Box width={400} margin="auto" padding={2}
                    // border={1} borderRadius={1}
                    >
                        <Grid container spacing={2} justifyContent="center" alignItems="center" padding={1}>
                            <Grid item xs={4}><Typography noWrap >ชื่ออุปกรณ์</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.device}</Typography></Grid>


                            <Grid item xs={4}><Typography noWrap >รุ่น GPS</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.gpsmodel}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >เลข IMEI</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.imei}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >DLT ID</Typography></Grid>
                            <Grid item xs={8}><Typography  >{dataInfo?.unit_id}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >Sim card</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.telgps}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >เลขตัวถัง</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_chassis_no}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >ยี่ห้อ</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_type}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >ป้ายทะเบียน</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_id}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >ประเภทรถ</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_register_type}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >หมายเลขรถ</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.car_id}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >กลุ่ม</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.group_id}</Typography></Grid>

                        </Grid>
                    </Box>
                </Dialog>
                <Dialog onClose={handleCloseHandyman} open={openHandyman}>
                    <DialogTitle>แจ้งซ่อม {data?.name}</DialogTitle>
                    <Box width={400} margin="auto" padding={2}
                    // border={1} borderRadius={1}
                    >
                        <Grid container spacing={2} justifyContent="center" alignItems="center" padding={1}>
                            <Grid item xs={4}><Typography noWrap >ชื่ออุปกรณ์</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.device}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >รุ่น GPS</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.gpsmodel}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >เลข IMEI</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.imei}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >Sim card</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.telgps}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >เลขตัวถัง</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_chassis_no}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >ยี่ห้อ</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_type}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >ป้ายทะเบียน</Typography></Grid>
                            <Grid item xs={8}><Typography noWrap >{dataInfo?.vehicle_id}</Typography></Grid>

                            <Grid item xs={4}><Typography noWrap >หัวข้อ</Typography></Grid>
                            <Grid item xs={8}>
                                <TextField size="small" fullWidth
                                    inputProps={{
                                        style: {
                                            height: "25px",
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                            fontSize: "14px"
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}><Typography noWrap >เรื่อง</Typography></Grid>
                            <Grid item xs={8}>
                                <TextField size="small" fullWidth
                                    inputProps={{
                                        style: {
                                            height: "25px",
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                            fontSize: "14px"
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} ><Typography noWrap >หมายเหตุ</Typography></Grid>
                            <Grid item xs={8} >
                                <TextField size="small" multiline maxRows={3} minRows={3} fullWidth
                                    inputProps={{
                                        style: {
                                            paddingTop: "2px",
                                            paddingBottom: "2px",
                                            fontSize: "14px"
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ justifyContent: "center", justifyItems: "center", display: "flex" }} >
                                <Stack direction="row" spacing={1}>
                                    <Button variant="outlined">บันทึก</Button>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Box>
                </Dialog>

                <Dialog onClose={handleRename} open={openRename}>
                    <DialogTitle>แก้ไขชื่อ</DialogTitle>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                            <Box width={400} margin="auto" padding={2}
                            // border={1} borderRadius={1}
                            >

                                <Grid container spacing={2} justifyContent="center" alignItems="center" padding={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            label="ชื่ออุปกรณ์"
                                            {...getFieldProps('name')}
                                            // value={values.name}
                                            error={Boolean(touched.name && errors.name)}
                                            helperText={touched.name && errors.name}

                                        />
                                    </Grid>

                                </Grid>
                                <Divider style={{ marginTop: 10 }} />
                                <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                    <Grid item xs={6} justifyContent="center">
                                        <Button
                                            fullWidth
                                            onClick={handleRename}
                                            size="medium"
                                            variant="outlined"
                                            color="info"
                                        // fullWidth
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} justifyContent="center">
                                        <LoadingButton
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="info"
                                            loading={isSubmitting}
                                        >
                                            Save
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Form>
                    </FormikProvider>
                </Dialog>

            </ Box >
        </ Box >
    )
}


const FormCustom = ({ handleGetData, getDateMinus }) => {

    const Schema = Yup.object().shape({
        startDate: Yup.string().required('required'),
        endDate: Yup.string().required('required')
    })
    const formik = useFormik({
        initialValues: {
            startDate: new Date().setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59)
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            handleGetData({ startDate: fDateTo(values.startDate, "yyyy-MM-dd HH:mm:ss"), endDate: fDateTo(values.endDate, "yyyy-MM-dd HH:mm:ss") })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const selectedTime = new Date();

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={1} direction="row">
                        {/* <Button size="small"
                            onClick={() => {
                                resetForm()
                                setFieldValue("startDate", fDateTo(selectedTime.setHours(0, 0, 0), "yyyy-MM-dd HH:mm:ss"))
                                setFieldValue("endDate", fDateTo(selectedTime.setHours(23, 59, 59), "yyyy-MM-dd HH:mm:ss"))
                                handleGetReplay({ startDate: fDateTo(selectedTime.setHours(0, 0, 0), "yyyy-MM-dd HH:mm:ss"), endDate: fDateTo(selectedTime.setHours(23, 59, 59), "yyyy-MM-dd HH:mm:ss") })
                            }}
                            sx={{ padding: "2px 0px" }}>
                            TODAY
                        </Button> */}
                        <MobileDateTimePicker
                            ampm={false}
                            disableFuture={true}
                            allowKeyboardControl={false}
                            value={values.startDate}
                            inputFormat="dd/MM/yyyy HH:mm"
                            format="dd/MM/yyyy HH:mm"
                            onChange={(val) => {
                                setFieldValue('startDate', val);
                            }}
                            renderInput={(params) => <TextField
                                size="small"
                                {...params}
                                {...getFieldProps('startDate')}
                                fullWidth
                                placeholder="DD/MM/YYYY"
                                autoComplete="off"
                                error={Boolean(touched.startDate && errors.startDate)}
                                helperText={touched.startDate && errors.startDate}
                                InputProps={{
                                    style: {
                                        height: "25px",
                                        width: "100px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        fontSize: "14px"
                                    },
                                }}
                            />}
                        />
                        <MobileDateTimePicker
                            maxDate={new Date(values.startDate)}
                            ampm={false}
                            disableFuture={true}
                            allowKeyboardControl={false}
                            value={values.endDate}
                            inputFormat="dd/MM/yyyy HH:mm"
                            format="dd/MM/yyyy HH:mm"
                            onChange={(val) => {
                                setFieldValue('endDate', val);
                            }}
                            renderInput={(params) => <TextField
                                size="small"
                                {...params}
                                {...getFieldProps('endDate')}
                                fullWidth
                                placeholder="DD/MM/YYYY"
                                autoComplete="off"
                                error={Boolean(touched.endDate && errors.endDate)}
                                helperText={touched.endDate && errors.endDate}
                                InputProps={{
                                    style: {
                                        height: "25px",
                                        width: "100px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        fontSize: "14px"
                                    },
                                }}
                            />}
                        />
                        <Button type="submit" size="small" sx={{ padding: "2px 0px" }}>ค้นหา</Button>
                    </Stack>
                </LocalizationProvider>
            </Form>
        </FormikProvider>
    )
}


const FormTextField = ({ handle }) => {

    const Schema = Yup.object().shape({
        startDate: Yup.string().required('required'),
        endDate: Yup.string().required('required')
    })
    const formik = useFormik({
        initialValues: {
            startDate: new Date().setHours(0, 0, 0),
            endDate: new Date().setHours(23, 59, 59)
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            handle({ startDate: fDateTo(values.startDate, "yyyy-MM-dd HH:mm:ss"), endDate: fDateTo(values.endDate, "yyyy-MM-dd HH:mm:ss") })
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const selectedTime = new Date();

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={1} direction="row">
                        <MobileDateTimePicker
                            ampm={false}
                            disableFuture={true}
                            allowKeyboardControl={false}
                            value={values.startDate}
                            inputFormat="dd/MM/yyyy HH:mm"
                            format="dd/MM/yyyy HH:mm"
                            onChange={(val) => {
                                setFieldValue('startDate', val);
                            }}
                            renderInput={(params) => <TextField
                                size="small"
                                {...params}
                                {...getFieldProps('startDate')}
                                fullWidth
                                placeholder="DD/MM/YYYY"
                                autoComplete="off"
                                error={Boolean(touched.startDate && errors.startDate)}
                                helperText={touched.startDate && errors.startDate}
                                InputProps={{
                                    style: {
                                        height: "25px",
                                        width: "100px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        fontSize: "14px"
                                    },
                                }}
                            />}
                        />
                        <MobileDateTimePicker
                            maxDate={new Date(values.startDate)}
                            ampm={false}
                            disableFuture={true}
                            allowKeyboardControl={false}
                            value={values.endDate}
                            inputFormat="dd/MM/yyyy HH:mm"
                            format="dd/MM/yyyy HH:mm"
                            onChange={(val) => {
                                setFieldValue('endDate', val);
                            }}
                            renderInput={(params) => <TextField
                                size="small"
                                {...params}
                                {...getFieldProps('endDate')}
                                fullWidth
                                placeholder="DD/MM/YYYY"
                                autoComplete="off"
                                error={Boolean(touched.endDate && errors.endDate)}
                                helperText={touched.endDate && errors.endDate}
                                InputProps={{
                                    style: {
                                        height: "25px",
                                        width: "100px",
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        fontSize: "14px"
                                    },
                                }}
                            />}
                        />
                        <Button type="submit" size="small" sx={{ padding: "2px 0px" }}>ค้นหา</Button>
                    </Stack>
                </LocalizationProvider>
            </Form>
        </FormikProvider>
    )
}



const TableComponents = {
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
    Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}