import { Backdrop, useMediaQuery, Grid, Box, IconButton, Button, Menu, MenuItem, Slider, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import PolylineUtil from 'polyline-encoded';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Polyline, TileLayer, ZoomControl } from 'react-leaflet';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Api } from '../../components/api';
import ListTileLayer from '../../layouts/ListTileLayer';
import { fDateTo } from '../../utils/formatTime';
import { listTablesAtom, loadingAtom, markerAtom } from './markerAtom';
import MarkerRotatedWithLabel from './MarkerRotatedWithLabel';
import CircularProgress from '@mui/material/CircularProgress';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import PauseCircleFilledTwoToneIcon from '@mui/icons-material/PauseCircleFilledTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import LayersIcon from '@mui/icons-material/Layers';
import { useSnackbar } from 'notistack';



const TableContainerStyle = styled(TableContainer)(({ theme }) => ({
  maxHeight: "calc(100vh - 253px)",
  [theme.breakpoints.down('md')]: {
    maxHeight: "232px"
  }
}));
const BoxMainStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down('md')]: {
    display: "block",
    flexDirection: "column"
  },
}));

const BoxMapStyle = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "100vh",
  width: "calc(100vw - 400px)",
  [theme.breakpoints.down('md')]: {
    width: "calc(100vw)",
    height: "calc(100vh - 160px)",
  },

}));

const BoxLeftStyle = styled(Box)(({ theme }) => ({
  width: "400px",
  [theme.breakpoints.down('md')]: {
    position: "relative",
    width: "calc(100vw)",
    zIndex: 999,
    backgroundColor: "white"
  },

}));
const BoxPanelStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: "flex",
    flexDirection: "row"
  },

}));

const BoxFormStyle = styled(Box)(({ theme }) => ({
  padding: "10px",
  [theme.breakpoints.down('md')]: {
    width: "100vw"
  },

}));


const MenuBottomNav = styled(Paper)(({ theme }) => (
  {
    [theme.breakpoints.up('md')]: {
      width: "400px",
      margin: 0,

    },
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    position: 'fixed',

  }));

const columns = [
  {
    label: 'datetime_gps',
    format: (value) => fDateTo(value, "dd-MM-yy HH:mm:ss"),
  },
  { label: 'speed', },
  { label: 'odometer', },
];

const Item = ({ setMarkerSelect, setMove, setSliderValue }) => {
  // console.log('render item');
  const ListItemButton = useRecoilValue(listTablesAtom)

  const itemButton = ListItemButton.map((row, i) =>
    <TableRow hover role="checkbox" sx={{
      cursor: "pointer"
    }} key={"tableRow" + i} onClick={() => {
      setMarkerSelect(row)
      setMove(true)
      setSliderValue(i)
    }}
      id={"tableRow" + i}
    >
      {columns.map((column, i) => {
        const value = row[column.label];
        return (
          <TableCell key={"rows" + i}>
            {column.format ? column.format(value) : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
  return (
    <DataTableStyle>
      {
        ListItemButton.length > 0 && (
          <TableContainerStyle>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, i) => (
                    <TableCell
                      key={"1" + i}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {ListItemButton} */}
                {itemButton}
              </TableBody>
            </Table>
          </TableContainerStyle>
        )
      }
    </DataTableStyle>
  )
};

const MemoizedItem = React.memo(Item);
// const MemoizedItem = useMemo(Item);

export default function Replay({ userId, account }) {
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(true)
  const [defaultDevice, setDefaultDevice] = useState(null)
  const location = useLocation()

  useEffect(() => {
    Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
      var devices_ = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
      setDevices(devices_);
      if (location.state?.defaultValue !== undefined) {
        setDefaultDevice(devices_.find(d => d.device === location.state.defaultValue))
      }
      setLoading(loading => !loading)
    }
    ).catch(e => {
      console.log('error', e)
      setLoading(loading => !loading)
    })

  }, [userId])


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={() => { setLoading(false) }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <ReplayForm account={account} defaultDevice={defaultDevice} devices={devices} />
      )}
    </>
  )
}
function ReplayForm({ account, defaultDevice, devices }) {
  const [listTables, setListTables] = useRecoilState(listTablesAtom)
  const [markerSelect, setMarkerSelect] = useState(null)
  const [map, setMap] = useState(null);
  const refMapStyle = useRef(null);
  const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")
  const [listReplay, setListReplay] = useState([])
  const [loading, setLoading] = useState(false)
  const [move, setMove] = useState(false)
  // const MemoizedItem = useMemo(Item);

  const [sliderValue, setSliderValue] = useState(0)

  useEffect(() => {
    const element = document.getElementById("tableRow" + sliderValue);
    if (markerSelect) {
      element?.classList.add("tableRow-active")
      // element.scrollIntoView({ block: "end" })
    }
    return () => {
      element?.classList?.remove("tableRow-active")
    }
  }, [sliderValue, markerSelect])


  useEffect(() => {
    if (markerSelect && move) {
      map.setView([markerSelect?.latitude, markerSelect?.longitude], map.getZoom(), {
        "animate": true,
        "duration": 1,
      })
    }
  }, [markerSelect, move])

  const selectedTime = new Date();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  const formik = useFormik({
    initialValues: {
      device: defaultDevice,
      dateStart: selectedTime.setHours(0, 0, 0),
      dateEnd: selectedTime.setHours(23, 59, 59),
    },
    // enableReinitialize: true,
    // validationSchema: ReplaySchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      if (listTables.length > 0) {
        setListTables([])
        setListReplay([])
        setMarkerSelect(null)
        setSliderValue(0)
      }
      Api.get('/tracking/history', {
        params: {
          device: values.device?.device,
          dateStart: fDateTo(values.dateStart, 'yyyy-MM-dd HH:mm:ss'),
          dateEnd: fDateTo(values.dateEnd, 'yyyy-MM-dd HH:mm:ss')
        }
      }).then((res) => {
        const { encode, gpslog } = res.data
        if (encode) {
          setLoading(false)
          setListTables(gpslog)
          const _encode = PolylineUtil.decode(encode)
          map.flyToBounds(_encode, { duration: 1, paddingTopLeft: [8, (isMobile ? 172 : 0) + 8] })
          setListReplay(_encode);
          setSubmitting()
        } else {
          handleSnackbar({ message: 'Not found', variant: 'error' })
          setLoading(false)
          setSubmitting()
        }
      }).catch((e) => {
        handleSnackbar({ message: 'Error please try again.', variant: 'error' })
        setLoading(false)
        setSubmitting()
      });
    }
  });
  const { errors, touched, isSubmitting, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnackbar = ({ message, variant }) => {
    enqueueSnackbar(message,
      {
        autoHideDuration: 1000,
        anchorOrigin: {
          horizontal: "right",
          vertical: 'bottom'
        },
        variant: variant
      });
  }


  return (
    <BoxMainStyle>
      <BoxLeftStyle>
        <BoxPanelStyle>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <BoxFormStyle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} md={12}>
                      <Autocomplete
                        id="device"
                        name="device"
                        options={devices}
                        // value={values.device}
                        defaultValue={values.device}
                        size="small"
                        getOptionLabel={(option) => option.vehicle_id}
                        isOptionEqualToValue={(option, value) => option.device === value.device}
                        fullWidth
                        onChange={(e, value) => {
                          setFieldValue("device", value);
                        }}

                        renderInput={(params) => (
                          <TextField
                            label="เลือกอุปกรณ์ GPS"
                            fullWidth
                            autoComplete="off"
                            name="device"
                            {...params}
                            {...getFieldProps('device')}
                            error={Boolean(touched.device && errors.device)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <MobileDateTimePicker
                        ampm={false}
                        disableFuture={true}
                        label="วันเวลาเริ่ม"
                        allowKeyboardControl={false}
                        value={values.dateStart}
                        inputFormat="dd/MM/yyyy HH:mm"
                        format="dd/MM/yyyy HH:mm"
                        onChange={(val) => {
                          setFieldValue('dateStart', val);
                        }}
                        renderInput={(params) => <TextField
                          size="small"
                          {...params}
                          {...getFieldProps('dateStart')}
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          error={Boolean(touched.dateStart && errors.dateStart)}
                          helperText={touched.dateStart && errors.dateStart}
                        />}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <MobileDateTimePicker
                        minDate={new Date(values.dateStart)}
                        ampm={false}
                        disableFuture={true}
                        label="ถึงวันเวลา"
                        allowKeyboardControl={false}
                        value={values.dateEnd}
                        inputFormat="dd/MM/yyyy HH:mm"
                        format="dd/MM/yyyy HH:mm"
                        onChange={(val) => {
                          setFieldValue('dateEnd', val);
                        }}
                        renderInput={(params) => <TextField
                          size="small"
                          {...params}
                          {...getFieldProps('dateEnd')}
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          error={Boolean(touched.dateEnd && errors.dateEnd)}
                          helperText={touched.dateEnd && errors.dateEnd} />}
                      />
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <LoadingButton
                        fullWidth
                        size="medium"
                        type="submit"
                        variant="contained"
                        color="info"
                        loading={isSubmitting}
                      >
                        แสดง
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <MemoizedCsvButton listTables={listTables} />
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </LocalizationProvider>
          </BoxFormStyle>

          <MemoizedItem setMarkerSelect={setMarkerSelect} setMove={setMove} setSliderValue={setSliderValue} />
        </BoxPanelStyle>
        <MenuBottomNav elevation={3}>
          <BottomNavigationBar ative="report" />
        </MenuBottomNav>
      </BoxLeftStyle>
      <BoxMapStyle>
        <MapContainerStyle
          whenCreated={m => {
            setMap(m)
          }}
          center={center}
          zoom={zoom}
          minZoom={3}
          zoomControl={false}
          scrollWheelZoom={true}
          doubleClickZoom={false}
          attributionControl={false}
        >
          <TileLayer ref={refMapStyle}
            url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
            maxNativeZoom={19}
            maxZoom={22}
          />
          <ZoomControl position='topright' />


          <Polyline positions={listReplay} weight={10} />


          {
            markerSelect && (
              <MarkerRotatedWithLabel
                position={[markerSelect?.latitude, markerSelect?.longitude]}
                rotationAngle={markerSelect?.bearing}
                rotationOrigin="center"
              />
            )
          }

        </MapContainerStyle>
        <PlayBack listTables={listTables} setMarkerSelect={setMarkerSelect} markerSelect={markerSelect} setMove={setMove}
          sliderValue={sliderValue} setSliderValue={setSliderValue}
        />
        <MemoizedLayerControl refMapStyle={refMapStyle} selectedMapStyle={selectedMapStyle} setSelectedMapStyle={setSelectedMapStyle} />
        {
          markerSelect && (
            <MarkerLabelSelect markerSelect={markerSelect} />
          )
        }
      </BoxMapStyle>
    </BoxMainStyle >
  );
}





const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
  height: '100%',
  width: "calc(100vw - 400px)",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: "100vw",
  },
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 6;

const PlayBackStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 10,
  right: 10,
  bottom: 10,
  backgroundColor: "transparent",
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    bottom: 10,
  },
}));


const PlayBack = ({ listTables, setMarkerSelect, setMove, sliderValue, setSliderValue, markerSelect }) => {

  const itemLength = listTables.length

  const [play, setPlay] = useState(false)
  const [duration, setDuration] = useState(1000);
  const [durationSpeed, setDurationSpeed] = useState('1x');

  useEffect(() => {
    var intervalId
    if (play && sliderValue < itemLength - 1) {
      intervalId = setInterval(() => {
        setMove(false)
        const isReplay = listTables[sliderValue + 1]
        setMarkerSelect(isReplay)
        setSliderValue(sliderValue + 1);
        const element = document.getElementById("tableRow" + (sliderValue + 1));
        element?.scrollIntoView({ block: "end" })
      }, duration);
    }
    return () => clearInterval(intervalId);
  }, [play, sliderValue, listTables]);
  const stopNplay = (p) => {
    setPlay(p)
    if (p) {
      if (markerSelect === null) {
        const element = document.getElementById("tableRow" + sliderValue);
        element.scrollIntoView({ block: "end" })
        setMove(false)
        const isReplay = listTables[sliderValue]
        setMarkerSelect(isReplay)
        setSliderValue(sliderValue);
      } else {
        const element = document.getElementById("tableRow" + (sliderValue + 1));
        element.scrollIntoView({ block: "end" })
        setMove(false)
        const isReplay = listTables[sliderValue + 1]
        setMarkerSelect(isReplay)
        setSliderValue(sliderValue + 1);
      }
    }
  }



  return (
    <PlayBackStyle>
      {
        itemLength !== 0 && (
          <Box sx={{
            height: "40px",
            backgroundColor: "#333333",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            color: "white",
            padding: "5px",
            position: "relative"
          }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
            }}>
              {
                itemLength - 1 === sliderValue ?
                  <IconButton
                    size="small"
                    onClick={() => {
                      const element = document.getElementById("tableRow" + 0);
                      element.scrollIntoView({ block: "end" })
                      setSliderValue(0)
                    }}
                  >
                    <ReplayTwoToneIcon />
                  </IconButton>
                  :
                  play ?
                    <IconButton
                      size="small"
                      onClick={
                        () => {
                          stopNplay(false)
                        }
                      }
                    >
                      <PauseCircleFilledTwoToneIcon />
                    </IconButton>
                    :
                    <IconButton
                      size="small"
                      onClick={
                        () => {
                          stopNplay(true)
                        }
                      }
                    >
                      <PlayCircleTwoToneIcon />
                    </IconButton>

              }
            </Box>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px"
            }}>
              <IconButton
                size="small"
                onClick={
                  () => {
                    if (duration === 1000) {
                      setDuration(500)
                      setDurationSpeed('2x')
                    }
                    if (duration === 500) {
                      setDuration(250)
                      setDurationSpeed('4x')

                    }
                    if (duration === 250) {
                      setDuration(125)
                      setDurationSpeed('8x')
                    }
                    if (duration === 125) {
                      setDuration(1000)
                      setDurationSpeed('1x')
                    }
                  }
                }
              >
                <span style={{
                  fontSize: '1rem',
                  fontWeight: '300'
                }}>
                  {
                    durationSpeed
                  }
                </span>
              </IconButton>
            </Box>
            <Box sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px"
            }}>
              <Slider
                size="small"
                max={itemLength - 1}
                aria-label="Temperature"
                defaultValue={0}
                value={sliderValue}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  return fDateTo(listTables[value].datetime_gps, "dd/MM/yyyy HH:mm:ss")
                }}
                onChange={(_, value) => {
                  if (value < itemLength) {
                    const element = document.getElementById("tableRow" + value);
                    element.scrollIntoView({ block: "end" })
                    setSliderValue(value)
                    setMarkerSelect(listTables[value])
                    setMove(false)
                  }
                }}
                color="secondary"
              />
            </Box>
          </Box>
        )
      }
    </PlayBackStyle >
  )
}

const BoxLabelRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

const CsvButton = ({ listTables }) => {
  // console.log('CsvButton')
  return (
    <>
      {
        listTables.length > 0 && (
          <CSVLink style={{
            textDecoration: "none",
          }} filename={`data_log.csv`} data={
            listTables.map((value, index) => {
              const {
                device,
                datetime,
                datetime_gps,
                latitude,
                longitude,
                speed,
                bearing,
                input,
                ad0,
                ad1,
                ad2,
                ad3,
                ad4,
                gps,
                gsm,
                driver,
                status,
                odometer,
                engine_status

              } = value;
              return {
                device: `=""${device}""`,
                datetime: `=""${fDateTo(new Date(datetime), 'dd/MM/yyyy HH:mm:ss')}""`,
                datetime_gps: `=""${fDateTo(new Date(datetime_gps), 'dd/MM/yyyy HH:mm:ss')}""`,
                latitude: `=""${latitude}""`,
                longitude: `=""${longitude}""`,
                speed: `=""${speed}""`,
                bearing: `=""${bearing}""`,
                input: `=""${input}""`,
                ad0: `=""${ad0}""`,
                ad1: `=""${ad1}""`,
                ad2: `=""${ad2}""`,
                ad3: `=""${ad3}""`,
                ad4: `=""${ad4}""`,
                gps: `=""${gps}""`,
                gsm: `=""${gsm}""`,
                driver: `=""${driver}""`,
                status: `=""${status}""`,
                odometer: `=""${odometer}""`,
                engine_status: `=""${engine_status}""`,
              }
            }
            )
          }  >
            <Button fullWidth variant="contained" >Download</Button>
          </CSVLink>
        )
      }
    </>
  )
}
const MemoizedCsvButton = React.memo(CsvButton);


const LayerControler = ({ refMapStyle, setSelectedMapStyle, selectedMapStyle }) => {
  const refDropDownMapStyle = useRef(null);
  const [openDropDown, setDropDown] = useState(false);

  const handleOpenDropDown = () => {
    setDropDown(!openDropDown);
  };

  return (
    <LayerControlStyle
      ref={refDropDownMapStyle}
    >
      <IconButton edge="end" size="small"
        style={{ backgroundColor: 'white', borderRadius: 5, }}
        onClick={() => {
          handleOpenDropDown()
        }}
      >
        <LayersIcon fontSize="inherit" />
      </IconButton>
      <Menu
        open={openDropDown}
        anchorEl={refDropDownMapStyle.current}
        onClose={() => handleOpenDropDown()}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Menu
          open={openDropDown}
          anchorEl={refDropDownMapStyle.current}
          onClose={() => handleOpenDropDown()}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {
            ListTileLayer.map((value, index) =>
              <MenuItem
                sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                onClick={
                  () => {
                    handleOpenDropDown()
                    if (refMapStyle.current) {
                      refMapStyle.current.setUrl(value.url);
                      setSelectedMapStyle(value.id)
                      localStorage.setItem('selectedMapStyle', value.id)
                    }
                  }
                }
                key={index}
              >
                {
                  value.name
                }
              </MenuItem>
            )
          }
        </Menu>
      </Menu>
    </LayerControlStyle>
  )
}
const MemoizedLayerControl = React.memo(LayerControler);

const LayerControlStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backdropFilter: 'blur(10px)',
  justifyContent: 'center',
  border: "2px solid rgba(0,0,0,0.2)",
  borderRadius: 5,
  top: 90,
  right: 10,
  width: 32,
  height: 32,
  zIndex: 2,
}));

const MarkerLabelSelect = ({ markerSelect }) => {
  return (
    <MarkerLabelStyle>
      <BoxLabelRow>
        <Box>วันที่ : </Box>
        <Box>
          {fDateTo(markerSelect?.datetime_gps, "dd-MM-yyyy HH:mm:ss")}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ความเร็ว : </Box>
        <Box>
          {markerSelect?.speed}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>driver : </Box>
        <Box>
          {markerSelect?.driver}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>status : </Box>
        <Box>
          {markerSelect?.status}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>odometer : </Box>
        <Box>
          {markerSelect?.odometer}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>engine_status : </Box>
        <Box>
          {markerSelect?.engine_status}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad0 : </Box>
        <Box>
          {markerSelect?.ad0}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad1 : </Box>
        <Box>
          {markerSelect?.ad1}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad2 : </Box>
        <Box>
          {markerSelect?.ad2}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad3 : </Box>
        <Box>
          {markerSelect?.ad3}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad4 : </Box>
        <Box>
          {markerSelect?.ad4}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>gps : </Box>
        <Box>
          {markerSelect?.gps}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>gsm : </Box>
        <Box>
          {markerSelect?.gsm}
        </Box>
      </BoxLabelRow>
    </MarkerLabelStyle>
  )
}


const MarkerLabelStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "50px",
  top: "10px",
  [theme.breakpoints.up('md')]: {
    width: "250px",
    // height: "172px",
  },
  [theme.breakpoints.down('md')]: {
    left: "50px",
    height: "172px",
  },
  overflow: "auto",
  fontSize: "16px",
  borderColor: "white",
  borderWidth: "10px",
  borderStyle: "solid",
  borderRadius: "10px",
  zIndex: 2,
  backgroundColor: "white"
}));

const DataTableStyle = styled(Paper)(({ theme }) => ({
  display: "unset",
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    display: "none"
  },
}));