import { LocalizationProvider, MobileDateTimePicker, LoadingButton } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {
  Card, Container, Stack, TextField, Autocomplete, TableRow, TableContainer, Table, TableHead, TableCell, TableBody,
  TablePagination, Typography, Box, Divider, Button
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import Page from '../components/Page'
import * as Yup from 'yup';
import { Api } from '../components/api'
import { fDateTo } from '../utils/formatTime'
import { styled } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { CSVLink } from "react-csv"
// import Scrollbar from '../components/Scrollbar'

const listTableHead = [
  // {
  //   title: 'datetime',
  //   style: { minWidth: 150, border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  // },
  {
    title: 'datetime gps',
    style: { minWidth: 160, border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'latitude',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'longitude',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'speed',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'bearing',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'input',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'ad0',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'ad1',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'ad2',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'ad3',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'ad4',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'gps',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'gsm',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'driver',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'status',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
  {
    title: 'odometer',
    style: { border: '1px solid rgba(241, 243, 244, 1)', textTransform: 'capitalize' }
  },
]
const MainStyle = styled(Box)(({ theme }) => ({
  margin: 'auto',
  paddingBottom: 60,
  overflow: 'auto'
}));
function Log({ account, userId }) {
  const [listLog, setListLog] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);
  const [devices, setDevices] = useState([])
  // const [data, setData] = useState('')

  const getDevices = useCallback(() => {

    const { cocode } = account;
    Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
      var list = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
      setDevices(list)
    }
    ).catch(e => {
      console.log('error', e)
    })
  }, [account]);

  useEffect(() => {
    getDevices()
  }, [getDevices])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleSnackbar = ({ message, variant }) => {
    // const handleClick = useCallback((button) => () => {
    enqueueSnackbar(message,
      {
        autoHideDuration: 1000,
        anchorOrigin: {
          horizontal: "right",
          vertical: 'bottom'
        },
        variant: variant
      });
    // }, [enqueueSnackbar]);
  }


  const deviceSchema = Yup.object().shape({
    deviceid: Yup.object().shape({
      device: Yup.string().required('Device is required'),
    }).nullable().required('Device is required'),
    // vender_id: Yup.string().required('Operator Gps is required'),
    // gpsmodel: Yup.string().required('Model Gps is required'),
    // imei: Yup.string().required('IMEI is required'),
  });
  const selectedTime = new Date();
  const formik = useFormik({
    initialValues: {
      deviceid: null,
      // date_s: fDateTo(fDateTo(Date(), 'yyyy-MM-dd') + ' 00:00', 'yyyy-MM-dd HH:mm'),
      // date_e: fDateTo(fDateTo(Date(), 'yyyy-MM-dd') + ' 23:59', 'yyyy-MM-dd HH:mm'),
      date_s: selectedTime.setHours(0, 0, 0),
      date_e: selectedTime.setHours(23, 59, 59),
    },
    validationSchema: deviceSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { deviceid, date_s, date_e } = values
      const { cocode } = account;
      // setData(JSON.stringify({
      //   cocode: cocode,
      //   device: deviceid,
      //   StartTime: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
      //   EndTime: fDateTo(date_e, 'yyyy-MM-dd HH:mm')
      // }))
      Api.get('/v0/gps/gps_log', {
        params: {
          cocode: cocode,
          device: deviceid.device,
          StartTime: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
          EndTime: fDateTo(date_e, 'yyyy-MM-dd HH:mm')
        }
      }).then((res) => {
        // resetForm()
        setListLog(res.data);
        setPage(0)
        setFirstLoad(false)
        setSubmitting()
      }).catch((e) => {
        // resetForm()
        setListLog([])
        setFirstLoad(false)
        handleSnackbar({ message: 'Not Found Data', variant: "error" })
        setSubmitting()
      });
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listLog.length) : 0;
  return (
    <Page title="Log">
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Box flexGrow={1} padding={1}>
          <Typography variant="h4">Log</Typography>
        </Box>
        <Divider />
        <MainStyle>
          <Container sx={{ padding: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Autocomplete
                        id="deviceid"
                        name="deviceid"
                        options={
                          devices
                        }
                        size="small"
                        getOptionLabel={(option) => option.vehicle_id}
                        isOptionEqualToValue={(option, value) => option.device === value.device}
                        fullWidth
                        onChange={(e, value) => {
                          setFieldValue("deviceid", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="เลือกอุปกรณ์ GPS"
                            fullWidth
                            autoComplete="off"
                            name="deviceid"
                            {...params}
                            {...getFieldProps('deviceid')}
                            error={Boolean(touched.deviceid && errors.deviceid)}
                            helperText={touched.deviceid && errors.deviceid}
                          />
                        )}
                      />
                      <MobileDateTimePicker

                        ampm={false}
                        disableFuture={true}
                        label="วันเวลาเริ่ม"
                        allowKeyboardControl={false}
                        value={values.date_s}
                        inputFormat="dd/MM/yyyy HH:mm"
                        format="dd/MM/yyyy HH:mm"
                        onChange={(val) => {
                          setFieldValue('date_s', val);
                          // if (values.date_s > values.date_e) {
                          //   setFieldValue('date_e', fDateTo(val, 'yyyy-MM-dd HH:mm'));
                          // }
                        }}
                        renderInput={(params) => <TextField
                          size="small"
                          {...params}
                          {...getFieldProps('date_r')}
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          error={Boolean(touched.date_s && errors.date_s)}
                          helperText={touched.date_s && errors.date_s} />}
                      />
                      <MobileDateTimePicker
                        minDate={new Date(values.date_s)}
                        ampm={false}
                        disableFuture={true}
                        label="ถึงวันเวลา"
                        allowKeyboardControl={false}
                        value={values.date_e}
                        inputFormat="dd/MM/yyyy HH:mm"
                        format="dd/MM/yyyy HH:mm"
                        onChange={(val) => {
                          setFieldValue('date_e', val);
                          // setFieldValue('date_e', fDateTo(val, 'yyyy-MM-dd HH:mm'));
                          // if (values.date_e < values.date_s) {
                          //   setFieldValue('date_s', fDateTo(fDateTo(val, 'yyyy-MM-dd') + ' 00:00', 'yyyy-MM-dd HH:mm'));
                          // }
                        }}
                        renderInput={(params) => <TextField
                          size="small"
                          {...params}
                          {...getFieldProps('date_e')}
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          error={Boolean(touched.date_e && errors.date_e)}
                          helperText={touched.date_e && errors.date_e} />}
                      />
                      <LoadingButton
                        style={{ height: 40 }}
                        fullWidth={false}
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        ค้นหา
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Form>
              </FormikProvider>
            </LocalizationProvider>
          </Container>

          {
            listLog.length > 0 && (
              <Container sx={{ padding: 3 }}>
                <Box sx={{ padding: 1 }}
                  display="flex"
                  justifyContent="flex-end">

                  <Button as={CSVLink} filename={`${fDateTo(values.date_s, 'dd_MM_yyyy_HH:mm')}-${fDateTo(values.date_e, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                    listLog.map(values => {
                      const {
                        datetime_gps, latitude, longitude, speed, bearing,
                        input, ad0, ad1, ad2, ad3, ad4, gps, gsm, driver, status, odometer, engine_status
                      } = values;
                      return {
                        datetime_gps, latitude, longitude, speed, bearing,
                        input, ad0, ad1, ad2, ad3, ad4, gps, gsm, driver: `=""${driver}""`,
                        status, odometer, engine_status
                      }
                    }
                    )
                  }
                    variant="contained"
                    style={{
                      textDecorationLine: 'none',
                    }} >
                    Download
                  </Button>

                  {/* <CSVLink data={listLog}>
                    Download
                  </CSVLink> */}
                </Box>
                <TableContainer>
                  <Table stickyHeader >
                    <TableHead >
                      <TableRow>
                        {
                          listTableHead.map((item, index) => {
                            return <TableCell style={item.style} key={index} >{item.title}</TableCell>
                          })
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        listLog.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                          const {
                            datetime, datetime_gps, latitude, longitude, speed, bearing,
                            input, ad0, ad1, ad2, ad3, ad4, gps, gsm, driver, status, odometer, engine_status
                          } = item;
                          return <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            style={{
                              backgroundColor: (engine_status === 1 && speed > 0) && '#7DCE13' || (engine_status === 1 && speed === 0) && '#FFF8BC' || (engine_status === 0 && speed === 0) && '#DFE6E9'
                            }}
                          >
                            {/* <TableCell>{fDateTo(datetime, 'dd/MM/yyyy HH:mm:ss')}</TableCell> */}
                            <TableCell><Typography noWrap>{fDateTo(datetime_gps, 'dd/MM/yyyy HH:mm:ss')}</Typography></TableCell>
                            <TableCell>{latitude}</TableCell>
                            <TableCell>{longitude}</TableCell>
                            <TableCell>{speed}</TableCell>
                            <TableCell>{bearing}</TableCell>
                            <TableCell>{input}</TableCell>
                            <TableCell>{ad0}</TableCell>
                            <TableCell>{ad1}</TableCell>
                            <TableCell>{ad2}</TableCell>
                            <TableCell>{ad3}</TableCell>
                            <TableCell>{ad4}</TableCell>
                            <TableCell>{gps}</TableCell>
                            <TableCell>{gsm}</TableCell>
                            <TableCell>{driver}</TableCell>
                            <TableCell>{status}</TableCell>
                            <TableCell>{odometer}</TableCell>
                          </TableRow>
                        })
                      }
                      {
                        emptyRows > 0 && (
                          <TableRow style={{ height: 56 * emptyRows }}>
                            <TableCell colSpan={listTableHead.length} />
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[
                    { value: 10, label: '10' },
                    { value: 20, label: '20' },
                    { value: 30, label: '30' },
                    { value: 50, label: '50' },
                    // { value: 100, label: 100 },
                    // { value: listLog.length, label: 'All' }
                  ]}
                  component="div"
                  count={listLog.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Container>
            )}
          {
            listLog.length === 0 && (
              <Container sx={{ padding: 3 }}>
                <Typography gutterBottom align="center" variant="subtitle1">
                  {
                    firstLoad && (
                      <>
                        กรุณาค้นหาข้อมูล
                      </>
                    )
                  }
                </Typography>
              </Container>
            )
          }
        </MainStyle>
      </Container >
    </Page >
  )
}

export default Log