import { Box, Container, Divider, Fab, Typography, ListItem, List, ListItemButton, ListItemText, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Page from '../../components/Page'
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../components/api';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';

const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 60,
    overflow: 'auto'
}));
export default function MainRoutes() {
    const navigate = useNavigate()
    const [masterRoutes, setMasterRoutes] = useState([

    ])
    // const [listRoutes, setListRoutes] = useState([])
    // /v0/Busticket/RouteStation?route_no={route_no}
    // /v0/Busticket/RouteStation
    // var reqData = {
    //     "route_no": routeNo.text,
    //     "station_no": stationNo.text,
    //     "station_name": stationName.text,
    //     "station_up": stationUp,
    //     "station_down": stationDown
    //   };
    // /v0/Busticket/Route
    // var reqData = {"route_no": routeNo.text, "route_name": routeName.text};

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const searchRoute = useCallback(
        ({ search }) => {
            Api.get('/v0/Busticket/Route', {
                params: {
                    search: search
                },
                // headers : {
                //     appid : ''
                // }
            })
                .then((res) => {
                    const list = res.data
                    // setListRoutes(list)
                    setMasterRoutes(list)
                })
                .catch((error) => {
                    console.log('error', error)
                    // setListRoutes([])
                    setMasterRoutes([])
                })
        },
        [],
    )

    useEffect(() => {
        searchRoute({ search: '' })
    }, [searchRoute])

    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleClickWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            // handleDel(item)
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };
    const handleDel = (item) => {
        var data = {
            ...item
        }
        Api.delete('/v0/Busticket/Route', { data })
            .then(res => {
                handleSnackbar({ message: "Successfully done the operation.", variant: "success" })
                searchRoute({ search: '' });
            })
            .catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: message });
            })
    };

    return (
        <Page title="routes">
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Routes</Typography>
                </Box>
                <Divider />
                <MainStyle>

                    <List style={{ paddind: 10 }}>
                        {masterRoutes.map((e, i) => (
                            <Fragment
                                key={'routes' + i}
                            >
                                <ListItem
                                    disablePadding
                                    secondaryAction={
                                        <Box paddingX={1}>
                                            {/* <IconButton edge="start" aria-label="Edit" style={{ margin: 1 }}
                                                onClick={() => {
                                                    navigate(`/settings/routes/route/${e.route_no}`, {
                                                        state: e
                                                    })
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="start" aria-label="delete" style={{ margin: 1 }}
                                                onClick={() => {
                                                    closeSnackbar()
                                                    handleClickWithAction({
                                                        message: "Remove item?",
                                                        handle: () => {
                                                            handleDel(e)
                                                        }
                                                    })
                                                }}
                                            >
                                                <DeleteIcon color="error" />
                                            </IconButton> */}
                                        </Box>
                                    }>
                                    <ListItemButton>
                                        <ListItemText
                                            onClick={
                                                () => {
                                                    navigate(`/settings/routes/${e.route_no}`
                                                        // , {
                                                        //     state: e
                                                        // }
                                                    )
                                                }
                                            }
                                            primary={e.route_name}
                                            secondary={e.route_no}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {i + 1 !== masterRoutes.length ? <Divider /> : null}
                            </Fragment>
                        ))}
                    </List>


                    {/* <FabStyle color="primary" aria-label='Add' onClick={() => {
                        navigate('/settings/routes/route')
                    }}>
                        <AddIcon />
                    </FabStyle> */}
                </MainStyle>
            </Container>
        </Page>
    )
}
